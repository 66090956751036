import { HelpOutline } from '@mui/icons-material';
import { Alert, Button, Modal } from '@mui/material';
import { addDays, differenceInDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import IntegrationModal from '../components/Integrations/IntegrationModal';
import OrgAgreementsModal from '../components/Modals/OrgAgreementsModal';
import UserAgreementsModal from '../components/Modals/UserAgreementsModal';
import SubscriptionFailed from '../components/Payments/SubscriptionFailed';
import SubscriptionSuccessful from '../components/Payments/SubscriptionSuccessful';
import Register from '../components/Register/Register';
import ACTIONS from '../store/action';
import { Actions as PROFILEACTIONS } from '../store/actions/profileActions';
import { DashboardButton, DashboardIcon } from '../styledComponents/GlobalStyle';

const ConnectStripeAccountModal = ({ showStripeConnect, connectStripeAccount }) => {
  const handleClose = () => null;
  const [loading, setLoading] = useState(false);

  if (!showStripeConnect) {
    return null;
  } else {
    return (
      <div>
        <Modal open={showStripeConnect} onClose={handleClose}>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4 className='modal-title'>Please connect your account</h4>
                </div>
              </div>
              <div className='modal-body' style={{ overflow: 'auto' }}>
                <p style={{ fontWeight: 'bold' }}>
                  To complete your Ambassador set up, please follow this link to create a Stripe Connect account. This will
                  enable payment of commissions.
                </p>
                <button
                  className='btn btn-success'
                  onClick={async () => {
                    setLoading(true);
                    await connectStripeAccount();
                    setLoading(false);
                  }}>
                  {loading && <i className='fa fa-spinner fa-spin' style={{ marginRight: 5 }} />}
                  Connect Stripe Account
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
};

const Dashboard = ({
  firstName,
  branding,
  showSubscriptionSuccesfulModal,
  stripeCheckoutFailed,
  stripeCheckoutSuccess,
  hlp,
  tourID,
  hasSubscriptionActive,
  ssoUser,
  list,
  fetchedOrganisations,
  ambassadorAdmin,
  stripeAccountId,
  currentOrg,
  directUser,
  whiteLabelAdmin,
  blockBtns,
  connectStripeAccount,
  accountType,
  daysUntilTestOrgExpire,
  helpHeroBtnStyles,
  slaAgreementConfirmed,
  eulaAgreementConfirmed,
}) => {
  const [showPaymentModal, setShowPaymentModal] = useState(null);
  const [showStripeConnect, setShowStripeConnect] = useState(false);
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);

  useEffect(() => {
    (async () => {
      // User is an ambassador admin and org doesn't have a stripe account -> First sign in, show stripe connect modal.
      if (ambassadorAdmin && currentOrg) {
        // User is an ambassador admin and org doesn't have a stripe account -> First sign in, show stripe connect modal.
        if (!stripeAccountId) {
          setShowStripeConnect(true);
        }
      }
      // Org doesn't have subscription -> show payment modal.
      if (hasSubscriptionActive === false) {
        setShowPaymentModal(true);
      } else {
        setShowPaymentModal(false);
      }
      // If xeroUser and no organisations, show integration modal.
      if (ssoUser && fetchedOrganisations && list?.length === 0) {
        setShowIntegrationModal(true);
      } else {
        setShowIntegrationModal(false);
      }
    })();
    // eslint-disable-next-line
  }, [currentOrg, fetchedOrganisations, ambassadorAdmin, stripeAccountId]);

  const {
    btnTextColor,
    dashboardFirstIcon,
    dashboardSecondIcon,
    dashboardThirdIcon,
    dashboardFourthIcon,
    buttonHover,
    dashboardFirstIconBtn,
    dashboardSecondIconBtn,
    dashboardThirdIconBtn,
    dashboardFourthIconBtn,
    dashboardIconBtnTxtColor,
    dashboardFirstIconBtnHover,
    dashboardSecondIconBtnHover,
    dashboardThirdIconBtnHover,
    dashboardForthIconBtnHover,
    dashboardHeaderBgColor,
    dashboardBoxBg,
    dashboardBoxBorder,
  } = branding.colors || {};

  if (stripeCheckoutFailed) return <SubscriptionFailed />;
  if (stripeCheckoutSuccess) return <SubscriptionSuccessful />;

  const showSLAAgreementModal = slaAgreementConfirmed === false && (directUser || whiteLabelAdmin);
  const showEULAAgreementModal = eulaAgreementConfirmed === false && (directUser || whiteLabelAdmin);

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <section className='panel'>
            <header className='panel-heading dashboard__header' style={{ '--dashboard-header-bg': dashboardHeaderBgColor }}>
              Welcome {firstName}
            </header>
          </section>
        </div>
      </div>

      <div
        style={{
          height: window.screen.availHeight > 1078 ? '78vh' : '75vh',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}>
        <div className='row' id='dashboard__icons'>
          <div className='col-md-3' id='dashboard__organisations'>
            <div
              className='mini-stat clearfix dashboard__mini-stat'
              style={{ '--dashboard-box-bg': dashboardBoxBg, '--dashboard-box-border': dashboardBoxBorder }}>
              <DashboardIcon className='mini-stat-icon' bgcolor={dashboardFirstIcon}>
                <i className='fa fa-building' />
              </DashboardIcon>
              <div className='mini-stat-info' style={{ marginLeft: '2vmin', fontSize: 14 }}>
                Organisations
              </div>
            </div>

            <DashboardButton
              className='btn btn-xs'
              bgcolor={dashboardFirstIconBtn || dashboardFirstIcon}
              bgcolorhover={dashboardFirstIconBtnHover || buttonHover}
              color={dashboardIconBtnTxtColor || btnTextColor}
              to='/organisations'
              style={{ fontSize: 14 }}>
              Manage Organisations
            </DashboardButton>
          </div>

          <div className='col-md-3' id='dashboard__facilities'>
            <div
              className='mini-stat clearfix dashboard__mini-stat'
              style={{ '--dashboard-box-bg': dashboardBoxBg, '--dashboard-box-border': dashboardBoxBorder }}>
              <DashboardIcon className='mini-stat-icon' bgcolor={dashboardSecondIcon}>
                <i className='fa fa-map-marker' />
              </DashboardIcon>

              <div className='mini-stat-info' style={{ marginLeft: '2vmin', fontSize: 14 }}>
                Facilities
              </div>
            </div>

            <DashboardButton
              className='btn btn-xs'
              bgcolor={dashboardSecondIconBtn || dashboardSecondIcon}
              bgcolorhover={dashboardSecondIconBtnHover || buttonHover}
              color={dashboardIconBtnTxtColor || btnTextColor}
              to='/facilities'
              style={{ fontSize: 14 }}>
              Manage Facilities
            </DashboardButton>
          </div>

          <div className='col-md-3' id='dashboard__usageData'>
            <div
              className='mini-stat clearfix dashboard__mini-stat'
              style={{ '--dashboard-box-bg': dashboardBoxBg, '--dashboard-box-border': dashboardBoxBorder }}>
              <DashboardIcon className='mini-stat-icon' bgcolor={dashboardThirdIcon}>
                <i className='fa fa-bar-chart' />
              </DashboardIcon>
              <div className='mini-stat-info' style={{ marginLeft: '2vmin', fontSize: 14 }}>
                Data Entries
              </div>
            </div>

            <DashboardButton
              className='btn btn-xs'
              bgcolor={dashboardThirdIconBtn || dashboardThirdIcon}
              bgcolorhover={dashboardThirdIconBtnHover || buttonHover}
              color={dashboardIconBtnTxtColor || btnTextColor}
              to='/usage'
              style={{ pointerEvents: blockBtns ? 'none' : 'auto', fontSize: 14 }}>
              Manage Usage Data
            </DashboardButton>
          </div>

          <div className='col-md-3' id='dashboard__reports'>
            <div
              className='mini-stat clearfix dashboard__mini-stat'
              style={{ '--dashboard-box-bg': dashboardBoxBg, '--dashboard-box-border': dashboardBoxBorder }}>
              <DashboardIcon className='mini-stat-icon' bgcolor={dashboardFourthIcon}>
                <i className='fa fa-file-text' />
              </DashboardIcon>
              <div className='mini-stat-info' style={{ marginLeft: '2vmin', fontSize: 14 }}>
                Reports
              </div>
            </div>

            <DashboardButton
              className='btn btn-xs'
              bgcolor={dashboardFourthIconBtn || dashboardFourthIcon}
              bgcolorhover={dashboardForthIconBtnHover || buttonHover}
              color={dashboardIconBtnTxtColor || btnTextColor}
              to='/reports'
              style={{ pointerEvents: blockBtns ? 'none' : 'auto', fontSize: 14 }}>
              Manage Reports
            </DashboardButton>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='row' id='clientTestOrgs__alertMessage'>
            <div style={{ display: accountType?.includes('Client Test Organisation') ? 'block' : 'none' }}>
              <Alert severity='info'>
                <p style={{ fontSize: 16 }}>Test Organisation expires in: {daysUntilTestOrgExpire} days.</p>
              </Alert>
            </div>
          </div>
          <div id='dashboard__helpHero_btn'>
            <Button
              variant='contained'
              startIcon={<HelpOutline />}
              style={helpHeroBtnStyles}
              onClick={() => hlp.startTour(String(tourID))}>
              Help
            </Button>
          </div>
        </div>
      </div>
      {showPaymentModal && !hasSubscriptionActive && <Register setShowPaymentModal={setShowPaymentModal} />}
      {ssoUser && showIntegrationModal && (
        <IntegrationModal setShowIntegrationModal={setShowIntegrationModal} showIntegrationModal={showIntegrationModal} />
      )}

      <ConnectStripeAccountModal showStripeConnect={showStripeConnect} connectStripeAccount={connectStripeAccount} />
      {showSLAAgreementModal && !showPaymentModal && !showIntegrationModal && <OrgAgreementsModal />}
      {showEULAAgreementModal && !showPaymentModal && !showIntegrationModal && <UserAgreementsModal />}
      {/* Display SubscriptionSuccessful Modal here if user updates subscription under dashboard component*/}
      {showSubscriptionSuccesfulModal && <SubscriptionSuccessful setShowPaymentModal={setShowPaymentModal} />}
    </>
  );
};

const mapStateToProps = (state) => {
  const { firstName, ambassadorAdmin, cyfAdmin, whiteLabelAdmin, consultantAdmin, ssoUser } = state?.profile?.details || {};
  const { softwareLicenseAgreementConfirmed, userLicenseAgreementConfirmed } = state.profile.details.legalAgreements || {};

  const { hasSubscriptionActive, stripeAccountId, accountType, creationDate } = state.organisation?.details || {};
  const directUser = consultantAdmin === false && cyfAdmin === false && ambassadorAdmin === false;
  const { orgMissingCompulsoryData, list, fetchedOrganisations } = state?.organisation || {};
  const blockBtns = !cyfAdmin && (!hasSubscriptionActive || orgMissingCompulsoryData);

  const hostname = window.location.hostname;

  let daysUntilTestOrgExpire = 0;
  if (accountType?.includes('Client Test Organisation') && creationDate) {
    const expireDay = addDays(new Date(creationDate), 30);
    daysUntilTestOrgExpire = differenceInDays(expireDay, new Date()) + 1;
    daysUntilTestOrgExpire = daysUntilTestOrgExpire < 0 ? 0 : daysUntilTestOrgExpire;
  }

  const helpHeroBtnStyles = {
    background: state.branding?.colors?.primaryColor,
    width: 93,
    borderRadius: 19,
    fontWeight: 600,
    padding: '10px 15px 10px 7px',
    fontSize: 15,
    display: ['localhost', 'compareyourfootprint'].some((x) => hostname.includes(x)) ? 'flex' : 'none',
  };

  return {
    slaAgreementConfirmed: softwareLicenseAgreementConfirmed,
    eulaAgreementConfirmed: userLicenseAgreementConfirmed,
    currentOrg: state.currentOrganisation,
    firstName,
    ssoUser,
    ambassadorAdmin,
    whiteLabelAdmin,
    directUser,
    stripeAccountId,
    list,
    fetchedOrganisations,
    branding: state.branding || {},
    hasSubscriptionActive,
    showSubscriptionSuccesfulModal: state.payment && state.payment.subscriptionSucessfull,
    accountType,
    daysUntilTestOrgExpire,
    helpHeroBtnStyles,
    stripeCheckoutFailed: window.location.pathname.includes('cancel'),
    stripeCheckoutSuccess: window.location.pathname.includes('success'),
    blockBtns,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLicenses: () => dispatch(ACTIONS.getLicenses()),
  connectStripeAccount: () => dispatch(PROFILEACTIONS.connectStripeAccount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
