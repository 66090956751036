const hostname = window.location.hostname;

/**
 * Returns the app version based on the hostname.
 * @param {string} version - The default version to use if no match is found. Defaults to 'v1'.
 * @returns {string} - The app version.
 */

const getAppVersion = (version = 'v1') => {
  if (['staging2', 'testcyf', 'worldlandtrust.compareyourfootprint.com'].some((x) => hostname.includes(x))) {
    version = 'stage2';
  } else if (['staging', 'staging-green'].some((x) => hostname.includes(x))) {
    version = 'stage';
  } else if (['local', 'dev', 'testcyf'].some((x) => hostname.includes(x))) {
    version = 'dev';
  }

  return version;
};

const apiURL =
  hostname.includes('local') && process.env.REACT_APP_LOCAL_BACK_TEST
    ? 'https://localhost:3001'
    : hostname.includes('archerirm') ? `https://api.archerirm.compareyourfootprint.com/${getAppVersion()}` : `https://api.app.compareyourfootprint.com/${getAppVersion()}`;

module.exports = { apiURL, getAppVersion };
