import { Auth } from '@aws-amplify/auth';
import { Fade, Modal } from '@mui/material';
import { Amplify } from 'aws-amplify';
import { format } from 'date-fns';
import initHelpHero from 'helphero';
import React, { useEffect, useState } from 'react';
import GoogleFontLoader from 'react-google-font-loader';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import awsconfig from './aws-exports';
import ACTIONS from './store/action';
import { Actions as ADMINACTIONS } from './store/actions/adminActions';
import { Actions as AUTHENTICATIONACTIONS } from './store/actions/authenticationActions';
import { Actions as FACILITYACTIONS } from './store/actions/facilityActions';
import { Actions as METRICACTIONS } from './store/actions/metricActions';
import { Actions as ORGANISATIONACTIONS } from './store/actions/organisationActions';
import { Actions as PAYMENTACTIONS } from './store/actions/paymentActions';
import { Actions as PROFILEACTIONS } from './store/actions/profileActions';

import ResendConfirmationCode from '../src/components/Register/ResendConfirmationCode';
import ConsumptionReport from './Pages/ConsumptionReport';
import Dashboard from './Pages/Dashboard';
import Developer from './Pages/Developer';
import Facilities from './Pages/Facilities';
import Facility from './Pages/Facility.js';
import Organisation from './Pages/Organisation';
import Organisations from './Pages/Organisations';
import Profile from './Pages/Profile';
import Report from './Pages/Report';
import Reports from './Pages/Reports';
import Usage from './Pages/Usage';
import UsersList from './Pages/UsersList';
import AdminPortalList from './components/AdminPortal/AdminPortalList.js';
import ClosestAirportList from './components/AdminPortal/Airports/ClosestAirportList';
import ConversionFactors from './components/AdminPortal/ConversionFactors/ConversionFactors.js';
import ClientsList from './components/AdminPortal/Organisations/OrganisationsList';
import Contract from './components/AdminPortal/SalesPortal/Contract';
import ContractsList from './components/AdminPortal/SalesPortal/ContractsList';
import NewContract from './components/AdminPortal/SalesPortal/NewContract';
import PriceBands from './components/AdminPortal/SalesPortal/PriceBands';
import SettingsPage from './components/AdminPortal/Settings/Settings';
import TransferPortal from './components/AdminPortal/TransferPortal/TransferPortal';
import UsageDataList from './components/AdminPortal/UsageData/UsageDataList';
import HelpSidebar from './components/Common/HelpSidebar';
import Navbar from './components/Common/Navbar';
import Sidebar from './components/Common/Sidebar';
import VideoModal from './components/Common/VideoModal';
import SageApp from './components/Integrations/SageApp';
import XeroApp from './components/Integrations/XeroApp';
import { XeroAuth } from './components/Integrations/XeroAuth';
import Login from './components/Login/Login';
import AdminPortalModal from './components/Modals/AdminPortalModal';
import AnalysisModal from './components/Modals/AnalysisModal';
import BulkEditPage from './components/Modals/BulkEditPage';
import ConfirmationModal from './components/Modals/ConfirmationModal';
import OrgAgreementsModal from './components/Modals/OrgAgreementsModal';
import OrgModal from './components/Organisations/Modal';
import BillingPage from './components/Payments/BillingPage';
import RedirectUsers from './components/Register/RedirectUsers';
import Register from './components/Register/Register';
import Alert from './components/utils/Alert';

if (awsconfig.aws_user_pools_id === 'eu-west-2_NeojTq9f0') {
  // If on stage env, users will sign in through dev user pool
  awsconfig.aws_cognito_identity_pool_id = 'eu-west-2:1445f8d0-77bd-438b-97ec-76a3b195b5da';
  awsconfig.aws_user_pools_id = 'eu-west-2_p3L38NXoz';
  awsconfig.aws_user_pools_web_client_id = '1csrmd2iunqcjjb2uqgcldg0tq';
} else if (awsconfig.aws_user_pools_id === 'eu-west-2_SgLIXwGTA') {
  // If on stagingtwo env, users will sign in through prodamp user pool
  awsconfig.aws_cognito_identity_pool_id = 'eu-west-2:e8ca2f76-1ffb-4220-b353-ba79719908c6';
  awsconfig.aws_user_pools_id = 'eu-west-2_j5gGmzsA0';
  awsconfig.aws_user_pools_web_client_id = '5cceagnrppj0ma9vdffgunk5uu';
  awsconfig.oauth.domain = 'cyf-prodamp.auth.eu-west-2.amazoncognito.com';
}

awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsconfig);

function App({
  branding,
  credentialsObtained,
  currentOrganisation,
  getAdminOrganisations,
  getLicenses,
  getMetrics,
  getOrganisationMetrics,
  getProfile,
  getUserCredentials,
  cyfAdmin,
  isFooterAgreementOpen,
  isWhiteLabelApp,
  listCountries,
  listCurrencies,
  listFacilityTypes,
  listIndustries,
  listOrganisations,
  loggedIn,
  loginWithAws,
  showFooterAgreements,
  showHelpSidebar,
  showSidebar,
  whiteLabelAdmin,
  getSubscription,
  getWLAdminSettings,
  getOrganisationUsers,
  getReferralCode,
  hasSubscriptionActive,
  toggleModal,
  getTypes,
  adminPortalModal,
  adminPortalConfirmationModal,
  getEntities,
  getSubentities,
  getScope3Categories,
  notAutoClose,
  getFacilities,
  getWLSettings,
  registerPage,
  getOrgAgreements,
  canEditAdminPortal,
  xeroOrSageCallback,
  checkReportingYearEndIssue,
  processingStepFunction,
  orgDetails,
  getStepFunctionStatus,
  getStripeCustomerId,
  consultantAdmin,
  toggleOrgModal,
  adminPortalTables,
}) {
  const { textColor, bgColor } = branding.colors || {};
  const { font: { body: appFont } = {} } = branding?.fonts?.[0] || {};
  const { name, logo, favicon } = branding || {};
  const [statusIndex, setStatusIndex] = useState(0);

  const hlp = initHelpHero('E3TB0qW4dj');

  let tourID;

  const domain = window.location.hostname.split('.')[0];
  const pathname = window.location.pathname;
  const hostname = window.location.hostname;

  switch (domain) {
    case 'app':
      tourID = 'sjKBTHOxsT';
      break;
    case 'staging':
      tourID = 'IQxudhNfKZ';
      break;
    case 'localhost':
      tourID = 'Xw8aprKcjd';
      break;
    default:
  }

  const getFaviconEl = () => document.getElementById('favicon');
  const getProjectTitle = () => document.getElementById('title');

  if (branding) {
    const icon = getFaviconEl();
    const title = getProjectTitle();
    icon.href = favicon || logo;
    title.innerHTML = name || 'Carbon Your Footprint';
  }

  const [isModalOpen, setModalOpen] = useState(false);
  const [videoLink, setVideoLink] = useState('');

  const openModal = (link) => {
    setModalOpen(true);
    setVideoLink(link);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    // Load Initial Data
    (async () => {
      await getWLSettings();
      const user = await Auth.currentUserInfo();
      if (user) {
        await loginWithAws(user);
        getUserCredentials();
        domain !== 'localhost' && domain !== 'dev' && hlp.identify(user?.attributes?.sub);
      }
      getLicenses();
    })();
    //eslint-disable-next-line
  }, []);

  window.onbeforeunload = function () {
    if (orgDetails && !pathname.includes('register')) {
      localStorage.setItem('Organisation', orgDetails?.id);
      localStorage.setItem('orgDetails', JSON.stringify(orgDetails));
    }
    return null;
  };

  useEffect(() => {
    if (credentialsObtained) {
      getProfile().then((profile) => {
        const { isAdmin, whiteLabelAdmin, greenElementUser } = profile || {};
        if (isAdmin || whiteLabelAdmin || greenElementUser) {
          getAdminOrganisations();
        }
      });
      listOrganisations();
      getMetrics();
      getScope3Categories();
      listCountries();
      listCurrencies();
      listIndustries();
      listFacilityTypes();
      getTypes();
      getEntities();
      getSubentities();
    }
  }, [
    credentialsObtained,
    getProfile,
    getAdminOrganisations,
    listOrganisations,
    getMetrics,
    getScope3Categories,
    listCountries,
    listIndustries,
    listFacilityTypes,
    getTypes,
    getEntities,
    getSubentities,
  ]);

  useEffect(() => {
    // When on the register page, we don't need to fetch all the below data, just when the app is loaded
    if (currentOrganisation && !registerPage) {
      // getProfile();
      getOrgAgreements();
      getOrganisationMetrics();
      getReferralCode();
      getFacilities();
      getOrganisationUsers();
      checkReportingYearEndIssue(currentOrganisation).then((o) => {
        if (o.orgMissingCompulsoryData) {
          toggleOrgModal(true, null, 'orgMissingCompulsoryData');
        }
      });
      getWLAdminSettings();
      getStripeCustomerId().then(async (stripeCustomerId) => {
        if (stripeCustomerId) await getSubscription();
      });
    }
    //eslint-disable-next-line
  }, [currentOrganisation]);

  const { executionArn, status } = processingStepFunction || {};

  useEffect(() => {
    const newStatusIndex = statusIndex + 1;
    executionArn &&
      (!status || (status !== 'SUCCEEDED' && status !== 'FAILED')) &&
      setTimeout(async () => {
        await getStepFunctionStatus(executionArn);
        setStatusIndex(newStatusIndex);
      }, 1500);
  }, [executionArn, statusIndex, getStepFunctionStatus, status]);

  if (!loggedIn && registerPage) {
    return (
      <ThemeProvider theme={branding}>
        <Register />
      </ThemeProvider>
    );
  } else if (!loggedIn && window.location.pathname.includes('confirmemail')) {
    return (
      <ThemeProvider theme={branding}>
        <ResendConfirmationCode />
      </ThemeProvider>
    );
  } else if (!loggedIn && window.location.pathname.includes('xero/signup')) {
    return (
      <ThemeProvider theme={branding}>
        <XeroAuth />
      </ThemeProvider>
    );
  } else if (loggedIn && registerPage) {
    return (
      <ThemeProvider theme={branding}>
        <RedirectUsers />
      </ThemeProvider>
    );
  } else if (xeroOrSageCallback) {
    return (
      <ThemeProvider theme={branding}>
        <RedirectUsers xeroOrSageCallback={xeroOrSageCallback} />
      </ThemeProvider>
    );
  } else if (!branding || Object.keys(branding).length < 1) {
    // Add loading page here while branding is being fetched
    return <RedirectUsers showLoadingPage={true} />;
  } else if (!loggedIn) {
    return (
      <>
        <>
          {hostname.includes('worldlandtrust') && (
            <GoogleFontLoader fonts={[{ font: 'Quattrocento Sans', weights: [400, '400i', 700] }]} />
          )}
          {hostname.includes('adnetzero') && (
            <GoogleFontLoader fonts={[{ font: 'Roboto Slab', weights: [400, '400i', 700] }]} />
          )}
        </>
        <ThemeProvider theme={branding}>
          <Login />
        </ThemeProvider>
      </>
    );
  }

  const pageClass = `${showSidebar ? `sidebar-l sidebar-o sidebar-o-xs` : ``} ${
    showHelpSidebar ? `open-right-panel` : ``
  } side-scroll header-navbar-fixed`;

  return (
    <ThemeProvider theme={branding}>
      {hostname.includes('worldlandtrust') && (
        <GoogleFontLoader fonts={[{ font: 'Quattrocento Sans', weights: [400, '400i', 700] }]} />
      )}
      {hostname.includes('adnetzero') && <GoogleFontLoader fonts={[{ font: 'Roboto Slab', weights: [400, '400i', 700] }]} />}
      <Router>
        <div id='container' className={`${pageClass} appContainer`} style={{ color: textColor, '--fontFamily': appFont }}>
          <Navbar />
          <Sidebar openModal={openModal} />

          <main id='main-content' style={{ minHeight: '272px' }} className={!showSidebar ? `merge-left` : ''}>
            <section className='wrapper' style={{ '--appBgColor': bgColor }}>
              <Alert close={notAutoClose ? toggleModal : false} />
              <Modal open={adminPortalModal} onClose={toggleModal} closeAfterTransition>
                <Fade in={adminPortalModal}>
                  <div>
                    <AdminPortalModal />
                  </div>
                </Fade>
              </Modal>
              <Modal open={adminPortalConfirmationModal} onClose={toggleModal} closeAfterTransition>
                <Fade in={adminPortalConfirmationModal}>
                  <div>
                    <ConfirmationModal />
                  </div>
                </Fade>
              </Modal>
              <AnalysisModal />
              <VideoModal isOpen={isModalOpen} onClose={closeModal} link={videoLink} />
              <OrgModal />
              <Switch>
                {/* Admin Portal Tables */}
                {adminPortalTables.map((table, index) => {
                  // Tables from Admin Portal that can also be accessible for Consultant and White Label Admins
                  const nonCyfAdminTables = ['organisations', 'settings'];
                  const adminUsers = cyfAdmin || whiteLabelAdmin || consultantAdmin;
                  if (adminUsers && nonCyfAdminTables.includes(table)) {
                    if (table === 'settings') {
                      return (
                        <Route path={`/admin/${table}`} key={index}>
                          <SettingsPage />
                        </Route>
                      );
                    } else if (table === 'organisations') {
                      return (
                        <Route path={`/admin/${table}`} key={index}>
                          <ClientsList canEditAdminPortal={canEditAdminPortal} />
                        </Route>
                      );
                    }
                  } else if (cyfAdmin) {
                    return (
                      <Route path={`/admin/${table}`} key={index}>
                        <AdminPortalList canEditAdminPortal={canEditAdminPortal} />
                      </Route>
                    );
                  } else return null;
                  return null;
                })}

                {cyfAdmin && (
                  <Route path='/admin/conversionfactors'>
                    <ConversionFactors canEditAdminPortal={canEditAdminPortal} />
                  </Route>
                )}
                {cyfAdmin && (
                  <Route path='/admin/closestAirport'>
                    <ClosestAirportList />
                  </Route>
                )}
                {cyfAdmin && (
                  <Route path='/admin/usageData'>
                    <UsageDataList canEditAdminPortal={canEditAdminPortal} />
                  </Route>
                )}
                {/* End of admin Portal Tables */}

                {(hasSubscriptionActive || cyfAdmin) && (
                  <Route path='/xero/callback'>
                    <XeroApp />
                  </Route>
                )}
                {(hasSubscriptionActive || cyfAdmin) && (
                  <Route path='/sage/callback'>
                    <SageApp />
                  </Route>
                )}

                {(hasSubscriptionActive || cyfAdmin) && (
                  <Route path='/developer'>
                    <Developer />
                  </Route>
                )}

                {cyfAdmin && (
                  <Route path='/usage/:id'>
                    <BulkEditPage />
                  </Route>
                )}

                {cyfAdmin && (
                  <Route path='/admin/transferPortal'>
                    <TransferPortal />
                  </Route>
                )}

                <Route path='/profile'>
                  <Profile />
                </Route>

                <Route path='/organisations/:id'>
                  <Organisation />
                </Route>

                <Route path='/organisations'>
                  <Organisations />
                </Route>

                {(hasSubscriptionActive || cyfAdmin) && (
                  <Route path='/facilities/:id'>
                    <Facility />
                  </Route>
                )}

                {(hasSubscriptionActive || cyfAdmin) && (
                  <Route path='/facilities'>
                    <Facilities />
                  </Route>
                )}

                {(hasSubscriptionActive || cyfAdmin) && (
                  <Route path='/usage'>
                    <Usage />
                  </Route>
                )}

                {(hasSubscriptionActive || cyfAdmin) && (
                  <Route path='/consumption/:id'>
                    <ConsumptionReport />
                  </Route>
                )}

                {(hasSubscriptionActive || cyfAdmin) && (
                  <Route path='/report/:id'>
                    <Report />
                  </Route>
                )}

                {(hasSubscriptionActive || cyfAdmin) && (
                  <Route path='/reports'>
                    <Reports />
                  </Route>
                )}

                {(hasSubscriptionActive || cyfAdmin) && (
                  <Route path='/users'>
                    <UsersList />
                  </Route>
                )}

                {cyfAdmin && (
                  <Route path='/admin/sales/newContract'>
                    <NewContract />
                  </Route>
                )}

                {cyfAdmin && (
                  <Route path='/admin/sales/contract/:id'>
                    <Contract />
                  </Route>
                )}

                {cyfAdmin && (
                  <Route path='/admin/sales/contracts'>
                    <ContractsList />
                  </Route>
                )}

                {cyfAdmin && (
                  <Route path='/admin/sales/pricingBands'>
                    <PriceBands />
                  </Route>
                )}

                <Route path='/billing'>
                  <BillingPage />
                </Route>

                <Route path='/'>
                  <Dashboard hlp={hlp} tourID={tourID} />
                </Route>
              </Switch>
              {isFooterAgreementOpen && <OrgAgreementsModal />}
            </section>

            <footer style={{ background: '#fff', padding: '10px 20px', fontSize: '9px' }}>
              <span style={{ float: 'left' }}>
                {isWhiteLabelApp
                  ? 'Powered By Compare Your Footprint '
                  : `© 2016-${format(new Date(), 'yyyy')} Compare Your Footprint`}
              </span>

              <span style={{ float: 'right' }}>
                <button
                  //href={isWhiteLabelApp ? '#' : 'https://compareyourfootprint.com/privacy-policy/'}
                  onClick={() => {
                    if (isWhiteLabelApp) {
                      showFooterAgreements(true, 'privacyPolicy');
                    } else {
                      window.open('https://compareyourfootprint.com/privacy-policy/');
                    }
                  }}
                  style={{ cursor: 'pointer', border: 0, background: 'inherit' }}>
                  Privacy Policy
                </button>
              </span>

              <span style={{ float: 'right', margin: '0px 5px' }}> - </span>

              <span style={{ float: 'right' }}>
                <button
                  //href={isWhiteLabelApp ? '#' : 'https://compareyourfootprint.com/software-license-agreement/'}
                  onClick={() => {
                    if (isWhiteLabelApp) {
                      showFooterAgreements(true, 'tncs');
                    } else {
                      window.open('https://compareyourfootprint.com/software-license-agreement/');
                    }
                  }}
                  style={{ cursor: 'pointer', border: 0, background: 'inherit' }}>
                  Terms &amp; Conditions <i className='bi bi-123'></i>
                </button>
              </span>

              <div style={{ clear: 'both' }}></div>
            </footer>
            <div>
              {domain !== 'app' && !isWhiteLabelApp && (
                <div className='domain-bar'>You are currently on the {domain} version of CYF</div>
              )}
            </div>
          </main>

          <HelpSidebar />
        </div>
      </Router>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  const isFooterAgreementOpen = state.footerAgreements?.status;
  const org = state.organisation?.details;
  const profile = state.profile?.details;

  const registerPage = window.location.pathname.includes('register');
  const xeroOrSageCallback = ['xero', 'sage'].some((path) => window.location.pathname.includes(`${path}/callback`));

  // This array will be mapped to the admin portal tables -> Add here future admin portal tables
  const adminPortalTables = [
    'profiles',
    'entities',
    'industries',
    'industrySubcategory',
    'userPermissions',
    'reports',
    'seaDistances',
    'airports',
    'help',
    'electricitySources',
    'countries',
    'scope3Categories',
    'marketFactors',
    'subentities',
    'seatType',
    'vehicleType',
    'fuelType',
    'productionType',
    'loadType',
    'wasteType',
    'priceType',
    'originlocation',
    'finallocation',
    'factorprovider',
    'metrics',
    'facilities',
    'facilitiesType',
    'organisationMetrics',
    'benchmark',
    'addOns',
    'settings',
    'organisations',
    'usageTypeDetails',
    'integrationMappings',
    'currencies',
    'currency-rates',
    'stripe',
  ];

  return {
    checkedUsage: state?.admin?.checkboxes?.checkedUsage,
    branding: state.branding,
    showSidebar: state.sidebar.show,
    showHelpSidebar: state.help.show,
    isWhiteLabelApp: state?.whiteLabelAdmin?.whiteLabelId !== '0',
    cyfAdmin: profile?.cyfAdmin,
    ambassadorAdmin: profile?.ambassadorAdmin,
    whiteLabelAdmin: profile?.whiteLabelAdmin,
    consultantAdmin: profile?.consultantAdmin,
    canEditAdminPortal: profile?.canEditAdminPortal,
    whiteLabelAdminOrgs: state.admin?.whiteLabelAdminOrgs,
    loggedIn: profile ? true : false,
    credentialsObtained: profile?.credentials ? true : false,
    currentOrganisation: state.currentOrganisation,
    softwareLicenseAgreementConfirmed: profile?.legalAgreements?.softwareLicenseAgreementConfirmed,
    isFooterAgreementOpen,
    hasSubscriptionActive: org?.hasSubscriptionActive,
    adminPortalModal: state.admin?.modal?.adminPortalModal ? true : false,
    adminPortalConfirmationModal: state.admin?.modal?.confirmationModal ? true : false,
    notAutoClose: state.admin?.modal?.notAutoClose,
    registerPage,
    xeroOrSageCallback,
    processingStepFunction: state.processingImport?.status,
    orgDetails: state.organisation?.details,
    adminPortalTables,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(PROFILEACTIONS.getProfile()),
  getReferralCode: () => dispatch(PROFILEACTIONS.getReferralCode()),
  listOrganisations: () => dispatch(ORGANISATIONACTIONS.listOrganisations()),
  listCountries: () => dispatch(ORGANISATIONACTIONS.listCountries()),
  listIndustries: () => dispatch(ORGANISATIONACTIONS.listIndustries()),
  listCurrencies: () => dispatch(ORGANISATIONACTIONS.listCurrencies()),
  getOrgAgreements: () => dispatch(ORGANISATIONACTIONS.getOrgAgreements()),
  getOrganisationMetrics: () => dispatch(ORGANISATIONACTIONS.getOrganisationMetrics()),
  getWLAdminOrgs: () => dispatch(ORGANISATIONACTIONS.getWLAdminOrgs()),
  getOrganisationUsers: () => dispatch(ORGANISATIONACTIONS.getOrganisationUsers()),
  getWLAdminSettings: () => dispatch(ORGANISATIONACTIONS.getWLAdminSettings()),
  toggleOrgModal: (status, item, action) => dispatch(ORGANISATIONACTIONS.toggleModal(status, item, action)),
  checkReportingYearEndIssue: (currentOrganisation) =>
    dispatch(ORGANISATIONACTIONS.checkReportingYearEndIssue(currentOrganisation)),
  listFacilityTypes: () => dispatch(FACILITYACTIONS.getFacilityTypes()),
  getFacilities: () => dispatch(FACILITYACTIONS.getFacilities()),
  getMetrics: () => dispatch(METRICACTIONS.getMetrics()),
  getAdminOrganisations: () => dispatch(ADMINACTIONS.getAdminOrganisations()),
  isLoggedIn: () => dispatch(AUTHENTICATIONACTIONS.isLoggedIn()),
  loginWithAws: (username) => dispatch(AUTHENTICATIONACTIONS.loginWithAws(username)),
  getUserCredentials: () => dispatch(AUTHENTICATIONACTIONS.getUserCredentials()),
  getWLSettings: () => dispatch(AUTHENTICATIONACTIONS.getWLSettings()),
  getLicenses: () => dispatch(ACTIONS.getLicenses()),
  getStepFunctionStatus: (importArn) => dispatch(ACTIONS.getStepFunctionStatus(importArn)),
  getEntities: () => dispatch(ACTIONS.getEntities()),
  getSubentities: () => dispatch(ACTIONS.getSubentities()),
  showFooterAgreements: (status, agreement) => dispatch(ACTIONS.showFooterLegalAgreements(status, agreement)),
  whitelabelApp: (status) => dispatch(ACTIONS.whitelabelApp(status)),
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
  getTypes: () => dispatch(METRICACTIONS.getTypes()),
  getScope3Categories: () => dispatch(METRICACTIONS.getScope3Categories()),
  getStripeCustomerId: () => dispatch(PAYMENTACTIONS.getStripeCustomerId()),
  getSubscription: () => dispatch(PAYMENTACTIONS.getSubscription()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
