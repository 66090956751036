import _ from 'lodash';
import React from 'react';

import { connect } from 'react-redux';

const GreenhouseEmissionsAndIntensityRatioRow = ({
  report,
  totalEmissionsByScope,
  metrics,
  organisationDetails,
  organisationMetrics,
  scope,
  metricValues,
  industries,
}) => {
  if (!report || !organisationDetails || !organisationMetrics || !totalEmissionsByScope) {
    return null;
  }

  const { year } = report;

  const hasComparative = totalEmissionsByScope && totalEmissionsByScope[year - 1];

  const co2e = scope
    ? (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        totalEmissionsByScope[year][scope] &&
        totalEmissionsByScope[year][scope].co2e) ||
      0
    : (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        _.sumBy(Object.values(totalEmissionsByScope[year]), 'co2e')) ||
      0;

  const co2eMarket = scope
    ? (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        totalEmissionsByScope[year][scope] &&
        (totalEmissionsByScope[year][scope].co2e_market !== undefined
          ? totalEmissionsByScope[year][scope].co2e_market
          : totalEmissionsByScope[year][scope].co2e)) ||
      0
    : (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        _.sumBy(Object.values(totalEmissionsByScope[year]), (item) =>
          item.co2e_market !== undefined ? item.co2e_market : item.co2e
        )) ||
      0;

  const co2eComparative =
    hasComparative && scope
      ? (hasComparative && hasComparative[scope] && hasComparative[scope].co2e) || 0
      : (hasComparative && _.sumBy(Object.values(hasComparative), 'co2e')) || 0;

  const co2eMarketComparative = scope
    ? (hasComparative &&
        hasComparative[scope] &&
        (hasComparative[scope].co2e_market !== undefined
          ? hasComparative[scope].co2e_market
          : hasComparative[scope].co2e)) ||
      0
    : (hasComparative &&
        _.sumBy(Object.values(hasComparative), (item) =>
          item.co2e_market !== undefined ? item.co2e_market : item.co2e || 0
        )) ||
      0;

  const metricValuesThisYear = metricValues.find((metrics) => metrics.year === String(year));
  const metricValuesPrevYear = metricValues.find((metrics) => metrics.year === String(year - 1));

  const revenueThisYear = metricValuesThisYear && metricValuesThisYear.originalTurnover / 1000000;
  const revenueLastYear = metricValuesPrevYear && metricValuesPrevYear.originalTurnover / 1000000;

  return (
    <tr key={crypto.randomUUID()}>
      <td align='left'>{scope ? `Scope ${scope}` : `All Scopes`}</td>

      {hasComparative && (
        <td className='toggle-comparative toggle-co2e'>
          <span className='toggle-location'>
            {parseFloat(co2eComparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
          </span>
          <span className='toggle-market'>
            {parseFloat(co2eMarketComparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
          </span>
        </td>
      )}

      <td className='toggle-co2e'>
        <span className='toggle-location'>
          {parseFloat(co2e || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
        </span>
        <span className='toggle-market'>
          {parseFloat(co2eMarket || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
        </span>
      </td>

      {
        // Normalised by revenue
        metricValues && [
          hasComparative && (
            <td className='toggle-comparative toggle-revenue'>
              <span className='toggle-location'>
                {revenueLastYear
                  ? parseFloat(co2eComparative / revenueLastYear).toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                    })
                  : 'N/A'}
              </span>
              <span className='toggle-market'>
                {revenueLastYear
                  ? parseFloat(co2eMarketComparative / revenueLastYear).toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                    })
                  : 'N/A'}
              </span>
            </td>
          ),

          <td style={{ textAlign: 'right' }} className='toggle-revenue'>
            <span className='toggle-location'>
              {revenueThisYear
                ? parseFloat(co2e / revenueThisYear).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                : 'N/A'}
            </span>
            <span className='toggle-market'>
              {revenueThisYear
                ? parseFloat(co2eMarket / revenueThisYear).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                : 'N/A'}
            </span>
          </td>,
        ]
      }

      {metricValues &&
        organisationDetails &&
        ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
          const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
          const metricID = orgIndustry?.[ir];
          const metric = metrics && metrics.find((metric) => metric.id === metricID);

          const metricValue = metricValuesThisYear && metricValuesThisYear[`metric${index + 1}`];
          const prevMetricValue = metricValuesPrevYear && metricValuesPrevYear[`metric${index + 1}`];

          return (
            metric &&
            metricID &&
            metricID !== '230393' && [
              hasComparative && (
                <td className={`toggle-m${index + 1} toggle-comparative`} key={crypto.randomUUID()}>
                  <span className='toggle-location'>
                    {prevMetricValue
                      ? parseFloat(co2eComparative / prevMetricValue).toLocaleString(navigator.language, {
                          minimumFractionDigits: 2,
                        })
                      : 'N/A'}
                  </span>
                  <span className='toggle-market'>
                    {prevMetricValue
                      ? parseFloat(co2eMarketComparative / prevMetricValue).toLocaleString(navigator.language, {
                          minimumFractionDigits: 2,
                        })
                      : 'N/A'}
                  </span>
                </td>
              ),
              <td style={{ textAlign: 'right' }} className={`toggle-m${index + 1}`} key={index}>
                <span className='toggle-location'>
                  {metricValue
                    ? parseFloat(co2e / metricValue).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                    : 'N/A'}
                </span>
                <span className='toggle-market'>
                  {metricValue
                    ? parseFloat(co2eMarket / metricValue).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                    : 'N/A'}
                </span>
              </td>,
            ]
          );
        })}
    </tr>
  );
};

const mapStateToProps = (state) => {
  const { totalEmissionsByScope, years, filter } = state.consumption || {};

  const { organisation, report } = state;

  return {
    report: report?.details,
    totalEmissionsByScope: totalEmissionsByScope,
    consumptionFilter: filter || [],
    years: years || [],
    metrics: state.metric?.list,
    organisationDetails: organisation?.details,
    organisationMetrics: organisation?.metrics,
    metricValues: organisation?.metrics ? organisation.metrics : null,
    industries: organisation?.industries,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GreenhouseEmissionsAndIntensityRatioRow);
