import React, { useState, useEffect } from 'react';
import axios from 'axios';
import createSignedRequest from '../../../store/actions/createSignedRequest';
import { connect } from 'react-redux';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import { AutoSizer, Table, Column } from 'react-virtualized';
import LinearProgress from '@mui/material/LinearProgress';
import _, { isArray } from 'lodash';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Tooltip from '@mui/material/Tooltip';
import Select from 'react-select';
import { adminPortalSelect } from '../../utils/ReactSelectStyles';
import { getIndexCount } from '../../utils/GlobalFunctions';
const { apiURL } = require('../../../store/actions/apiURL');

const ClosestAirportListTable = ({
  branding,
  getAllItemsFromDB,
  closestAiport,
  tableName,
  page,
  filterSize,
  tableSize,
  setTableSize,
  setTotalItems,
  getRangeFromIndex,
}) => {
  const { headers } = branding?.fonts[0]?.font;

  const [data, setData] = useState([]);
  const [filtering, setFiltering] = useState('');

  const filteringOpenSearch = async () => {
    const response = await axios(
      await createSignedRequest(
        'GET',
        apiURL +
          `/admin/portal/filter/${tableName}${filtering ? `?query=${filtering}&page=${page}&size=${tableSize.value}` : ''}`,
        null,
        null
      )
    );

    if (response?.data?.items) {
      setData(response?.data?.items);
      setTotalItems(response?.data?.itemCount);
    }
  };

  const filterClosestAirport = () => {
    const filteringAirports = closestAiport?.filter(
      (airport) =>
        !filtering ||
        airport.id?.toLowerCase().trim().includes(filtering?.toLowerCase().trim()) ||
        airport.airport?.toLowerCase().trim().includes(filtering?.toLowerCase().trim())
    );

    if ((filteringAirports.length === 0) & (filtering.length > 4)) {
      filteringOpenSearch();
    } else setData(filteringAirports);
  };

  useEffect(() => {
    (async () => {
      const indexCount = await getIndexCount(tableName);
      setTotalItems(indexCount);
      await getRangeFromIndex(tableName, tableSize?.value, page);
    })();
  }, [page, tableSize, getRangeFromIndex, setTotalItems, tableName]);

  useEffect(() => {
    filterClosestAirport();
    if (!filtering) {
      setTotalItems(null);
    }
    // eslint-disable-next-line
  }, [closestAiport, filtering]);

  if (!closestAiport?.length > 0 && !data?.length > 0) {
    return <LinearProgress color='inherit' />;
  }

  if (data && !isArray(data)) {
    //Error Message
    return <div>{closestAiport}</div>;
  }

  const formatCellValue = ({ cellData, dataKey }) => {
    if (dataKey) {
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{cellData}</p>}>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {cellData}
            </div>
          </Tooltip>
        </div>
      );
    }
  };

  return (
    <section className='panel' style={{ fontFamily: headers }}>
      <header
        className='panel-heading'
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        Closest Airport
        <div style={{ marginRight: 10, display: 'flex' }}>
          <TextField
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
            id='outlined-basic'
            label='Search'
            variant='outlined'
            size='small'
            sx={{ marginLeft: 5 }}
            onChange={(e) => {
              setFiltering(e.target.value);
            }}
          />
          <Select
            options={filterSize}
            name={'tableSize'}
            defaultValue={filterSize[0]}
            onChange={(selected) => setTableSize(selected)}
            styles={{ ...adminPortalSelect, control: (x) => ({ ...x, ...adminPortalSelect.control, width: 100 }) }}
            placeholder={'Table Size'}
            value={tableSize}
          />
        </div>
      </header>
      <div className='panel-body' style={{ height: '100vh' }}>
        {!data?.length > 0 && (
          <Alert severity='warning' sx={{ fontSize: 15, width: '50%' }}>
            <AlertTitle>No Match Found</AlertTitle>
            There are no search results!
          </Alert>
        )}
        <div style={{ minWidth: '100%', height: '100%', display: !data?.length > 0 ? 'none' : 'block' }}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width}
                height={height}
                rowHeight={50}
                headerHeight={60}
                headerStyle={{ verticalAlign: 'bottom', width: '100%', display: 'flex', justifyContent: 'center' }}
                rowStyle={{ textAlign: 'center' }}
                headerClassName='admin_portal_column_headers'
                rowCount={(data && data.length) || 0}
                rowGetter={({ index }) => data[index]}>
                <Column label='ID' dataKey='id' width={600} cellRenderer={formatCellValue} />
                <Column label='Airport' dataKey='airport' width={400} cellRenderer={formatCellValue} />
                <Column label='Latitude' dataKey='lat' width={455} cellRenderer={formatCellValue} />
                <Column label='Longitude' dataKey='lng' width={455} cellRenderer={formatCellValue} />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { page, setTableSize, tableSize } = ownProps;
  const tableName = 'closestAirport';
  const sortedClosestAirport = _.sortBy(state?.admin?.indexItems?.items, ['airport']);

  return {
    closestAiport: sortedClosestAirport || state?.admin?.indexItems?.items,
    branding: state.branding,
    tableName,
    filterSize: state.admin?.filterSize,
    page,
    tableSize,
    setTableSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllItemsFromDB: (table, filter) => dispatch(ADMINACTIONS.getAllItemsFromDB(table, filter)),
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
  filterFromOpeanSearch: (index, query) => dispatch(ADMINACTIONS.filterFromOpeanSearch(index, query)),
  getRangeFromIndex: (index, size, from) => dispatch(ADMINACTIONS.getRangeFromIndex(index, size, from)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClosestAirportListTable);
