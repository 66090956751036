import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import ConsumptionFacilityChart from './ConsumptionFacilityChart';
import ConsumptionSubentity from './ConsumptionSubentity';

import { CategoryTableTitle } from '../../styledComponents/GlobalStyle';

const PercentRenewable = ({ years, months, electricityRenewable }) => {
  if (months && months.length > 0) {
    return (
      <tr className='title-row' style={{ textAlign: 'left' }}>
        <td className='table-title' colSpan='1'>
          % Renewable
        </td>
        {months.map((month) => (
          <td className='table-title' colSpan='1'>
            {(electricityRenewable[month].renewable * 100) / electricityRenewable[month].total}%
          </td>
        ))}
      </tr>
    );
  }

  return (
    <tr className='title-row' style={{ textAlign: 'left' }}>
      <td className='table-title' colSpan='1'>
        % Renewable
      </td>
      {years.map((year) => (
        <td key={crypto.randomUUID()} className='table-title' colSpan='1'>
          {electricityRenewable &&
            electricityRenewable[year] &&
            (electricityRenewable[year].renewable * 100) / electricityRenewable[year].total}
          %
        </td>
      ))}
    </tr>
  );
};

const ConsumptionEntity = ({
  entityID,
  entity,
  subentities,
  years,
  months,
  consumption,
  electricityRenewable,
  metricFilter,
  yearFilter,
  monthFilter,
  totalYears,
  metricOptions,
}) => {
  const hasSolarConsumed = entityID === '151936' && subentities.find((item) => item.id === '233408');

  if (months && months.length > 0) {
    return (
      <>
        <tr className='title-row' style={{ textAlign: 'left' }}>
          <CategoryTableTitle
            className='table-title'
            colSpan={1 + (12 - monthFilter) * (totalYears - yearFilter) * (metricOptions - metricFilter)}>
            {entity.name}
          </CategoryTableTitle>
        </tr>

        {subentities?.map((subentity) => (
          <ConsumptionSubentity entity={entity} subentity={subentity} />
        ))}

        {hasSolarConsumed && (
          <PercentRenewable
            electricityRenewable={electricityRenewable}
            entityID={entityID}
            consumption={consumption}
            months={months}
            subentities={subentities}
          />
        )}

        <ConsumptionFacilityChart entityID={entityID} />
      </>
    );
  }

  return (
    <>
      <tr
        key={crypto.randomUUID()}
        className={`title-row-${entity?.name?.split(' ').join('').replace(':', '-')}`}
        style={{ textAlign: 'left' }}>
        <CategoryTableTitle className='table-title' colSpan='100%'>
          {entity.name}
        </CategoryTableTitle>
      </tr>

      {subentities?.map((subentity) => (
        <ConsumptionSubentity key={crypto.randomUUID()} entity={entity} subentity={subentity} />
      ))}

      {hasSolarConsumed && (
        <PercentRenewable
          electricityRenewable={electricityRenewable}
          entityID={entityID}
          consumption={consumption}
          years={years}
          subentities={subentities}
        />
      )}

      <ConsumptionFacilityChart entityID={entityID} />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { data, years, months, electricityRenewable } = state.consumption;

  const { subentities, entities } = state;

  const year = state?.report?.details?.year;

  const { entityID } = ownProps;

  const entitySubentities = data?.[entityID] && Object.keys(data[entityID]);

  const entity = _.find(entities, { id: entityID });

  const filteredSub = subentities && subentities.filter(({ id }) => entitySubentities?.includes(String(id)));

  const neededSubentities = subentities && _.orderBy(filteredSub, ['name']);

  return {
    entity,
    year,
    years: years || [],
    months: months || [],
    consumption: data[entityID],
    subentities: neededSubentities,
    electricityRenewable,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConsumptionEntity);
