import md5 from 'md5';
import cleanDeep from 'clean-deep';

let supportedFilters = [
  'entity',
  'subentity',
  /* 'seat',
  'vehicle',
  'fuel',
  'production',
  'load',
  'waste',*/
  'metric',
  'facility',
  'year',
];

const makeHash = (obj) => {
  let filters = {};
  Object.keys(obj).forEach((key) => {
    if (supportedFilters.includes(key)) filters[key] = obj[key];
  });
  filters = cleanDeep(filters);
  const hash = filters && md5(JSON.stringify(filters, Object.keys(filters).sort()));
  return hash;
};

const exports = { supportedFilters, makeHash };
export default exports;
