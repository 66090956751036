import React from 'react';
import { connect } from 'react-redux';

import EmissionsDisaggregatedByFacilityRow from './EmissionsDisaggregatedByFacilityRow';

const EmissionsDisaggregatedByFacility = ({ facilityKeys, facilityListFilteredByKeys }) => {
  if (!(facilityKeys && facilityKeys.length > 1)) {
    return null;
  }

  return (
    <div>
      <br />
      <br />
      <h3>Emissions disaggregated by facility</h3>
      <table className='table table-hover general-table' style={{ textAlign: 'right' }}>
        <thead>
          <tr>
            <th width='20%'>Facility</th>
            <th width='20%' style={{ textAlign: 'right' }}>
              Total (tCO2e)
            </th>
            <th width='20%' style={{ textAlign: 'right' }}>
              Scope 1 (tCO2e)
            </th>
            <th width='20%' style={{ textAlign: 'right' }}>
              Scope 2 (tCO2e)
            </th>
            <th width='20%' style={{ textAlign: 'right' }}>
              Scope 3 (tCO2e incl. WTT*)
            </th>
          </tr>
        </thead>
        <tbody>
          {facilityListFilteredByKeys &&
            facilityListFilteredByKeys.map((facility) => (
              <EmissionsDisaggregatedByFacilityRow key={facility.id} facilityID={facility.id} />
            ))}
        </tbody>
      </table>
      <br></br>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { emissionsByFacility } = state.consumption;

  const facilityKeys =
    emissionsByFacility &&
    state.report &&
    state.report.details &&
    state.report.details.year &&
    emissionsByFacility[state.report.details.year] &&
    Object.keys(emissionsByFacility[state.report.details.year]);

  const facilityList = state.facility && state.facility.list;

  const facilityListFilteredByKeys =
    facilityList &&
    Array.isArray(facilityList) &&
    facilityList
      .filter((item) => facilityKeys && facilityKeys.includes(item.id.toString()))
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

  return {
    facilityKeys,
    facilityListFilteredByKeys,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsDisaggregatedByFacility);
