import React, { useEffect } from 'react';
import ACTIONS from '../../store/action';
import { connect } from 'react-redux';
import { Auth } from '@aws-amplify/auth';
import { Waveform } from '@uiball/loaders';

const RedirectUsers = ({
  branding,
  profileDetails,
  xeroOrSageCallback,
  xeroCallback,
  sageCallBack,
  wlSettings,
  showLoadingPage,
}) => {
  const { name, clientWebsite, logo } = branding || {};
  const { bgColor, buttonHover, primaryHeaderText, primaryColor } = branding.colors || {};
  const { firstName, lastName } = profileDetails || {};
  const { liveDomain } = wlSettings || {};

  const signOut = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log('Error Signing Out: ', error);
    }
  };

  useEffect(() => {
    if (showLoadingPage) return;
    else if (xeroOrSageCallback) {
      if (window.location.pathname.includes('xero')) {
        xeroCallback();
      } else sageCallBack();
    }
  }, [xeroOrSageCallback, xeroCallback, sageCallBack, showLoadingPage]);

  if (xeroOrSageCallback || showLoadingPage) {
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <div
          className='container'
          style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {!showLoadingPage && (
              <>
                <h3 style={{ marginBottom: 10, color: 'black' }}>Connecting your Carbon Footprint Tracker...</h3>
                <span style={{ color: 'black' }}> Please do not refresh or hit the back button.</span>
              </>
            )}
            <Waveform size={40} lineWeight={3.5} speed={1} color='black' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='login-body' style={{ height: '100vh', width: '100%', display: 'flex' }}>
      <div className='logoSide' style={{ background: bgColor }}>
        <div className='logoDescription'>
          <img
            src={logo}
            alt={name}
            style={{ maxWidth: '30vw', cursor: 'url(), pointer' }}
            onClick={() => window.open(`https://${clientWebsite}`, '_blank')}
          />
        </div>
      </div>
      <div className='signInSide' style={{ background: buttonHover }}>
        <div className='signInFields' style={{ width: '35vw' }}>
          <h1 style={{ marginBottom: '1.5vh', color: primaryHeaderText }}>Account Registered</h1>

          <div className='row' style={{ marginBottom: 20 }}>
            <div className='col-xs-10' style={{ width: '100%', fontSize: 12 }}>
              <div className='form-group' style={{ marginBottom: 10 }}>
                <h5 style={{ lineHeight: '20px', color: primaryHeaderText }}>
                  There's already an account registered under this email account!
                </h5>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-xs-10' style={{ width: '100%', fontSize: 12 }}>
              <div className='form-group' style={{ display: 'flex', justifyContent: 'center' }}>
                <a
                  className='primary-button'
                  style={{ color: 'white', fontSize: '1.5rem', backgroundColor: primaryColor }}
                  href={liveDomain === 'localhost' ? `https://localhost:3000` : `https://${liveDomain}`}
                  target='_blank'
                  rel='noreferrer'>
                  Go to Application
                </a>
              </div>
            </div>
            <div className='col-xs-10' style={{ width: '100%', fontSize: '1rem', marginTop: 20 }}>
              <div className='form-group' style={{ marginBottom: 10, color: primaryHeaderText }}>
                <span>
                  Not {firstName} {lastName}?{' '}
                  <span onClick={signOut} style={{ color: 'red', cursor: 'pointer' }}>
                    Log out
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  xeroCallback: () => dispatch(ACTIONS.xeroCallback()),
  sageCallBack: () => dispatch(ACTIONS.sageCallBack()),
});

const mapStateToProps = (state) => {
  return {
    branding: state.branding,
    currentOrganisation: state.currentOrganisation,
    profileDetails: state?.profile?.details,
    isWhiteLabelApp: state.isWhiteLabelApp,
    wlSettings: state.whiteLabelAdmin,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectUsers);
