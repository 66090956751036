import _ from 'lodash';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import gfm from 'remark-gfm';
import * as yup from 'yup';
import ACTIONS from '../../store/action';
import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';
import privacyPolicy from '../Register/PrivacyPolicy';
import softwareLicenseAgreement from '../Register/SoftwareLicenseAgreement';
import ValidationMessage from '../utils/ValidationMessage';

import { AuthBtn } from '../../styledComponents/GlobalStyle';

const OrgAgreementModal = ({
  branding,
  confirmUserAgreement,
  userLicenseAgreement,
  showFooterAgreements,
  isFooterAgreementOpen,
  legalAgreement,
  wlSettings,
}) => {
  const { primaryColor } = branding.colors || {};
  const { name, whiteLabelId } = wlSettings || {};
  const [validationError, setValidationError] = useState(null);
  const [softwareLicenseConfirmed, setSoftwareLicenseConfirmed] = useState(false);
  const isWhiteLabelApp = whiteLabelId ? (whiteLabelId !== '0' ? true : false) : undefined;

  let validationSchema = yup.object().shape({
    softwareLicenseConfirmed: yup
      .boolean()
      .oneOf([true], 'Accepting the Software License Agreement and Privacy Policy is required')
      .required(),
  });

  const confirmOrgAgreements = async () => {
    await validationSchema
      .validate({ softwareLicenseConfirmed: softwareLicenseConfirmed, userLicenseConfirmed: true }, { abortEarly: false })
      .then(async () => {
        confirmUserAgreement(true);
      })
      .catch((error) => {
        console.log('ValidationError', error);
        setValidationError(error.inner);
      });
  };

  if (isFooterAgreementOpen) {
    // Modals to show just for white label apps when click on the Terms & Conditions
    return (
      <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
        <div className='modal-dialog userLicenseAgreementModal'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button onClick={() => showFooterAgreements(false, null)} className='close'>
                x
              </button>
              <h4 className='modal-title'>
                {legalAgreement === 'tncs' && 'Software License Agreement and User License Agreement'}
                {legalAgreement === 'privacyPolicy' && 'Privacy Policy'}
              </h4>
            </div>
            <div
              className='modal-body'
              style={{ height: '85vh', overflow: 'auto', fontSize: '12px', background: 'white', borderRadius: '6px' }}>
              <div style={{ textAlign: 'justify', color: 'black' }}>
                {legalAgreement === 'tncs' && (
                  <>
                    <br />
                    <p>Powered by Compare Your Footprint Ltd for {name}.</p>
                    <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
                      Software License Agreement and User License Agreement
                    </h4>
                    <br />

                    <>
                      <ReactMarkdown
                        remarkPlugins={[gfm]}
                        className={'userAgreement-text'}
                        children={softwareLicenseAgreement()}
                      />
                      <ReactMarkdown
                        remarkPlugins={[gfm]}
                        className={'userAgreement-text'}
                        children={userLicenseAgreement}
                      />
                    </>
                  </>
                )}

                {legalAgreement === 'privacyPolicy' && (
                  <>
                    <p>Powered by Compare Your Footprint Ltd for {name}.</p>

                    <ReactMarkdown
                      remarkPlugins={[gfm]}
                      className={'privacyPolicy-text privacy-policy-text'}
                      children={privacyPolicy()}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)', zIndex: 2000000 }}>
      <div className='modal-dialog userLicenseAgreementModal'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>Software License Agreement and Privacy Policy</h4>
          </div>
          <div
            className='modal-body'
            style={{ height: '85vh', overflow: 'auto', fontSize: '12px', background: 'white', borderRadius: '6px' }}>
            <div style={{ textAlign: 'justify', color: 'black' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isWhiteLabelApp === false ? (
                  <ReactMarkdown>
                    ![ScreenShot](https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/branding/compareyourfootprint/Picture+1.jpg)
                  </ReactMarkdown>
                ) : (
                  <p>Powered by Compare Your Footprint Ltd for {name}.</p>
                )}
              </div>

              <p className='warning-agreement-text' style={{ '--warning-bgColor': primaryColor }}>
                Please review these agreements and click to accept to continue.
              </p>

              <ReactMarkdown remarkPlugins={[gfm]} className={'userAgreement-text'} children={softwareLicenseAgreement()} />
              <ReactMarkdown
                remarkPlugins={[gfm]}
                className={'privacyPolicy-text privacy-policy-text'}
                children={privacyPolicy()}
              />
            </div>
            <div style={{ fontSize: '12px', color: '#333', margin: 10, marginTop: 20 }}>
              <div id='accept-ula-checkbox' style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type='checkbox'
                  style={{ marginBottom: 10 }}
                  onChange={() => setSoftwareLicenseConfirmed(!softwareLicenseConfirmed)}
                />
                <label style={{ fontWeight: 400, marginBottom: 7, marginLeft: 5 }}>
                  By clicking you are accepting to our <strong>Software License Agreement</strong> and{' '}
                  <strong>Privacy Policy</strong>.
                </label>
              </div>

              <ValidationMessage message={_.find(validationError, { path: 'softwareLicenseConfirmed' })?.message} />

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBotton: 20 }}>
                <AuthBtn
                  className='btn btn-lg btn-login btn-block onceonly'
                  style={{ color: 'white', width: 'fit-content', fontSize: 16, padding: '10 10' }}
                  onClick={() => confirmOrgAgreements()}>
                  Confirm
                </AuthBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  confirmUserAgreement: (inclOrg) => dispatch(AUTHENTICATION_ACTIONS.confirmUserAgreement(inclOrg)),
  showFooterAgreements: (status, agreement) => dispatch(ACTIONS.showFooterLegalAgreements(status, agreement)),
});

const mapStateToProps = (state) => {
  const { softwareLicenseAgreementConfirmed } = state.profile.details.legalAgreements || {};
  const licenses = state.licenses;
  const userLicenseAgreement =
    licenses && Array.isArray(licenses) && licenses.find((license) => license.type === 'User License Agreement').text;

  const isFooterAgreementOpen = state.footerAgreements?.status;
  const legalAgreement = state.footerAgreements?.agreement;

  return {
    slaAgreementConfirmed: softwareLicenseAgreementConfirmed,
    wlSettings: state.whiteLabelAdmin,
    userLicenseAgreement,
    branding: state.branding,
    isFooterAgreementOpen,
    legalAgreement,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgAgreementModal);
