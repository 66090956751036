import axios from 'axios';
import { showLoader } from '../action.js';
import createSignedRequest from './createSignedRequest';
import { getOrganisation } from './organisationActions';
const { apiURL } = require('./apiURL');

const Types = {
  PROFILE_GETTING: 'PROFILE_GETTING',
  PROFILE_GET: 'PROFILE_GET',
  PROFILE_SAVING: 'PROFILE_SAVING',
  PROFILE_SAVE: 'PROFILE_SAVE',
  PROFILE_ADMIN: 'PROFILE_ADMIN',
  PROFILE_SETTING_DOMAIN_EMAIL: 'PROFILE_SETTING_DOMAIN_EMAIL',
  PROFILE_SETTING_DOMAIN_APP_STATUS: 'PROFILE_SETTING_DOMAIN_APP_STATUS',
  CREATE_WL_DOMAIN_STATUS: 'PROFILE_CREATE_WL_DOMAIN_STATUS',
  GENERATING_DNS_RECORDS: 'PROFILE_GENERATING_DNS_RECORDS',
  GET_SHORT_CODE: 'PROFILE_GET_SHORT_CODE',
  UPDATE_ORGANISATION: 'PROFILE_UPDATE_ORGANISATION',
};

const gettingProfile = () => ({
  type: Types.PROFILE_GETTING,
});

const updateProfile = (response) => ({
  type: Types.PROFILE_GET,
  payload: response,
});

const getProfile = () => {
  return async (dispatch, getState) => {
    dispatch(gettingProfile());

    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest(
          'GET',
          apiURL + `/my/profile`,
          null,
          state.currentOrganisation && {
            Organisation: String(state.currentOrganisation),
          }
        )
      );

      console.log('getProfile', response.data);

      if (response.data.profile) {
        dispatch(updateProfile(response.data));
        return response.data.profile;
      }
    } catch (e) {
      console.log('getProfile Error', e);
    }
  };
};

const savingProfile = (status) => ({
  type: Types.PROFILE_SAVING,
  status,
});

const generatingDNSRecords = (status) => ({
  //Loader for Setting WL Domain
  type: Types.GENERATING_DNS_RECORDS,
  status,
});

const profileSaved = (response) => ({
  type: Types.PROFILE_SAVE,
  payload: response,
});

const saveProfile = (profile) => {
  return async (dispatch, getState) => {
    dispatch(savingProfile(true));

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + `/my/profile`,
          JSON.stringify(profile)
          //{'Organisation': String(state.currentOrganisation)}
        )
      );

      console.log('saveProfile', response);
      dispatch(profileSaved(response.data));
      dispatch(savingProfile(false));
    } catch (e) {
      dispatch(savingProfile(false));
      console.log('saveProfile Error', e);
    }
  };
};

const settingDomainEmail = (response) => ({
  type: Types.PROFILE_SETTING_DOMAIN_EMAIL,
  response,
});

const setDomainEmail = (domain) => {
  return async (dispatch, getState) => {
    dispatch(savingProfile(true));

    const state = getState();
    const profileName = state.profile?.details?.firstName;
    const profileEmail = state.profile?.details?.email;

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + `/my/createSesDomain`,
          JSON.stringify({ domain, profileName, profileEmail }),
          { Organisation: String(state.currentOrganisation) }
        )
      );

      console.log('setDomainEmail', response);
      dispatch(settingDomainEmail(response.data));
      dispatch(savingProfile(false));
    } catch (e) {
      dispatch(savingProfile(false));
      console.log('setDomainEmail Error', e);
    }
  };
};

/* const updateOrganisation = (response) => ({
  type: Types.UPDATE_ORGANISATION,
  payload: response,
}); */

const connectStripeAccount = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/payment/stripe/connectStripeAccount/`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('connectStripeAccount', response);

      const { stripeConnectURL } = response.data;
      window.location.href = stripeConnectURL;
    } catch (e) {
      console.log('Error Connecting Stripe', e);
      return e;
    }
  };
};

const settingWhiteLabelAppDomain = (response) => ({
  type: Types.PROFILE_SETTING_DOMAIN_APP_STATUS,
  response,
});

const setWhiteLabelAppDomain = (domain) => {
  return async (dispatch, getState) => {
    dispatch(savingProfile(true));
    dispatch(generatingDNSRecords(true));

    const state = getState();
    const profileName = state.profile?.details?.firstName;
    const profileEmail = state.profile?.details?.email;

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + `/my/createWhiteLabelDomain`,
          JSON.stringify({ domain, profileName, profileEmail }),
          { Organisation: String(state.currentOrganisation) }
        )
      );

      console.log('setWhiteLabelAppDomain', response);

      // we got the data, add it to the global state, and stop loading
      dispatch(settingWhiteLabelAppDomain(response.data));
      dispatch(savingProfile(false));
    } catch (e) {
      dispatch(generatingDNSRecords(false));
      dispatch(savingProfile(false));
      console.log('setWhiteLabelAppDomain Error', e);
    }
  };
};

const processingStepFunctionStatus = (response) => ({
  type: Types.CREATE_WL_DOMAIN_STATUS,
  payload: response,
});

const getStepFunctionStatus = (importArn) => {
  return async (dispatch, getState) => {
    dispatch(generatingDNSRecords(true));

    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/my/stepFunctionStatus/' + importArn, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('response', response);
      dispatch(processingStepFunctionStatus(response.data));

      if (response.data?.data?.status === 'SUCCEEDED') {
        //Step Function Status is Succeeded
        dispatch(settingWhiteLabelAppDomain(response.data.data));
        dispatch(generatingDNSRecords(false));
        dispatch(getOrganisation());
      }
    } catch (e) {
      console.log('getStepFunctionStatus Error:', e);
      dispatch(generatingDNSRecords(false));
    }
  };
};

const toggleAdminProfile = (status, admin) => ({
  type: Types.PROFILE_ADMIN,
  payload: { status, admin },
});

const setShortCode = (response) => ({
  type: Types.GET_SHORT_CODE,
  response,
});

const getReferralCode = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/admin/referralCode`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );
      console.log('getReferralCode', response);
      dispatch(setShortCode(response.data));
    } catch (e) {
      console.log('Error Getting Referral Code');
    }
  };
};

const confirmPromoterAgreement = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const isWhiteLabelApp = state.isWhiteLabelApp;

    dispatch(showLoader(true));

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/my/confirmPromoterAgreement', JSON.stringify({ isWhiteLabelApp }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('confirmPromoterAgreement Response', response.data);

      //dispatch(toggleAlertMessage(true, response.data.message, response.data.type));
      dispatch(showLoader(false));
      dispatch(getReferralCode());
    } catch (e) {
      dispatch(showLoader(false));
      console.log('confirmPromoterAgreement Error:', e);
    }
  };
};

const Actions = {
  getProfile,
  saveProfile,
  setDomainEmail,
  toggleAdminProfile,
  connectStripeAccount,
  setWhiteLabelAppDomain,
  getStepFunctionStatus,
  getReferralCode,
  confirmPromoterAgreement,
};

export { Actions, Types };
