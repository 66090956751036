import { Auth } from '@aws-amplify/auth';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import * as yup from 'yup';
import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';
import ConfirmEmail from '../Register/ConfirmEmail';

import { AuthBtn } from '../../styledComponents/GlobalStyle';

const ResendConfirmationCode = ({ branding, loggingIn, processing }) => {
  const [confirmationCode, setConfirmationCode] = useState(false);
  const [newConfirmationCode, setNewConfirmationCode] = useState(false);
  const [error, setError] = useState(false);
  const [username, setUsername] = useState(null);
  const [newPassword, setnewPassword] = useState('');
  const [validationError, setValidationError] = useState(null);

  let validationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required('Password is required')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Password must contain 8 characters, one uppercase, one lowercase,one number and one special case character'
      ),
  });

  const errorsToDisplay = {};

  validationError?.forEach((error) => {
    if (error.path === 'newPassword' && !(error.type === 'min' || error.type === 'matches')) {
      errorsToDisplay['password'] = error.message;
    } else if (error.type === 'min') {
      errorsToDisplay['min'] = error.message;
    } else if (error.type === 'matches') {
      errorsToDisplay['matches'] = error.message;
    }
  });

  const resendCode = async () => {
    await validationSchema
      .validate(
        {
          newPassword: newPassword,
        },
        { abortEarly: false }
      )
      .then(async () => {
        try {
          loggingIn(true);
          await Auth.resendSignUp(username);
          setConfirmationCode(true);
          setNewConfirmationCode(true);
          console.log('code resent successfully');
          loggingIn(false);
        } catch (error) {
          setError(error);
          console.log('error resending the code', error);
        }
      })
      .catch((error) => {
        console.log('ValidationError', error);
        setValidationError(error.inner);
      });
  };

  if (confirmationCode) {
    return <ConfirmEmail username={username} newConfirmationCode={newConfirmationCode} newPassword={newPassword} />;
  }

  return (
    <div className='login-body' style={{ background: branding.colors.bgColor }}>
      <div className='container' style={{ height: '100vh' }}>
        <form className='form-signin' id='regForm'>
          <div className='login-wrap' style={{ height: 'auto', padding: 0, display: 'flex', flexDirection: 'column' }}>
            <h2
              className='form-signin-heading'
              style={{
                background: branding.colors.primaryColor,
                fontSize: branding.fonts?.[0].size.find((size) => size === '2em'),
                fontWeight: branding.fonts?.[0].weight.find((weight) => weight === 700),
              }}>
              Confirmation Code
            </h2>

            {error && (
              <div className='col-xs-12 alert-danger alert' style={{ marginBottom: 0 }}>
                <h5 style={{ textAlign: 'left' }}>Error: {error.log || error.message}</h5>
              </div>
            )}

            <h5 style={{ display: 'block', fontSize: 17, margin: '1em auto', textAlign: 'center' }}>
              Enter your email to get a new confirmation code.
            </h5>

            <input
              type='text'
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '95%',
              }}
              className='form-control'
              name='companyName'
              placeholder='Enter your email here*'
              defaultValue=''
              maxLength='50'
              onChange={(e) => setUsername(e.target.value)}
              required
              autoFocus
            />

            <div>
              <Tooltip
                content='Same password you used for signup.'
                background='#333'
                color='white'
                forceDirection={true}
                direction='left'
                styles={{ position: 'absolute', right: '15px', zIndex: 10, marginTop: 3 }}
                tipContentClassName='register-tooltip-content'>
                <i className='fa fa-info-circle'></i>
              </Tooltip>

              <input
                type='password'
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '95%',
                }}
                className='form-control'
                name='companyName'
                placeholder='Enter your password here*'
                defaultValue=''
                maxLength='50'
                onChange={(e) => setnewPassword(e.target.value)}
                required
              />
            </div>

            {errorsToDisplay && (errorsToDisplay.password || errorsToDisplay.min || errorsToDisplay.matches) && (
              <div>
                {errorsToDisplay.password && (
                  <p
                    style={{
                      textAalign: 'left',
                      color: 'red',
                      fontSize: '11px',
                      fontWeight: 700,
                      marginTop: '-10px',
                      marginLeft: 10,
                    }}>
                    {errorsToDisplay.password}
                  </p>
                )}
                {errorsToDisplay.min && (
                  <p
                    style={{
                      textAalign: 'left',
                      color: 'red',
                      fontSize: '11px',
                      fontWeight: 700,
                      marginTop: '-10px',
                      marginLeft: 10,
                    }}>
                    {errorsToDisplay.min}
                  </p>
                )}
                {errorsToDisplay.matches && (
                  <p
                    style={{
                      textAalign: 'left',
                      color: 'red',
                      fontSize: '10px',
                      fontWeight: 700,
                      marginTop: '-10px',
                      marginLeft: 10,
                    }}>
                    {errorsToDisplay.matches}
                  </p>
                )}
              </div>
            )}

            <AuthBtn
              className='btn btn-lg btn-login btn-block onceonly'
              style={{ width: '83%', margin: 'auto', marginBottom: '1em' }}
              bgcolor
              type
              onClick={(e) => {
                e.preventDefault();
                resendCode();
              }}>
              {processing && <i className='fa fa-spin fa-spinner icon-spinner' />}
              Confirm
            </AuthBtn>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    branding: state.branding,
    //currentOrganisation: state.currentOrganisation,
    processing: state.authentication.processing,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    //getUserCredentials: () => dispatch(AUTHENTICATION_ACTIONS.getUserCredentials()),
    //newUser: (user, organisation) => dispatch(AUTHENTICATION_ACTIONS.newUser(user, organisation)),
    loggingIn: (saving) => dispatch(AUTHENTICATION_ACTIONS.loggingIn(saving)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendConfirmationCode);
