import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions';
import { Actions as PROFILEACTIONS } from '../../store/actions/profileActions';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { format } from 'date-fns';
import { AuthBtn } from '../../styledComponents/GlobalStyle';

const dateOfOrder = format(new Date(), 'dd MMMM yyyy HH:mm:ss');

const PromoterAgreement = ({
  setShowPromoterAgreement,
  confirmPromoterAgreement,
  isWhiteLabelAdmin,
  profile,
  org,
  consultantAgreement,
  showLoader,
}) => {
  const [confirmAgreement, setConfirmAgreement] = useState(false);
  const [loader, setShowLoader] = useState(false);

  return (
    <>
      <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
        <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
          <div className='modal-content'>
            <div className='modal-header'>
              <button className='close' onClick={() => setShowPromoterAgreement(false)}>
                x
              </button>
              <h4 className='modal-title'>Promoter Agreement</h4>
            </div>
            <div className='modal-body'>
              <div style={{ display: !consultantAgreement ? 'block' : 'none' }}>
                <h4>Agreement Not Found</h4>
              </div>
              <div style={{ textAlign: 'justify', color: 'black' }}>
                <div>
                  <ReactMarkdown
                    remarkPlugins={[gfm]}
                    className={'userAgreement-text'}
                    children={consultantAgreement?.text.split('/./')[0]}
                  />
                </div>
                <div style={{ display: consultantAgreement ? 'block' : 'none' }}>
                  <table style={{ border: '0.5px solid black', color: 'black' }}>
                    <thead>
                      <tr style={{ borderBottom: '0.5px solid black' }}>
                        <th style={{ padding: 15, borderRight: '1px solid black', width: 300 }}>
                          Compare Your Footprint Ltd By:{' '}
                        </th>
                        <th style={{ padding: 15, width: 300 }}> By: {org?.name}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ borderBottom: '0.5px solid black' }}>
                        <td style={{ height: 60, borderRight: '0.5px solid black', paddingLeft: 10, paddingTop: 60 }}>
                          <strong>Date and Time of Order: {dateOfOrder} </strong>
                        </td>
                        <td style={{ height: 60, borderRight: '0.5px solid black', paddingLeft: 10, paddingTop: 60 }}>
                          <strong>Date and Time of Order: {dateOfOrder}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: 20, borderRight: '1px solid black', paddingLeft: 10 }}>
                          Name: William Richardson, Founder and CEO
                        </td>
                        <td style={{ paddingLeft: 10 }}>
                          Name: {profile.firstName} {profile.lastName}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    display: consultantAgreement?.images ? 'flex' : 'none',
                    justifyContent: 'center',
                    margin: 10,
                    marginTop: 40,
                  }}>
                  <img src={consultantAgreement?.images[0]} alt='' style={{ width: '90%' }} />
                </div>
                <div style={{ marginTop: 30 }}>
                  <ReactMarkdown
                    remarkPlugins={[gfm]}
                    className={'userAgreement-text'}
                    children={consultantAgreement?.text.split('/./')[1]}
                  />
                </div>
              </div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={confirmAgreement} />}
                  onChange={(e) => setConfirmAgreement(e.target.checked)}
                  label={'By clicking you are accepting to our Promoter Agreement.'}
                />
              </FormGroup>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBotton: 20 }}>
                <AuthBtn
                  className='btn btn-lg btn-login btn-block onceonly'
                  disabled={!confirmAgreement || isWhiteLabelAdmin || !consultantAgreement || loader}
                  style={{ color: 'white', width: 'fit-content', fontSize: 16, padding: '10 10' }}
                  onClick={async (e) => {
                    try {
                      e.preventDefault();
                      setShowLoader(true);
                      await confirmPromoterAgreement();
                      setShowPromoterAgreement(false);
                      setShowLoader(false);
                    } catch (err) {
                      setShowLoader(false);
                      console.log('Error Confirming Promoter Agreement', err);
                    }
                  }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i className='fa fa-spin fa-spinner icon-spinner' style={{ display: showLoader ? 'block' : 'none' }} />
                    Confirm
                  </div>
                </AuthBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const org = state.organisation?.details;
  const profile = state.profile?.details;
  const isWhiteLabelAdmin = org?.whiteLabelId === org?.id;
  const consultantAgreement = state.licenses?.find(
    (x) => x.type === 'Consultant Agreement' && x.whiteLabelId === org?.whiteLabelId
  );

  return {
    branding: state.branding,
    isWhiteLabelAdmin,
    profile,
    org,
    consultantAgreement,
    showLoader: state.showLoader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
  confirmPromoterAgreement: () => dispatch(PROFILEACTIONS.confirmPromoterAgreement()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoterAgreement);
