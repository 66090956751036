import React from 'react';
import { connect } from 'react-redux';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import Breadcrumb from '../../Common/Breadcrumb';
import ContractsListTable from './ContractsListTable';
import Box from '@mui/material/Box';

const items = [{ label: 'Contracts', link: '/admin/sales/contracts' }];

const ContractsList = () => {
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Breadcrumb items={items} />
      </Box>

      <ContractsListTable />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractsList);
