import React from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import { AddButton } from '../../styledComponents/GlobalStyle';

const DisplayErrors = ({ errors, analysisType }) => {
  if (analysisType === 'recalculate') {
    // We return an excel file with the errors from the recalculate usage
    return (
      <>
        <p className='error-imports' style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'red' }}>
          There were some errors while recalculating the usage. <br />
          <a style={{ cursor: 'pointer' }} href={errors}>
            <i className='fa fa-file-excel-o' /> Download Excel
          </a>
        </p>
      </>
    );
  }

  return null;
};

const SuccessfulMessage = ({ analysisType, branding }) => {
  const { primaryColor } = branding.colors || {};
  if (analysisType === 'recalculate') {
    return (
      <>
        <p className='error-imports' style={{ fontSize: '1.2em', fontWeight: 'bold', color: primaryColor }}>
          All usage has been successfully recalculated!
        </p>
      </>
    );
  }

  return null;
};

const AnalysisModal = ({ showModal, errors, showAnalysisModal, analysisType, branding, processingStepFunctionStatus }) => {
  const { recalculateUsage } = showModal || {}; // Tells which anaylsis modal we should show

  if (recalculateUsage) {
    return (
      <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
        <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                className='close'
                onClick={() => {
                  processingStepFunctionStatus(false);
                  showAnalysisModal(false, 'recalculateUsage');
                }}>
                x
              </button>
              <h4 className='modal-title'>Analysis</h4>
            </div>
            <div className='modal-body'>
              <div className='form-group'>
                <div id='import-link'>
                  {errors?.length >= 1 ? (
                    <DisplayErrors errors={errors} analysisType={analysisType} />
                  ) : (
                    <SuccessfulMessage analysisType={analysisType} branding={branding} />
                  )}
                </div>
              </div>

              <div className='row block buttons' style={{ display: 'flex', justifyContent: 'center' }}>
                <AddButton
                  className='btn btn-success'
                  onClick={() => {
                    processingStepFunctionStatus(false);
                    showAnalysisModal(false, 'recalculateUsage');
                  }}>
                  Close
                </AddButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else return null;
};

const mapStateToProps = (state) => {
  const analysisType = state.processingImport?.status?.type;

  return {
    branding: state.branding,
    successful: state.processingImport?.status?.successful,
    errors: state.processingImport?.status?.errors,
    showModal: state?.showAnalysisModal,
    analysisType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showAnalysisModal: (status, action) => dispatch(ACTIONS.showAnalysisModal(status, action)),
  processingStepFunctionStatus: (status) => dispatch(ACTIONS.processingStepFunctionStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisModal);
