import { TextareaAutosize } from '@mui/base';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import { Actions as AUTHACTIONS } from '../../../store/actions/authenticationActions';
import { AddButton } from '../../../styledComponents/GlobalStyle';
import Breadcrumb from '../../Common/Breadcrumb';
import { adminPortalSelect } from '../../utils/ReactSelectStyles.js';
import ValidationMessage from '../../utils/ValidationMessage';

const Settings = ({
  settingsOpt,
  saveSettings,
  user,
  cyfAdmin,
  whiteLabelAdmin,
  consultantAdmin,
  TextArea,
  getAllItemsFromDB,
  options,
  wl,
  whiteLabels,
  hideFields,
  toTitleCase,
  editableFields,
  saveWLSettings,
  isValidUrl,
}) => {
  const [editedState, setEditedState] = useState({});
  const [error, setError] = useState([]);
  const [currentWl, setCurrentWl] = useState(wl?.whiteLabelId);
  const [wlSettings, setWlSettings] = useState(settingsOpt);

  function updateSettings(wl) {
    let settingOpt = {};
    Object.keys(wl)?.forEach((item) => {
      if (hideFields.includes(item)) return;
      settingOpt[item] = {
        type: 'text',
        title: toTitleCase(item),
        value: String(wl[item]) || String(editedState[item]),
        disable: whiteLabelAdmin ? true : editableFields.includes(item) ? false : true,
      };
    });
    setWlSettings(settingOpt);
  }

  useEffect(() => {
    getAllItemsFromDB('whiteLabelSettings');
  }, [getAllItemsFromDB]);

  useEffect(() => {
    updateSettings(wl);
    //eslint-disable-next-line
  }, [whiteLabels]);

  const updateField = (e) => {
    const { name, value } = e.target;
    const newEditedState = { ...editedState };

    newEditedState[name] = value;
    setEditedState(newEditedState);
  };

  /* const handleSave = async (settings) => {
    try {
      await saveSettings(settings);
    } catch (error) {
      if (error.inner) {
        // If here, it means there are validation errors
        setError(error.inner);
      } else console.log('Error', error);
    }
  }; */

  const saveWlSettings = async (settings) => {
    try {
      settings.whiteLabelId = currentWl;
      await saveWLSettings(settings);
    } catch (error) {
      if (error.inner) {
        setError(error.inner);
      } else {
        console.log('Error', error);
      }
    }
  };

  return (
    <>
      <Breadcrumb items={[{ label: 'Settings', link: '/admin/settingsPage' }]} />
      {!consultantAdmin && (
        <div className='row'>
          <div className='col-lg-12'>
            <section className='panel'>
              <aside className='profile-nav alt'>
                <section className='panel'>
                  <header
                    className='panel-heading'
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {whiteLabels ? whiteLabels?.find((wl) => wl.whiteLabelId === currentWl)?.name + ' Settings' : 'Settings'}
                    {cyfAdmin && (
                      <Select
                        name='whiteLabels'
                        options={options}
                        onChange={(selected) => {
                          setCurrentWl(selected?.value);
                          setEditedState({});
                          updateSettings(whiteLabels?.find((wl) => wl.whiteLabelId === selected.value));
                        }}
                        styles={adminPortalSelect}
                        menuPlacement='auto'
                        value={options.find((option) => option?.value === currentWl)}
                      />
                    )}
                  </header>
                  {wlSettings && (
                    <ul className='nav nav-pills nav-stacked'>
                      {_.sortBy(Object.keys(wlSettings), (o) => wlSettings[o].title)
                        .filter((o) => !wlSettings[o]?.exclusiveFor || wlSettings[o]?.exclusiveFor.includes(user))
                        .map((key, index) => {
                          const type = wlSettings[key].type;
                          //const styles = wlSettings[key].style;
                          const value = wlSettings[key].value;
                          return (
                            <li key={index}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  padding: '10px 15px',
                                }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                  {wlSettings[key].title}
                                  {wlSettings[key].tooltipText && (
                                    // In case options has some tooltip to show
                                    <Tooltip title={<p style={{ fontSize: 12 }}>{wlSettings[key].tooltipText}</p>}>
                                      <i className='fas fa-info-circle' style={{ marginLeft: 10 }} />
                                    </Tooltip>
                                  )}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                  {['text', 'email', 'password'].includes(type) &&
                                    TextArea({ wlSettings, key, editedState, value, updateField, type })}
                                  {isValidUrl(value) && (
                                    <div style={{ cursor: 'pointer' }} onClick={() => window.open(value)}>
                                      <i className='fa-solid fa-download' />
                                    </div>
                                  )}
                                </div>
                              </div>
                              {error.length > 0 && (
                                <ValidationMessage
                                  message={_.find(error, { path: key })?.message}
                                  style={{ fontSize: 12 }}
                                  wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}
                                />
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </section>
              </aside>
            </section>
            <div
              id='save-wl-settings'
              style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', margin: '20px 0px' }}>
              {cyfAdmin && (
                <>
                  <AddButton
                    onClick={async () => {
                      await saveWlSettings(editedState);
                      localStorage.removeItem('wlSettings');
                      localStorage.removeItem('wlSettingsTimestamp');
                      window.location.reload();
                    }}>
                    Save White Label Settings
                  </AddButton>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {/* <ListPaymentMethods /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  const TextAreaStyle = styled(TextareaAutosize)({
    minWidth: '30vw',
    width: '40vw',
    resize: 'none',
  });

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      '^(https:\\/\\/)' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  function TextArea({ wlSettings, key, editedState, value, updateField, type }) {
    return (
      <TextAreaStyle
        disabled={wlSettings?.[key]?.disable}
        className='form-control'
        name={key}
        value={editedState?.[key] === undefined ? value : editedState[key]}
        onChange={updateField}
        type={type}
        rows={4}
        maxRows={4}
        sx={!isValidUrl(value) ? { marginRight: '25px' } : { marginRight: '10px' }}
      />
    );
  }

  function toTitleCase(name) {
    // Split the string at each capital letter
    let formattedString = name.replace(/([A-Z])/g, ' $1');
    // Capitalize the first letter and remove leading space
    formattedString = formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
    if (formattedString.includes('U R L')) {
      formattedString = formattedString.replace('U R L', 'URL');
    }
    return formattedString;
  }

  const editableFields = [
    'activityDataInventoryFile',
    'carbonReportLogo',
    'dataHelperTool',
    'favicon',
    'industriesAndFacilitiesTypesGuide',
    'logo',
    'logoEmail',
    'methodology',
    'tutorialURL',
    'usageDataGuide',
    'scienceBasedTarget',
    'faq',
    'facilitiesTutorial',
    'importTemplatesTutorial',
    'organisationSetUpTutorial',
    'reportsTutorial',
    'usageDataTutorial',
  ];

  const hideFields = [
    'annualPriceOptions',
    'annualStripePriceId',
    'brandingColors',
    'font',
    'enableConsultants',
    'hasPaymentSystem',
    'inviteUsers',
    'showConsultantClients',
    'stripeProductId',
    'stripeAccountId',
    'stripePercentageFee',
    'unlimitedStripePriceId',
    'UnlimitedPriceOptions',
    'whiteLabelId',
  ];

  const settingsOpt = {};
  const wl = state.whiteLabelAdmin;
  const { cyfAdmin, whiteLabelAdmin, consultantAdmin } = state.profile.details || {};
  const user = cyfAdmin ? 'CYF' : whiteLabelAdmin ? 'White Label' : consultantAdmin ? 'Consultant' : null;

  Object.keys(wl)?.forEach((item) => {
    if (hideFields.includes(item)) return null;
    settingsOpt[item] = {
      type: 'text',
      title: toTitleCase(item),
      value: String(wl[item]) || '',
      disable: whiteLabelAdmin ? true : editableFields.includes(item) ? false : true,
    };
  });

  const whiteLabels = state.admin?.adminPortal?.whiteLabelSettings;
  const options = _.sortBy(whiteLabels?.map((wl) => ({ value: wl.whiteLabelId, label: wl.name })) || [], ['label']);
  const branding = state.branding;

  return {
    settingsOpt,
    cyfAdmin,
    whiteLabelAdmin,
    consultantAdmin,
    user,
    TextArea,
    options,
    wl,
    hideFields,
    editableFields,
    toTitleCase,
    whiteLabels,
    branding,
    isValidUrl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveContract: (contract, history) => dispatch(ADMINACTIONS.saveContract(contract, history)),
  saveWLSettings: (settings) => dispatch(ADMINACTIONS.saveWLSettings(settings)),
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  getWLSettings: () => dispatch(AUTHACTIONS.getWLSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
