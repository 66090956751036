import React from 'react';
import ReactMarkdown from 'react-markdown';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import Select, { components } from 'react-select';
import { RemoveTags } from '../utils/GlobalFunctions';

const Control = ({ children, ...props }) => {
  const { value } = props.selectProps;
  const style = { cursor: 'pointer', display: value?.description ? 'block' : 'none' };

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  return (
    <components.Control {...props}>
      <span style={style} ref={setTriggerRef}>
        <i className='fa fa-question-circle' />
      </span>
      {value && value.description !== null && value.description !== undefined && visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container',
            style: { marginLeft: 10, marginTop: 10, backgroundColor: '#63AF39', borderRadius: 4 },
          })}>
          <div {...getArrowProps({ className: 'tooltip-arrow', style: { color: '#63AF39', left: '-15px', top: '-3px' } })} />
          <div className='tooltip-body' style={{ color: 'white', textAlign: 'justify' }}>
            <ReactMarkdown children={RemoveTags(value.description)} />
          </div>
        </div>
      )}
      {children}
    </components.Control>
  );
};

const CustomSelectProps = ({
  zIndex,
  options,
  name,
  onChange,
  components,
  value,
  editUsageModal,
  item,
  maxMenuHeight,
  ...props
}) => {
  const styles = {
    control: (css) => ({ ...css, paddingLeft: '1rem' }),
    container: (x) => ({ ...x, zIndex: zIndex ? zIndex : 10 }),
  };

  if (!item?.[name] && options.length === 1 && name !== 'subentity') {
    // Automatically select the only option if there is only one option and the value is null
    if (editUsageModal ){
      // TODO: Make this better
      editUsageModal(options[0].value, { name: name });
    }
  }

  return (
    <Select
      {...props}
      maxMenuHeight={maxMenuHeight || 250}
      components={{ Control }}
      isSearchable
      name={name}
      options={options}
      styles={styles}
      value={value}
      onChange={onChange}
    />
  );
};

export default CustomSelectProps;
