import React, { useRef } from 'react';
import { connect } from 'react-redux';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

/* const colors = [
  '#1f9b38',
  '#111111',
  '#FFE711',
  '#FF9300',

  // "#eafbed",
  // "#f2f2f2",
  // "#fffce5",
  // "#fff4e5",

  '#26bf45',
  '#737373',
  '#e6ce00',
  '#e68400',

  // "#bff2ca",
  // "#d9d9d9",
  // "#fff7b3",
  // "#ffdfb3",

  '#1e9536',
  '#595959',
  '#b3a000',
  '#b36700',

  // "#95eaa6",
  // "#bfbfbf",
  // "#fff280",
  // "#ffc980",

  '#156a26',
  '#404040',
  '#807300',
  '#804a00',

  '#6ae182',
  '#a6a6a6',
  '#ffed4d',
  '#ffb34d',

  '#0d4017',
  '#262626',
  '#4d4500',
  '#4d2c00',

  '#40d95f',
  '#8c8c8c',
  '#ffe81a',
  '#ff9e1a',
]; */

const TotalGreenhouseEmissionsByScopeChart = ({ chartOptions, brandingColors, hasChartData }) => {
  const ref = useRef(null);

  if (!chartOptions || !hasChartData) {
    return null;
  }

  return <HighchartsReact ref={ref} highcharts={Highcharts} options={chartOptions} />;
};

const mapStateToProps = (state, ownProps) => {
  // console.log('consumptionData',consumptionData)

  const year = state.report.details.year;
  const brandingColors = state.branding?.colors || {};

  const { totalEmissionsByScope, withMarketFactors, filter } = state.consumption;

  //const { subentities, entities, facility, metric } = state;

  const data = {};
  const isMarket = withMarketFactors && !filter.includes('market');

  const years = state.consumption.years.sort().reverse();

  const { chart1, chart2 } = brandingColors;

  years.forEach((year, i) => {
    data[year] = [
      {
        name: 'Scope 1',
        y:
          isMarket && totalEmissionsByScope[year][1] && !isNaN(totalEmissionsByScope[year][1].co2e_market)
            ? parseFloat(totalEmissionsByScope[year][1].co2e_market.toFixed(2)) || 0
            : (totalEmissionsByScope[year][1] &&
                totalEmissionsByScope[year][1].co2e &&
                parseFloat(totalEmissionsByScope[year][1].co2e.toFixed(2))) ||
              0,
      },
      {
        name: 'Scope 2',
        y:
          isMarket && totalEmissionsByScope[year][2] && typeof totalEmissionsByScope[year][2].co2e_market === 'number'
            ? parseFloat(totalEmissionsByScope[year][2].co2e_market.toFixed(2)) || 0
            : (totalEmissionsByScope[year][2] &&
                totalEmissionsByScope[year][2].co2e &&
                parseFloat(totalEmissionsByScope[year][2].co2e.toFixed(2))) ||
              0,
      },
      {
        name: 'Scope 3',
        y:
          isMarket && totalEmissionsByScope[year][3] && typeof totalEmissionsByScope[year][3].co2e_market === 'number'
            ? parseFloat(totalEmissionsByScope[year][3].co2e_market.toFixed(2)) || 0
            : (totalEmissionsByScope[year][3] &&
                totalEmissionsByScope[year][3].co2e &&
                parseFloat(totalEmissionsByScope[year][3].co2e.toFixed(2))) ||
              0,
      },
    ];
  });

  // const chartData = []
  //console.log('data',data)

  const hasChartData = data && data[year] && data[year].length > 0;

  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'column',
    },
    title: {
      text: `Total Greenhouse Gas Emissions by Scope`,
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} tCO2e</b><br/>',
    },
    yAxis: [
      {
        title: {
          text: 'tCO2e',
        },
        showFirstLabel: false,
      },
    ],

    xAxis: {
      type: 'category',
      max: 2,
      labels: {
        useHTML: true,
        animate: true,
      },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      x: 0,
      y: 100,
    },
    series: [
      {
        color: chart1,
        name: year,
        id: 'main',
        dataLabels: [
          {
            enabled: false,
            inside: false,
            style: {
              fontSize: '16px',
            },
          },
        ],
        data: data[year],
        pointWidth: 50,
        showInLegend: false,
      },
      {
        color: chart2,
        pointPlacement: 0.1,
        linkedTo: 'main',
        data: data[year - 1],
        name: year - 1,
        pointWidth: 50,
        showInLegend: false,
      },
    ],
  };

  return {
    chartOptions,
    brandingColors,
    hasChartData,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalGreenhouseEmissionsByScopeChart);
