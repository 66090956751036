import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AddButton } from '../../styledComponents/GlobalStyle';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { Box, LinearProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

const ConfirmationModal = ({ showConfirmModal, setShowConfirmModal, showUsageDataModal, branding, progressState, edit }) => {
  const { primaryColor } = branding.colors;

  const [progressBarActive, setProgressBarActive] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progressState !== undefined) {
      setProgress(progressState);
      setProgressBarActive(true);
    } else {
      setProgressBarActive(false);
      setShowConfirmModal(false);
    }
  }, [progressState, setShowConfirmModal]);

  if (showConfirmModal) {
    return (
      <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
        <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
          <div id='usage-confirmation-modal' className='modal-content'>
            <div className='modal-header'>
              <button
                onClick={() => {
                  showUsageDataModal(false, null);
                  setShowConfirmModal(false);
                }}
                type='button'
                className='close'>
                x
              </button>
              <h4 className='modal-title'>Usage Data</h4>
            </div>
            <div className='modal-body'>
              <div className='form-group'>
                {/* <h5 style={{ lineHeight: '25px', fontSize: '1.5em' }}>Your data has been successfully added!</h5> */}
                {/* <h5 style={{ lineHeight: '25px', fontSize: '1.5em' }}></h5> */}
              </div>

              {!progressBarActive && (
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div>
                    <AddButton
                      className='btn btn-success'
                      onClick={() => {
                        showUsageDataModal(true, null);
                        setShowConfirmModal(false);
                      }}>
                      Add more Data
                    </AddButton>
                  </div>
                  <div style={{ marginLeft: 30 }}>
                    <AddButton
                      className='btn btn-success'
                      onClick={() => {
                        showUsageDataModal(false, null);
                        setShowConfirmModal(false);
                      }}>
                      Exit
                    </AddButton>
                  </div>
                  <div className='clearfix'></div>
                </div>
              )}

              {progressBarActive && (
                <div className='modal-body' style={{ marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
                  <Box sx={{ width: '100%' }}>
                    <LinearProgressWithLabel
                      sx={{ height: 7, borderRadius: 5 }}
                      value={progress}
                      primarycolor={primaryColor}
                    />
                    {progress === 100 && (
                      <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <p>Usage Data has been {`${edit ? 'edited' : 'added'}`} Successfully!</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <div>
                            <AddButton
                              className='btn btn-success'
                              onClick={() => {
                                showUsageDataModal(true, null);
                                setShowConfirmModal(false);
                              }}>
                              Add more Data
                            </AddButton>
                          </div>
                          <div style={{ marginLeft: 30 }}>
                            <AddButton
                              className='btn btn-success'
                              onClick={() => {
                                showUsageDataModal(false, null);
                                setShowConfirmModal(false);
                              }}>
                              Exit
                            </AddButton>
                          </div>
                          <div className='clearfix'></div>
                        </div>
                      </>
                    )}
                  </Box>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', height: '10vh' }}>
        <Box sx={{ width: '90%', mr: 4, marginLeft: '5vh', color: props.primarycolor }}>
          <LinearProgress color='inherit' variant='determinate' {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant='body2' fontSize='2vh' color='text.secondary'>{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  return { branding: state?.branding, progressState: state?.progress, edit: state?.usageDataModal?.item?.id };
};

const mapDispatchToProps = (dispatch) => ({
  showUsageDataModal: (status, item) => dispatch(USAGEACTIONS.showUsageDataModal(status, item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
