import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import _, { isArray } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import { AddButton, AdminPortalBulkChange } from '../../../styledComponents/GlobalStyle';
import { adminPortalSelect } from '../../utils/ReactSelectStyles';

const ConversionFactorsTable = ({
  branding,
  page,
  filterSize,
  tableSize,
  setTotalItems,
  setShowHide,
  setFilter,
  filter,
  totalItems,
  options,
  setShowPagination,
  canEditAdminPortal,
  toggleModal,
  profiles,
  getAllItemsFromDB,
  showLoader,
  conversionFactors,
  updateCheckedItems,
  handleTableSizeChange,
  toggleSelectAllChecked,
}) => {
  const get_OS_terms = () => ({ filters: filter, page, tableSize: tableSize.value });

  const { headers } = branding?.fonts[0]?.font || {};
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const checkedList = conversionFactors?.filter((item) => item.checked);

  const selectAll = () => {
    toggleSelectAllChecked(!selectAllChecked);
    setSelectAllChecked(!selectAllChecked);
  };

  useEffect(() => {
    if (checkedList.length === 0) {
      setSelectAllChecked(false);
    }
  }, [checkedList.length]);

  useEffect(() => {
    getAllItemsFromDB('profile');
  }, [getAllItemsFromDB]);

  useEffect(() => {
    setTotalItems(totalItems);
  }, [totalItems, setTotalItems]);

  if (showLoader) return <LinearProgress color='inherit' />;

  if (conversionFactors && !isArray(conversionFactors)) {
    //Error Message
    return <div>{conversionFactors}</div>;
  }

  const _headerRenderer = () => {
    return <div>{conversionFactors.length > 1 && <Checkbox onChange={selectAll} checked={selectAllChecked} />}</div>;
  };

  const bulkRenderer = ({ cellData, rowData }) => {
    return (
      <Checkbox
        checked={rowData?.checked}
        //cellData here is cellID
        onChange={() => updateCheckedItems(cellData)}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    );
  };

  const formatUsageTypesValues = ({ cellData, rowData, dataKey }) => {
    if (
      [
        'subentity',
        'vehicle',
        'production',
        'metric',
        'waste',
        'seat',
        'load',
        'fuel',
        'price',
        'finallocation',
        'originlocation',
        'factorprovider',
      ].includes(dataKey) &&
      cellData !== null &&
      cellData !== undefined &&
      cellData !== 'null'
    ) {
      return (
        <div>
          <Tooltip
            sx={{ fontSize: 20 }}
            title={
              <p style={{ fontSize: 12, marginBottom: 0 }}>
                {cellData && _.find(options?.[dataKey], { value: cellData })?.label}{' '}
              </p>
            }>
            <div
              style={{ cursor: 'pointer', whiteSpace: 'pre-wrap', overflow: 'hidden', height: 'inherit' }}
              className='admin_portal_cellData_description'>
              {cellData && _.find(options?.[dataKey], { value: cellData })?.label}
            </div>
          </Tooltip>
        </div>
      );
    } else {
      if (cellData === null || cellData === 'null' || cellData === undefined) {
        return (
          <div>
            <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{'<null>'}</p>}>
              <div style={{ textAlign: 'center', overflowX: 'auto', maxHeight: '100px', opacity: '40%' }}>{'<null>'}</div>
            </Tooltip>
          </div>
        );
      }
    }

    if (dataKey === 'addOns') {
      return (
        <div>
          <Tooltip
            sx={{ fontSize: 20 }}
            title={
              <p style={{ fontSize: 12, marginBottom: 0 }}>
                {cellData?.map((item) => _.find(options?.[dataKey], { value: item })?.label + ' ')}
              </p>
            }>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {cellData && cellData?.map((item) => _.find(options?.[dataKey], { value: item })?.label + ' ')}
            </div>
          </Tooltip>
        </div>
      );
    }

    if (dataKey === 'creationDate' || dataKey === 'modificationDate' || dataKey === 'discontinuedDate')
      return (
        <div>
          <Tooltip
            sx={{ fontSize: 20 }}
            title={<p style={{ fontSize: 12, marginBottom: 0 }}>{cellData && moment(cellData).format('DD/MM/YYYY')}</p>}>
            <div
              style={{ cursor: 'pointer', whiteSpace: 'pre-wrap', overflow: 'hidden', height: 'inherit' }}
              className='admin_portal_cellData_description'>
              {cellData && moment(cellData).format('DD/MM/YYYY')}
            </div>
          </Tooltip>
        </div>
      );

    if (dataKey === 'userId') {
      const user = profiles?.find((user) => user.id === rowData.userId);
      const userName = user ? user?.firstName + ' ' + user?.lastName : '';
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{cellData}</p>}>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {userName}
            </div>
          </Tooltip>
        </div>
      );
    }

    if (dataKey)
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{cellData}</p>}>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {cellData}
            </div>
          </Tooltip>
        </div>
      );
  };

  const userActions = ({ rowData }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>Edit Conversion Factor</p>}>
          <EditIcon sx={{ cursor: 'pointer' }} onClick={() => toggleModal(true, 'conversionFactors-table-edit', rowData)} />
        </Tooltip>
        <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>Delete Conversion Factor</p>}>
          <DeleteIcon
            sx={{ marginLeft: '1vh', cursor: 'pointer' }}
            onClick={() => toggleModal(true, 'conversionFactors-delete', rowData)}
          />
        </Tooltip>
      </div>
    );
  };

  if (conversionFactors && !conversionFactors.length > 0) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className='alert-message'>
          <i className='fa-solid fa-triangle-exclamation' />
          <span>No Conversion Factor Found</span>
        </div>
        <AddButton
          id='reset-btn'
          className='btn btn-success'
          onClick={() => {
            setFilter({});
            setTotalItems(0);
            setShowPagination(false);
            setShowHide(false);
          }}
          bordercolor='true'
          style={{ width: '10vh', height: 'fit-content', fontSize: 16, marginLeft: '2vh' }}>
          Reset
        </AddButton>
      </div>
    );
  }

  return (
    <section className='panel' style={{ fontFamily: headers }}>
      <header
        className='panel-heading'
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        Conversion Factors Results
        <div style={{ marginRight: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Select
            options={filterSize}
            name={'tableSize'}
            defaultValue={filterSize[0]}
            onChange={handleTableSizeChange}
            styles={{ ...adminPortalSelect, control: (x) => ({ ...x, ...adminPortalSelect.control, width: 100 }) }}
            placeholder={'Table Size'}
            value={tableSize}
          />
          <AddButton
            id='reset-btn'
            className='btn btn-success'
            onClick={() => {
              setFilter({});
              setTotalItems(0);
              setShowPagination(false);
              setShowHide(false);
            }}
            bordercolor='true'
            style={{ width: '10vh', fontSize: 16, marginLeft: '3vh', textTransform: 'none' }}>
            Reset
          </AddButton>
          {checkedList?.length > 1 && (
            <>
              <div style={{ marginRight: '1.5vh' }}>
                <AdminPortalBulkChange
                  style={{ width: '12vh', fontSize: 16, marginLeft: '3vh', textTransform: 'none' }}
                  to={'/admin/conversionfactors'}
                  className='btn'
                  onClick={() => {
                    toggleModal(true, 'conversionFactors-bulk_edit', checkedList, null, get_OS_terms());
                  }}>
                  Bulk Edit
                </AdminPortalBulkChange>
              </div>
              <div>
                <AdminPortalBulkChange
                  style={{ width: '15vh', fontSize: 16, textTransform: 'none' }}
                  to={'/admin/conversionfactors'}
                  className='btn'
                  onClick={() => {
                    toggleModal(true, 'conversionFactors-bulk_delete', checkedList, null, get_OS_terms());
                  }}>
                  Bulk Delete
                </AdminPortalBulkChange>
              </div>
            </>
          )}
        </div>
      </header>
      <div className='panel-body' style={{ height: '100vh', overflowX: 'auto' }}>
        <div style={{ width: '100%', height: '100%', display: !conversionFactors?.length > 0 ? 'none' : 'block' }}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width + 1000}
                height={height}
                rowHeight={100}
                headerHeight={50}
                headerStyle={{ verticalAlign: 'bottom', width: '100%', display: 'flex', justifyContent: 'center' }}
                rowStyle={({ index }) => {
                  const rowData = conversionFactors[index];
                  const shouldHighlight = rowData && rowData.checked;
                  return {
                    backgroundColor: shouldHighlight ? 'lightblue' : 'white',
                    textAlign: 'center',
                    textOverflow: 'auto-flow',
                    fontSize: 13,
                  };
                }}
                headerClassName='admin_portal_column_headers'
                rowCount={conversionFactors.length}
                rowGetter={({ index }) => conversionFactors[index]}>
                {canEditAdminPortal && (
                  <Column dataKey='id' width={60} headerRenderer={_headerRenderer} cellRenderer={bulkRenderer} />
                )}
                {canEditAdminPortal && <Column label='Actions' dataKey='id' width={75} cellRenderer={userActions} />}
                <Column label='Id' dataKey='id' width={75} cellRenderer={formatUsageTypesValues} />
                <Column width={50} id='year-column' label='Year' dataKey='year' cellRenderer={formatUsageTypesValues} />
                <Column
                  width={150}
                  id='subentity-column'
                  label='Subentity'
                  dataKey='subentity'
                  cellRenderer={formatUsageTypesValues}
                />
                <Column width={85} id='seat-column' label='Seat' dataKey='seat' cellRenderer={formatUsageTypesValues} />
                <Column
                  width={85}
                  id='vehicle-column'
                  label='Vehicle'
                  dataKey='vehicle'
                  cellRenderer={formatUsageTypesValues}
                />
                <Column width={85} id='fuel-column' label='Fuel' dataKey='fuel' cellRenderer={formatUsageTypesValues} />
                <Column
                  width={90}
                  id='production-column'
                  label='Production'
                  dataKey='production'
                  cellRenderer={formatUsageTypesValues}
                />
                <Column width={85} id='load-column' label='Load' dataKey='load' cellRenderer={formatUsageTypesValues} />
                <Column width={85} id='waste-column' label='Waste' dataKey='waste' cellRenderer={formatUsageTypesValues} />
                <Column
                  width={85}
                  id='waste-column'
                  label='Price Type'
                  dataKey='price'
                  cellRenderer={formatUsageTypesValues}
                />
                <Column
                  width={100}
                  id='waste-column'
                  label='Origin Location'
                  dataKey='originlocation'
                  cellRenderer={formatUsageTypesValues}
                />
                <Column
                  width={100}
                  id='waste-column'
                  label='Final Location'
                  dataKey='finallocation'
                  cellRenderer={formatUsageTypesValues}
                />
                <Column
                  width={100}
                  id='factorprovider-column'
                  label='Factor Provider'
                  dataKey='factorprovider'
                  cellRenderer={formatUsageTypesValues}
                />
                <Column
                  width={85}
                  id='metric-column'
                  label='Metric'
                  dataKey='metric'
                  cellRenderer={formatUsageTypesValues}
                />
                <Column width={85} label='CO2e' dataKey='co2e' cellRenderer={formatUsageTypesValues} />
                <Column width={85} label='CO2' dataKey='co2' cellRenderer={formatUsageTypesValues} />
                <Column width={85} label='CH4' dataKey='ch4' cellRenderer={formatUsageTypesValues} />
                <Column width={85} label='WTT' dataKey='wtt' cellRenderer={formatUsageTypesValues} />
                <Column width={85} label='N2O' dataKey='n2o' cellRenderer={formatUsageTypesValues} />
                <Column
                  width={200}
                  label='Outside of Scopes'
                  dataKey='outsideOfScopes'
                  cellRenderer={formatUsageTypesValues}
                />
                <Column width={100} label='Scope' dataKey='scope' cellRenderer={formatUsageTypesValues} />
                <Column width={300} label='Source' dataKey='source' cellRenderer={formatUsageTypesValues} />
                <Column width={200} label='Add-Ons' dataKey='addOns' cellRenderer={formatUsageTypesValues} />
                <Column
                  width={200}
                  label='Discontinued Date'
                  dataKey='discontinuedDate'
                  cellRenderer={formatUsageTypesValues}
                />
                <Column width={150} label='Creation Date' dataKey='creationDate' cellRenderer={formatUsageTypesValues} />
                <Column
                  width={200}
                  label='Modification Date'
                  dataKey='modificationDate'
                  cellRenderer={formatUsageTypesValues}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { page, setTableSize, tableSize, handleTableSizeChange } = ownProps;
  const tableName = 'conversionFactors';

  return {
    showLoader: state?.showLoader,
    branding: state.branding,
    totalItems: state?.admin?.termsItems?.totalCount,
    profiles: state?.admin?.adminPortal?.profile,
    tableName,
    filterSize: state.admin?.filterSize,
    conversionFactors: state?.admin?.termsItems?.items || [],
    page,
    tableSize,
    setTableSize,
    handleTableSizeChange,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, action, item, notAutoClose, openSearchTerms) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose, openSearchTerms)),
  filterFromOpeanSearch: (index, query) => dispatch(ADMINACTIONS.filterFromOpeanSearch(index, query)),
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  updateCheckedItems: (itemId) => dispatch(ADMINACTIONS.updateCheckedItems(itemId)),
  toggleSelectAllChecked: (checked) => dispatch(ADMINACTIONS.toggleSelectAllChecked(checked)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversionFactorsTable);
