import React, { useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const colors = [
  '#1f9b38',
  '#111111',
  '#FFE711',
  '#FF9300',

  // "#eafbed",
  // "#f2f2f2",
  // "#fffce5",
  // "#fff4e5",

  '#26bf45',
  '#737373',
  '#e6ce00',
  '#e68400',

  // "#bff2ca",
  // "#d9d9d9",
  // "#fff7b3",
  // "#ffdfb3",

  '#1e9536',
  '#595959',
  '#b3a000',
  '#b36700',

  // "#95eaa6",
  // "#bfbfbf",
  // "#fff280",
  // "#ffc980",

  '#156a26',
  '#404040',
  '#807300',
  '#804a00',

  '#6ae182',
  '#a6a6a6',
  '#ffed4d',
  '#ffb34d',

  '#0d4017',
  '#262626',
  '#4d4500',
  '#4d2c00',

  '#40d95f',
  '#8c8c8c',
  '#ffe81a',
  '#ff9e1a',
];

const EmissionsBySourceChart = ({ chartOptions }) => {
  const ref = useRef(null);

  if (!chartOptions || !chartOptions?.series?.length) return null;

  return <HighchartsReact ref={ref} highcharts={Highcharts} options={chartOptions} />;
};

const mapStateToProps = (state) => {
  const { co2eByEntity, co2eMarketByEntity, withMarketFactors, filter } = state.consumption;

  const { entities } = state;

  const entityIDs = (co2eByEntity && Object.keys(co2eByEntity)) || [];

  const isMarket = withMarketFactors && !filter.includes('market');

  const chartData = entityIDs.map((entityID) => {
    const sliceData = isMarket ? co2eMarketByEntity[entityID] : co2eByEntity[entityID];
    const entityDetails = entities && entities.find(({ id }) => id === entityID);

    return {
      name: entityDetails && entityDetails.name,
      y: sliceData && sliceData,
      co2e: sliceData && sliceData,
      co2eMarket: co2eMarketByEntity && co2eMarketByEntity[entityID],
    };
  });

  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: `Emissions by Source`,
    },
    tooltip: {
      formatter() {
        return `<b>${this.key}</b><br/>CO2e: ${
          isMarket ? this.point.percentage.toFixed(2) : this.point.percentage.toFixed(2)
        } %<br/>`;
      },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
        showInLegend: true,
        colors,
      },
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      x: 0,
      y: 100,
    },
    series: [
      {
        name: 'CO2e',
        colorByPoint: true,
        data: _.orderBy(chartData, [isMarket ? 'co2eMarket' : 'co2e', 'name'], ['desc', 'asc']),
      },
    ],
  };

  return { chartOptions };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsBySourceChart);
