import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import AccountCodes from './AccountCodes';
import ImportFromXero from './ImportFromXero';
import Method from './Method';
import SaveSettings from './SaveSettings';
import Tenants from './Tenants';
import TrackingOptions from './TrackingOptions';

import { useHistory, useParams } from 'react-router';
import { Actions as ADMIN_ACTIONS } from '../../store/actions/adminActions';
import './styles.css';

const XeroFaq = ({ branding }) => {
  const [show, setShow] = useState(false);

  const iconStyle = {
    fontSize: '20px',
    marginLeft: '10px',
    verticalAlign: 'middle',
  };

  if (!show) {
    return (
      <button
        href='#'
        style={{ border: 0, background: 'inherit' }}
        onClick={(e) => {
          e.preventDefault();
          setShow(true);
        }}>
        <i style={iconStyle} className='fa fa-question-circle'></i>
      </button>
    );
  }

  const closeStyle = {
    fontSize: '20px',
    position: 'absolute',
    top: '17px',
    cursor: 'pointer',
  };

  return (
    <div
      style={{
        padding: '40px 20px',
        background: 'white',
        position: 'relative',
        borderRadius: '5px',
      }}>
      <i
        className='fa fa-close'
        style={closeStyle}
        onClick={(e) => {
          e.preventDefault();
          setShow(false);
        }}></i>
      <p>
        Compare Your Footprint is now plugged in to Xero, the online accounting software, to make it even easier to report on
        your energy usage and carbon footprint.
      </p>
      <p>
        If you already use Xero, you can easily link your accounts to Compare Your Footprint and our APP does the rest! It's
        then integrated directly with your Xero accounts, so your footprint updates every time your accounting is updated!{' '}
      </p>

      <h4>To connect to Xero follow these steps:</h4>

      <ol>
        <li>login and go to Organisations and click view/edit.</li>
        <li>Scroll down and click on Connect to Xero.</li>
        <li>
          <a className='link' target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/watch?v=tDk08xlszl4'>
            Watch this video tutorial to help you to connect your Xero account with Compare Your Footprint.
          </a>
        </li>
      </ol>

      <p>
        Any questions? Contact{' '}
        <a className='link' href={`mailto:${branding.supportEmail}`}>
          {branding.supportEmail}
        </a>
      </p>

      <h4>Is it secure?</h4>

      <p>
        Xero is using an industry-standard top-grade solution in terms of security. This is known as oAuth 2.0. The
        authentication process is coming from Xero's side and depending on how your account is set up, and the last time you
        connected, they may or may not ask for a password and dual authentication.
      </p>

      <p>
        Compare Your Footprint obtains two tokens from the initial request, one which is valid for 30 minutes and a second
        one which is by default valid for 30 days. Every time the 30-minute token expires, we renew this using the 30-day
        token, which is at that point extended for 30 days. For this token to work, requests must be originating from within
        Compare Your Footprint with parameters that are provided by Xero. This is done so that no token is used continuously
        for a period of time increasing inherent security. Xero's own accounting app runs on the very same security
        technology behind the hood.
      </p>

      <p>Xero allows you to disconnect Compare Your Footprint at any point in time from within Xero's portal. </p>

      <p>
        All data in between Xero and Compare Your Footprint is always passed through encrypted channels and is stored within
        a database which is encrypted at rest. So, your tokens’ short or long life are not exposed nor is the data being
        transacted.{' '}
      </p>

      <p>
        Furthermore, should any breach ever be discovered in Compare Your Footprint that could potentially compromise your
        data, we are able to immediately invalid our API keys and tokens to render any remaining tokens invalid. However, if
        it is a matter of your account details being compromised, no financial data is ever exposed, as this is not even
        available to the Compare Your Footprint team to view within the backend.
      </p>

      <h4>Data Protection</h4>

      <p>
        Compare Your Footprint Limited is committed to protecting the privacy of the users of our APP. We are the sole owner
        of the information collected via the APP. We will not sell, share, or rent this information to others in ways
        different from those disclosed in this statement.
      </p>

      <p>
        Please take time to read our <a href='https://compareyourfootprint.com/privacy-policy/'>Privacy Policy</a>.
      </p>
    </div>
  );
};

const XeroApp = ({
  isConnectedToXero,
  getOrganisationSettings,
  connected,
  xeroLoginUrl,
  xeroCallback,
  method,
  branding,
  organisationId,
  sageConnected,
  currentTenant,
  showLoader,
  toggleModal,
  tenants,
  tenant,
  getSpendEntities,
}) => {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if ((window.location.pathname === '/xero/callback' && !connected) || window.location.href.includes('?test=true')) {
      xeroCallback(history);
    } else {
      isConnectedToXero();
    }
  }, [id, isConnectedToXero, xeroCallback, history, connected]);

  useEffect(() => {
    if (connected) {
      getOrganisationSettings();
      getSpendEntities();
    }
  }, [connected, getOrganisationSettings, getSpendEntities]);

  return (
    <>
      <div>
        {!sageConnected && (
          <div className='App'>
            {connected && (
              <div>
                <section className='panel'>
                  <aside className='profile-nav alt'>
                    <section className='panel'>
                      <header className='panel-heading'>
                        Configure your Xero Integration for <u id='configureXero'>{currentTenant?.tenantName}</u>{' '}
                      </header>
                      <ul className='nav nav-pills nav-stacked'>
                        <Tenants />
                        <Method />
                        {method === 'accountcodes' && <AccountCodes />}
                        {method === 'trackingcategories' && <TrackingOptions />}
                      </ul>
                    </section>
                  </aside>
                </section>
                <SaveSettings />
                <ImportFromXero />
              </div>
            )}

            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!connected ? (
                <a
                  className='App-link btn xero-btn'
                  id='connectXero'
                  href={String(xeroLoginUrl)}
                  onClick={() => localStorage.setItem('organisation', organisationId)}>
                  <img
                    src={process.env.PUBLIC_URL + '/images/xero_circle.png'}
                    alt='Xero logo'
                    style={{ width: 40, height: 40 }}
                  />
                  Connect to Xero
                </a>
              ) : (
                <button
                  id='disconnectXero'
                  className='App-link btn xero-btn'
                  style={{ margin: 10 }}
                  disabled={showLoader}
                  onClick={() => {
                    toggleModal(true, 'disconnect-xeroOrg', { selectOrg: tenants?.length > 1, tenants, tenant });
                  }}>
                  <img
                    src={process.env.PUBLIC_URL + '/images/xero_circle.png'}
                    alt='Xero logo'
                    style={{ width: 40, height: 40 }}
                  />
                  Disconnect from Xero
                </button>
              )}

              {connected && (
                <a
                  id='connectAnotherOrgBtn'
                  className='App-link btn btn-success integration-btn'
                  href={xeroLoginUrl}
                  rel='noopener noreferrer'
                  style={{ margin: 10 }}
                  disabled={showLoader}>
                  Connect another organisation
                </a>
              )}

              <XeroFaq branding={branding} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const currentTenant = state?.tenants?.find((item) => item.tenantId === state.tenant);
  return {
    connected: state.connected,
    tenants: state.tenants,
    currentTenant,
    xeroLoginUrl: state.xeroLoginUrl,
    session: state.session,
    method: state.method,
    branding: state.branding,
    organisationId: state?.organisation?.details?.id,
    sageConnected: state.sageConnected,
    showLoader: state?.showLoader,
    tenant: state?.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  isConnectedToXero: () => dispatch(ACTIONS.isConnectedToXero()),
  xeroCallback: (history) => dispatch(ACTIONS.xeroCallback(history)),
  getOrganisationSettings: () => dispatch(ACTIONS.getOrganisationSettings()),
  setSession: (session) => dispatch(ACTIONS.setSession(session)),
  getSpendEntities: () => dispatch(ACTIONS.getSpendEntities()),
  getAccountCodes: () => dispatch(ACTIONS.getAccountCodes()),
  getTrackingCategories: () => dispatch(ACTIONS.getTrackingCategories()),
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMIN_ACTIONS.toggleModal(status, action, item, notAutoClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(XeroApp);
