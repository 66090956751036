import { Types } from '../actions/profileActions';

const defaultState = {};

const profileReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.PROFILE_GETTING: {
      let newState = { ...state };

      newState.profile.updating = true;

      return newState;
    }
    case Types.PROFILE_GET: {
      let newState = { ...state };

      newState.profile.updating = false;

      const adminPortalAdminIds = [
        '592272', // Lisette
        '251', // Emma
        '560053', // Zoey
        '583757', // Eman
        '583917', // Francisco
        '229944', // Jonathan
        'c705e3dc-221d-45f8-9a3b-68fbb19e6e4d', // Jessica
        'd6ea0730-c74e-49bb-b087-6014acc8f1ff', // Shaun
        '723c5b6f-062c-429b-9ed1-ddcf18034f43', // Shaun (Dev)
      ];

      const canEditAdminPortal = adminPortalAdminIds.includes(action?.payload?.profile?.id);

      newState.profile.details = {
        ...newState.profile.details, // Attributes from Cognito
        id: action?.payload?.profile?.id || newState?.profile?.details?.id,
        whiteLabelAdmin: action?.payload?.profile?.whiteLabelAdmin || false,
        cyfAdmin: action?.payload?.profile?.isAdmin || false,
        consultantAdmin: action?.payload?.profile?.consultantAdmin || false,
        ambassadorAdmin: action?.payload?.profile?.ambassadorAdmin || false,
        registrationDate: action?.payload?.profile?.registrationDate,
        credentials: newState?.profile?.details?.credentials,
        contractAdmin: action?.payload?.profile?.contractAdmin || false,
        ambassadorCreator: action?.payload?.profile?.ambassadorCreator || false,
        contractApprover: action?.payload?.profile?.contractApprover || false,
        legalAgreements: {
          userLicenseAgreementConfirmed: action.payload.userLicenseAgreementConfirmed || false,
          userPrivacyPolicyAgreementConfirmed: action.payload.userPrivacyPolicyAgreementConfirmed || false,
        },
      };

      if (canEditAdminPortal) {
        newState.profile.details = { ...newState.profile.details, canEditAdminPortal: canEditAdminPortal };
      } else if (action?.payload?.profile?.greenElementUser) {
        newState.profile.details = { ...newState.profile.details, greenElementUser: true };
      }

      return newState;
    }

    case Types.PROFILE_SAVING: {
      let newState = { ...state };

      newState.profile.saving = action.status;

      return newState;
    }
    case Types.GENERATING_DNS_RECORDS: {
      let newState = { ...state };

      newState.profile.generatingDNSRecords = action.status;

      return newState;
    }
    case Types.PROFILE_SAVE: {
      let newState = { ...state };

      newState.profile.saving = false;
      newState.profile.details = action.payload;

      return newState;
    }
    case Types.PROFILE_ADMIN: {
      let newState = { ...state };

      newState.profile.details[action.payload.admin] = action.payload.status;

      if (action.payload.admin === 'whiteLabelAdmin') {
        newState.isWhiteLabelApp = true;
      } else {
        newState.isWhiteLabelApp = false;
      }

      return newState;
    }
    case Types.PROFILE_SETTING_DOMAIN_EMAIL: {
      let newState = { ...state };

      if (!newState.organisation.wlDomain) {
        newState.organisation.wlDomain = {};
      }

      if (action.response.message) {
        //Error while Generating DNS Records
        newState.organisation.wlDomain = {
          ...newState.organisation.wlDomain,
          emailErrorMessage: action.response.message,
        };
      } else {
        //DNS Records Generated
        newState.organisation.details = {
          ...newState.organisation.details,
          emailIdentityDomain: action.response.domainName,
        };

        newState.organisation.wlDomain = {
          ...newState.organisation.wlDomain,
          dnsRecordsEmailDomain: action.response.dnsRecords,
        };
      }

      return newState;
    }

    case Types.PROFILE_SETTING_DOMAIN_APP_STATUS: {
      let newState = { ...state };

      if (!newState.organisation.wlDomain) {
        newState.organisation.wlDomain = {};
      }

      if (action.response?.data?.executionArn) {
        //Getting Execution Arn to start step function

        newState.organisation.wlDomain.creatingDomain = action.response?.data;

        return newState;
      } else {
        // Step Function Execution was Succeeded

        const output = JSON.parse(action.response.output);

        if (output.message) {
          //Error while Generating DNS Records
          newState.organisation.wlDomain = {
            ...newState.organisation.wlDomain,
            errorMessage: output.message,
          };
        } else {
          //DNS Records Generated
          newState.organisation.details = {
            ...newState.organisation.details,
            liveDomain: output.liveDomain,
          };

          newState.organisation.wlDomain = {
            ...newState.organisation.wlDomain,
            appDomainDNSRecords: output.dnsRecords,
            errorMessageAppDomain: null,
          };
        }

        return newState;
      }
    }

    case Types.CREATE_WL_DOMAIN_STATUS: {
      let newState = { ...state };

      newState.organisation.wlDomain.creatingDomain = {
        ...newState.organisation.wlDomain.creatingDomain,
        status: action.payload.data.status,
        stopDate: action.payload.data.stopDate,
      };

      return newState;
    }

    case Types.GET_SHORT_CODE: {
      let newState = { ...state };

      newState.promoter = {};

      if (!action.response) {
        delete newState.promoter.referralCode;
      }

      newState.promoter = {
        referralCode: action?.response?.referralCode,
      };

      return newState;
    }

    case Types.UPDATE_ORGANISATION: {
      let newState = { ...state };
      const stripeAccountId = action.payload;

      console.log('stripeAccountId', stripeAccountId);

      const orgDetails = state.organisation.details;

      newState.organisation.details = { ...orgDetails, stripeAccountId: stripeAccountId };

      return newState;
    }

    default:
      return state;
  }
};

export default profileReducers;
