import { Types } from '../actions/consumptionActions';
import _ from 'lodash';

const defaultState = {
  consumption: {},
};

const consumptionReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.BENCHMARK: {
      let newState = _.cloneDeep(state);

      const benchmarkData = action.payload;

      newState.benchmarkData = benchmarkData;

      return newState;
    }
    case Types.GET: {
      const year = state.report.details.year;

      let newState = _.cloneDeep(state);

      const {
        wasteByTypeObject,
        consumption,
        entities,
        subentities,
        years,
        months,
        consumptionByEntityByFacility,
        totalEmissionsByScope,
        emissionsByFacility,
        withMarketFactors,
        electricityRenewable,
        emissionsByUserLabel,
        emissionsByCustomCategory,
        customCategories,
      } = action.payload;

      const co2eByEntity = {};
      const co2eMarketByEntity = {};

      const consumptionEntities = consumption && Object.keys(consumption);

      consumptionEntities?.forEach((entityID) => {
        co2eByEntity[entityID] = 0;
        co2eMarketByEntity[entityID] = 0;

        const consumptionSubentities = Object.keys(consumption[entityID]);

        consumptionSubentities.forEach((subentityID) => {
          const consumptionEntitySubentity = Object.keys(consumption[entityID][subentityID]);

          consumptionEntitySubentity.forEach((entitySubentityID) => {
            // we are finally here time to tally up :)
            const allFacilities = consumption[entityID][subentityID][entitySubentityID]._allFacilities;
            if (allFacilities && allFacilities[year]) {
              co2eByEntity[entityID] += allFacilities[year].co2e;

              //co2eMarketByEntity[entityID] += allFacilities[year].market_mix ? allFacilities[year].co2e_market : allFacilities[year].co2e
              co2eMarketByEntity[entityID] +=
                allFacilities[year].market_mix || allFacilities[year].co2e_market
                  ? allFacilities[year].co2e_market
                  : allFacilities[year].co2e;
            }
          });
        });
      });

      newState.consumption = {
        withMarketFactors,
        emissionsByFacility,
        totalEmissionsByScope,
        consumptionByEntityByFacility,
        co2eByEntity,
        co2eMarketByEntity,
        data: consumption,
        entities,
        subentities,
        years: years?.filter((year) => year != null)?.sort((a, b) => b - a),
        months: months?.filter((month) => month != null),
        filter: window.location.pathname.split('/')[1] === 'monthlyreport' ? ['market', 'm1', 'm2', 'm3'] : ['market'],
        wasteByType: wasteByTypeObject || {},
        electricityRenewable,
        emissionsByUserLabel,
        emissionsByCustomCategory,
        customCategories,
      };

      if (newState.showLoader) {
        // Hide loader as soon as consumption object is formed
        newState.showLoader = false;
      }

      return newState;
    }
    case Types.TOGGLE_FILTER: {
      let newState = _.cloneDeep(state);

      const filter = newState.consumption.filter || [];

      const filterContainsToggle = filter.includes(action.filter);

      newState.consumption.filter = filterContainsToggle
        ? _.reject(filter, (item) => item === action.filter)
        : [...filter, action.filter];

      return newState;
    }
    case Types.MONTHLY_FILTER: {
      let newState = _.cloneDeep(state);

      newState.consumption.filter = ['m1', 'm2', 'm3', 'market'];

      return newState;
    }
    case Types.GET_CONSUMPTION_STATUS: {
      let newState = _.cloneDeep(state);

      newState.consumption.processingStepFunction = {
        processingConsumption: action.status,
        executionArn: action.processingConsumption?.executionArn,
      };

      return newState;
    }

    default:
      return state;
  }
};

export default consumptionReducers;
