import React from 'react';

const ConsumptionValue = ({ value, prev, change, classValue, normalise, normalisePrev, withNormalisation, year }) => {
  //let normalisationMissing = false;
  let myChange = change && !isNaN(change) ? change : null;

  if (normalise) {
    value = value / normalise;
  }

  if (normalisePrev) {
    prev = prev / normalisePrev;
  }

  if (!change && value && prev) {
    myChange = (value / prev - 1) * 100;
  }

  if (!change && value && !prev) {
    myChange = null;
  }

  if (withNormalisation && !normalise) {
    // some value is not normalised
    value = 'N/A';
  }

  if (withNormalisation && (!normalise || !normalisePrev)) {
    // some value is not normalised
    myChange = null;
    //normalisationMissing = true;
  }

  return (
    <td className={classValue} key={crypto.randomUUID()}>
      <span className={`value value-${classValue.split(' ')[0]}-${year}`}>
        {!isNaN(value) ? parseFloat(value).toLocaleString('en-GB', { minimumFractionDigits: 3 }) : value}
      </span>
      {myChange && (
        <span className={`usage-detail ${classValue}`}>
          <i className={`fa fa-arrow-${myChange > 0 ? 'up' : 'down'}`} style={{ marginRight: '3px' }} />
          {!isNaN(myChange) ? `${parseFloat(Math.abs(myChange)).toFixed(3)} %` : myChange}
        </span>
      )}
      {!myChange && <span className={`usage-detail ${classValue}`}>-</span>}
    </td>
  );
};

export default ConsumptionValue;
