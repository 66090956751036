import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions';
import { AddButton } from '../../styledComponents/GlobalStyle';
import OrganisationsRow from './OrganisationsRow';

const OrganisationsTable = ({
  list,
  branding,
  showLoader,
  ambassadorAdmin,
  toggleModal,
  currentOrgDetails,
  adminUser,
  getAllItemsFromDB,
}) => {
  const { headers } = branding.fonts?.[0].font || {};
  const { btnTextColor } = branding.colors || {};
  const history = useHistory();

  useEffect(() => {
    getAllItemsFromDB('currencies');
  }, [getAllItemsFromDB]);

  const sortedOrgList = list?.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));

  if (showLoader) return <LinearProgress color='inherit' />;

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <section className='panel'>
          <header className='panel-heading' style={{ fontFamily: headers }}>
            Your Organisations
          </header>
          <div className='panel-body'>
            <table className='table table-hover general-table organisations' style={{ fontFamily: headers }}>
              <thead>
                <tr>
                  <th className='hidden-phone'>Organisation</th>
                  <th>Industry</th>
                  <th>Currency</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  Array.isArray(sortedOrgList) &&
                  sortedOrgList.map((organisationDetails, index) => (
                    <OrganisationsRow key={index} organisationDetails={organisationDetails} />
                  ))}

                {!ambassadorAdmin && (
                  <tr>
                    <td>
                      <AddButton
                        style={{ color: btnTextColor }}
                        onClick={() => {
                          // Check if the organisation details are available and if the organisation creation is disabled, and user is not an admin
                          if (currentOrgDetails?.id && !currentOrgDetails?.organisationCreation && !adminUser) {
                            toggleModal(true, 'organisationCreation-disabled', null);
                          } else {
                            history.push('/organisations/new');
                          }
                        }}>
                        Add an Organisation
                      </AddButton>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { ambassadorAdmin, cyfAdmin, whiteLabelAdmin, consultantAdmin } = state?.profile?.details || {};
  const orgDetails = state?.organisation?.details;
  const adminUser = cyfAdmin || whiteLabelAdmin || consultantAdmin;

  return {
    ambassadorAdmin,
    list: state.organisation?.list,
    branding: state.branding,
    showLoader: state?.showLoader,
    currentOrgDetails: orgDetails,
    allOrgs: state?.admin?.organisations,
    adminUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsTable);
