import { addYears, differenceInDays, formatISO, isLeapYear } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { AddButton, AddUsageInputStyles, CalendarBtn } from '../../styledComponents/GlobalStyle';
import { getUsageOptionsByFilteredCF } from '../UsageData/utils/UsageUtils';
import { RemoveLeadingZeroes, parseDateToTz } from '../utils/GlobalFunctions';
import ConfirmationModal from './ConfirmationModal';
import CustomSelectWithTooltip from './CustomSelectWithTooltip';
import './reactdatepicker.css';

export const itemToOptions = (item) => ({
  value: item?.id,
  label: item?.name || item?.priceType,
  description: item?.description,
  // for sub entities we need the metric and calc_market property
  metric: item?.metric || null,
  fuel_mix: item?.calc_market || null,
});

const AddEditUsage = ({
  usageDataModal,
  item,
  showUsageDataModal,
  editUsageModal,
  options,
  getFilteredFactors,
  waterTreatment,
  solarConsumed,
  prevDates,
  gridSources,
  saveUsageData,
  usageOptions,
  usageOptionsOK,
  hasConversionFactors,
  fieldsToClearInputs,
  cyfDataAdmin,
  branding,
  getUsageById,
  usageDetails,
  editSolarObj,
  currentSubentity,
  adminPortal,
  conversionFactors,
  getAllItemsFromDB,
  electricitySources,
  usageTypeKeys,
  currencies,
  orgCurrency,
  currency,
  isSpend,
}) => {
  const { email } = branding;
  const { primaryColor } = branding.colors;

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [invalidUsageDate, setInvalidUsageDate] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [validFactors, setValidFactors] = useState([]);
  const [errorDisplay, setErrorDisplay] = useState(false);

  //this is to check if the market factors fetch returns grid sources, thus confirming if they should see yes or no for fuel mix.
  const [hasFuelMix, setHasFuelMix] = useState(item?.fuel_mix ? true : false);
  const [fuelMixInputs, setFuelMixInputs] = useState(false);
  const [localTextState, setLocalTextState] = useState({
    userLabel: '',
    usage: '',
    waterTreatment: '',
    solarConsumed: '',
    rawUsage: null,
  });
  const [localGridMix, setLocalGridMix] = useState(item?.fuel_mix || { 599324: '100' });

  if (isSpend && orgCurrency && !item?.currency) {
    editUsageModal(item?.rawUsageCurrency || orgCurrency?.value, { name: 'currency' });
    editUsageModal(true, { name: 'isSpend' });
  }

  useEffect(() => {
    if (gridSources) {
      getAllItemsFromDB('electricitySources');
    }
    //eslint-disable-next-line
  }, [hasConversionFactors, getAllItemsFromDB]);

  useEffect(() => {
    if (item?.id || item?.copy) {
      setLocalTextState({
        userLabel: item?.userLabel || '',
        usage: item?.copy ? '' : item?.rawUsage || item?.usage || '',
        rawUsage: item?.copy ? null : item?.rawUsage || null,
      });
      if (item?.fuel_mix) {
        setLocalGridMix(item?.fuel_mix);
      }
      if (item?.fuel_mix && item?.fuel_mix?.['599324'] !== '100') {
        // For edit mode, if the usage has fuel mix different Residual show the grid mix inputs
        setFuelMixInputs(true);
      }
    }
  }, [item]);

  useEffect(() => {
    setHasFuelMix(gridSources?.length > 0);
  }, [gridSources?.length]);

  useEffect(() => {
    if (item?.dateStart && item?.dateEnd) {
      const dateDiffInDays = differenceInDays(new Date(item?.dateEnd), new Date(item?.dateStart));
      const usageStartYear = new Date(item?.dateStart).getFullYear();
      // If usage date start falls after february check if usage date end year is a leap year
      // Otherwise we check if usage date start year is a leap year
      const checkForEndYear = formatISO(new Date(item?.dateStart)) >= formatISO(new Date(usageStartYear, 2, 1));
      const leapYear = checkForEndYear
        ? isLeapYear(addYears(new Date(item?.dateStart), 1))
        : isLeapYear(new Date(item?.dateStart));
      const maxUsageSpanDays = leapYear ? 365 : 364;

      if (dateDiffInDays > maxUsageSpanDays) {
        // Date Span cannot be higher than 364 days
        setInvalidUsageDate(true);
      } else setInvalidUsageDate(false);
    }
  }, [item?.dateStart, item?.dateEnd]);

  useEffect(() => {
    if (options?.subentity?.value?.value && item?.dateStart) {
      const subentityId = options?.subentity?.value?.value;
      const subentityPossibleFuelMix = options?.subentity?.value?.fuel_mix || false;
      const metric = options?.metric?.value?.value;
      if (metric) {
        // If metric is undefined don't fetch CF
        // Get all CFs for this combo
        getFilteredFactors(
          formatISO(new Date(item.dateStart)),
          subentityId,
          subentityPossibleFuelMix,
          metric,
          adminPortal ? item.organisation : null
        );
      }
    }
    //eslint-disable-next-line
  }, [options?.metric?.value?.value, options?.subentity?.value?.value, item?.dateStart, item?.dateEnd]);

  const checkDiscontinuedCF = (endDate, conversionFactors) => {
    // Check if the CF is discontinued
    let valid = [];
    conversionFactors?.forEach((cf) => {
      if (cf?.discontinuedDate < moment(endDate).format('YYYY-MM-DD')) {
      } else {
        valid.push(cf);
      }
    });
    setValidFactors(valid);
  };

  useEffect(() => {
    checkDiscontinuedCF(item?.dateStart, conversionFactors);
  }, [item?.dateStart, item?.dateEnd, options?.metric?.value?.value, conversionFactors]);

  useEffect(() => {
    setErrorDisplay(false);
  }, [item?.entity, item?.subentity]);

  if (!usageDataModal?.status) return null;

  const localTextChange = (value, name) => {
    const prevState = { ...localTextState };

    if (waterTreatment && name === 'usage') prevState['waterTreatment'] = RemoveLeadingZeroes(value);
    else if (solarConsumed && name === 'usage') prevState['solarConsumed'] = RemoveLeadingZeroes(value);

    if (name === 'waterTreatment' || name === 'solarConsumed') {
      // if value of waterTreated or solarConsumed becomes greater than usage, set the value to same as usage
      // if value is empty string set it to 0
      // else set it to value.
      prevState[name] =
        parseFloat(value) > parseFloat(prevState['usage']) ? prevState['usage'] : !value ? '0' : RemoveLeadingZeroes(value);
    } else if (name === 'rawUsage') {
      prevState['usage'] = value;
      prevState['rawUsage'] = null;
    } else {
      prevState[name] = value;
    }
    setLocalTextState(prevState);
  };

  const localGridChange = (value, name) => {
    if (!hasFuelMix) {
      setLocalGridMix({ 599324: '100' });
    } else {
      const prevMix = { ...localGridMix };
      prevMix[name] = parseFloat(value) <= 0 ? '0' : RemoveLeadingZeroes(value);
      // Delete residual if we have a fuel mix.
      if (Object.keys(prevMix).length > 1) {
        delete prevMix['599324'];
      }
      setLocalGridMix(prevMix);
    }
  };

  //check if fuelMix adds up to 100
  const fuelMixSum = parseFloat(
    Object.values(localGridMix)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      .toFixed(11)
  );

  //is a facility, subentity, usage>0, usageOptions set, and gridmix adding up to 100, if there is one?
  const canSubmitUsage =
    item?.dateStart &&
    item?.dateEnd &&
    item?.facility &&
    currentSubentity &&
    parseFloat(localTextState?.usage) >= 0 &&
    usageOptionsOK &&
    (fuelMixInputs ? fuelMixSum === 100 : true) &&
    !invalidUsageDate &&
    validFactors.length > 0 &&
    hasConversionFactors &&
    new Date(item?.dateStart) <= new Date(item?.dateEnd);

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog' style={{ height: '85vh', fontSize: '12px' }}>
        <div className='modal-content' style={{ background: 'transparent' }}>
          <div className='modal-header'>
            <button
              onClick={() => {
                setHasFuelMix(false);
                setLocalTextState({
                  userLabel: '',
                  usage: '',
                  waterTreatment: '',
                  solarConsumed: '',
                });
                setLocalGridMix({ 599324: '100' });
                showUsageDataModal(false, null);
              }}
              type='button'
              className='close'>
              x
            </button>
            <h4 className='modal-title'>{item?.id ? 'Edit' : 'Add'} Usage Data</h4>
          </div>
          <div
            className='modal-body'
            id='modal-body'
            style={{ overflow: 'auto', background: 'transparent', height: '85vh', padding: 0 }}>
            <div
              style={{
                overflow: 'visible',
                background: 'white',
                padding: 15,
                borderBottomRightRadius: '6px',
                borderBottomLeftRadius: '6px',
              }}>
              <div id='usage-facility' className='form-group'>
                <label>
                  Facility <em>*</em>
                </label>
                <div>
                  <CustomSelectWithTooltip
                    value={options?.facility?.value || false}
                    name={'facility'}
                    onChange={(s, e) => editUsageModal(s?.value, e)}
                    options={options?.facility?.values}
                    zIndex={26}
                    isClearable={true}
                    components
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-xs-6'>
                  <div id='usage-startDate' className='form-group'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <label>
                        Start Date <em>*</em>
                      </label>
                      {prevDates?.dateStart && (
                        <button
                          style={{ color: '#1f9b37', textDecoration: 'underline', border: 0, background: 'inherit' }}
                          onClick={() => editUsageModal(prevDates, { name: 'prevDates' })}>
                          Use Previous Dates
                        </button>
                      )}
                    </div>
                    <label className='input-group date datepicker'>
                      <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={5}
                        scrollableYearDropdown
                        dateFormat='dd/MM/yyyy'
                        selected={(item?.dateStart && parseDateToTz(item?.dateStart, 'yyyy-MM-dd')) || null}
                        //selected={(item?.dateStart && new Date(item?.dateStart)) || null}
                        onChange={(date, e) => {
                          const dateInput = e?.target?.value; // When user types the date
                          if (!date) {
                            // Clear Date
                            editUsageModal(date, { name: 'dateStart' });
                            setInvalidUsageDate(false);
                          } else if ((dateInput && dateInput?.split('/').length !== 3) || dateInput?.length < 10) {
                            // Return until date format (dd/MM/YYYY) and date length are not valid
                            return console.log('Invalid Date');
                          } else {
                            editUsageModal(formatISO(date), { name: 'dateStart' });
                            if (!item?.dateEnd || new Date(item?.dateEnd) < date)
                              editUsageModal(formatISO(date), { name: 'dateEnd' });
                          }
                          /* if (currentSubentity && item?.metric) {
                          const subentityPossibleFuelMix = options?.subentity?.value?.fuel_mix || false;
                          // Factor year is always usage end date year, date will be in js date object
                          const factorYear = date && formatISO(date)?.substring(0, 4);
                          // Get all CFs for this combo equal and prior to usage end date year
                          console.log('Factor Year: ', factorYear);
                          getFilteredFactors(
                            factorYear,
                            currentSubentity,
                            subentityPossibleFuelMix,
                            item?.metric,
                            adminPortal ? item.organisation : null
                          );
                        } */
                        }}
                        selectsStart
                        startDate={item?.dateStart && new Date(item?.dateStart)}
                        endDate={item?.dateEnd && new Date(item?.dateEnd)}
                        className='form-control startDate'
                        maxDate={new Date()}
                        popperPlacement='bottom-end'
                        isClearable={true}
                        popperModifiers={{ offset: { enabled: true, offset: '77px, 0px' } }}
                      />

                      <span className='input-group-btn'>
                        <CalendarBtn className='btn btn-primary date-set'>
                          {' '}
                          <i className='fa fa-calendar' />{' '}
                        </CalendarBtn>
                      </span>
                    </label>
                    <span
                      id='dateSpan-warning'
                      style={{ display: invalidUsageDate ? 'block' : 'none', color: 'red', fontWeight: 'bold' }}>
                      <i className='fa-solid fa-triangle-exclamation' style={{ color: 'red' }} />
                      {''}Your date span cannot exceed 1 year!
                    </span>
                  </div>
                </div>
                <div className='col-xs-6'>
                  <div id='usage-endDate' className='form-group'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <label>
                        End date <em>*</em>
                      </label>
                    </div>
                    <label className='input-group date datepicker'>
                      <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={5}
                        scrollableYearDropdown
                        dateFormat='dd/MM/yyyy'
                        selected={(item?.dateEnd && parseDateToTz(item?.dateEnd, 'yyyy-MM-dd')) || null}
                        //selected={(item?.dateEnd && new Date(item?.dateEnd)) || null}
                        onChange={(date, e) => {
                          const dateInput = e?.target?.value;
                          if (!date) {
                            // Clear Date
                            editUsageModal(date, { name: 'dateEnd' });
                            setInvalidUsageDate(false);
                          } else if (
                            (dateInput && dateInput?.split('/').length !== 3) ||
                            (dateInput && dateInput?.length !== 10)
                          ) {
                            // Return until date format (dd/MM/YYYY) and date length are not valid
                            return console.log('Invalid Date');
                          } else {
                            editUsageModal(formatISO(date), { name: 'dateEnd' });
                          }
                        }}
                        selectStart
                        startDate={item?.dateStart && new Date(item?.dateStart)}
                        endDate={item?.dateEnd && new Date(item?.dateEnd)}
                        className='form-control startDate'
                        minDate={item?.dateStart && new Date(item?.dateStart)}
                        //maxDate={new Date()}
                        isClearable={true}
                        popperPlacement='bottom-end'
                        popperModifiers={{ offset: { enabled: true, offset: '77px, 0px' } }}
                      />
                      <span className='input-group-btn'>
                        <CalendarBtn className='btn btn-primary date-set'>
                          {' '}
                          <i className='fa fa-calendar' />{' '}
                        </CalendarBtn>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <Tooltip
                content={'Optional customised top-level category name.' || ''}
                background='#333'
                color='white'
                forceDirection={true}
                direction='right'
                styles={{ position: 'absolute', left: '165px', zIndex: '2', marginTop: 1 }}
                tipContentClassName='addUsageData-tooltip-content'>
                <i className='fa fa-question-circle' style={{ cursor: 'pointer' }} />
              </Tooltip>

              <div className='row'>
                <div className='col-xs-12'>
                  <div className='form-group'>
                    <label>Custom top-level category</label>
                    <div style={{ width: '100%' }}>
                      <input
                        style={AddUsageInputStyles}
                        name='userLabel'
                        value={localTextState?.['userLabel'] || ''}
                        onChange={(e) => {
                          localTextChange(e.target.value, 'userLabel');
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-xs-12'>
                  <div id='usage-usageType' className='form-group'>
                    <label>
                      Usage Type <em>*</em>
                    </label>
                    <CustomSelectWithTooltip
                      value={options?.entity?.value || false}
                      name={'entity'}
                      onChange={(s, e) => {
                        setHasFuelMix(false);
                        setLocalGridMix({ 599324: '100' });
                        setFuelMixInputs(false);
                        const { usage, waterTreatment, solarConsumed, ...newTextState } = localTextState;
                        setLocalTextState(newTextState);
                        editUsageModal(s?.value, e);
                      }}
                      options={options?.['entity']?.values}
                      zIndex={25}
                      isClearable={true}
                      components
                    />
                  </div>
                </div>
              </div>

              {Object.keys(options)?.map((optionName, index) => {
                // Don't show dropdown for the below options and if there are no options
                if (
                  optionName === 'facility' ||
                  optionName === 'entity' ||
                  options[optionName]?.values?.length === 0 ||
                  (optionName === 'metric' && options?.metric?.values.length === 1)
                )
                  return null;
                const datesSet = item?.dateStart && !invalidUsageDate;
                if (!options[optionName].values) {
                  return (
                    <div key={optionName} className='row'>
                      <div id={`usage-${optionName}`} className='col-xs-12' style={{ marginBottom: 15 }}>
                        <label>
                          <Tooltip
                            content={
                              item?.currency
                                ? `Amount of ${currency?.symbol} (${currency?.isoCode}) you spent on the usage type.`
                                : options?.metric?.value?.description || ''
                            }
                            background='#333'
                            color='white'
                            forceDirection={true}
                            direction='right'
                            tipContentClassName='addUsageData-tooltip-content'>
                            {options[optionName]?.title || ''} <em>*</em>
                            <i className='fa fa-question-circle' style={{ marginLeft: 7, cursor: 'pointer' }} />
                          </Tooltip>
                        </label>
                        <NumberFormat
                          className='form-control'
                          inputMode='numeric'
                          name={optionName}
                          value={
                            localTextState?.rawUsage !== null ? localTextState?.rawUsage : localTextState?.[optionName] || ''
                          }
                          allowNegative={false}
                          onChange={(e) => {
                            localTextChange(e.target.value, optionName);
                            if (localTextState?.rawUsage !== null) {
                              localTextChange(e.target.value, 'rawUsage');
                            }
                            checkDiscontinuedCF(item?.dateEnd, conversionFactors);
                            setErrorDisplay(true);
                          }}
                        />
                      </div>
                    </div>
                  );
                }
                return (
                  <div key={index} className='row' style={{ display: options[optionName] && datesSet ? 'block' : 'none' }}>
                    <div className='col-xs-12'>
                      <div id={`usage-${optionName}`} className='form-group'>
                        <label>
                          {options[optionName]?.title}
                          <em>*</em>
                        </label>
                        <CustomSelectWithTooltip
                          maxMenuHeight={200}
                          item={item}
                          editUsageModal={editUsageModal}
                          value={options?.[optionName]?.value || false}
                          name={optionName}
                          onChange={(s, e) => {
                            if (fieldsToClearInputs.includes(optionName)) {
                              setHasFuelMix(false);
                              setFuelMixInputs(false);
                              setLocalGridMix({ 599324: '100' });
                              //remove properties usage,waterTreatment,solarConsumed from new text state.
                              const { usage, waterTreatment, solarConsumed, ...newTextState } = localTextState;
                              setLocalTextState(newTextState);
                              editUsageModal(optionName === 'subentity' ? s : s?.value, e);
                              if (optionName === 'metric' && isSpend) {
                                editUsageModal(true, 'isSpend');
                              }
                            } else {
                              editUsageModal(s?.value || false, e);
                            }
                          }}
                          options={options[optionName]?.values}
                          zIndex={25 - (index + 1)}
                          isClearable={true}
                          components
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* for solar or water */}
              <div className='row' style={{ display: solarConsumed || waterTreatment ? 'block' : 'none' }}>
                <div className='col-xs-12' style={{ marginBottom: 15 }}>
                  <label>
                    <Tooltip
                      content={options?.metric?.value?.description || ''}
                      background='#333'
                      color='white'
                      forceDirection={true}
                      direction='right'
                      tipContentClassName='addUsageData-tooltip-content'>
                      {waterTreatment ? 'Water Treatment in' : 'Solar Consumed in'} {options?.metric?.value?.label}
                      <em>*</em>
                      <i className='fa fa-question-circle' style={{ marginLeft: 7, cursor: 'pointer' }} />
                    </Tooltip>
                  </label>
                  <NumberFormat
                    className='form-control'
                    inputMode='numeric'
                    name={waterTreatment ? 'waterTreatment' : 'solarConsumed'}
                    value={localTextState?.[waterTreatment ? 'waterTreatment' : 'solarConsumed'] || ''}
                    onChange={(e) => {
                      localTextChange(e.target.value, waterTreatment ? 'waterTreatment' : 'solarConsumed');
                    }}
                    allowNegative={false}
                  />
                </div>
              </div>

              {item?.dateStart && item?.entity && currentSubentity && gridSources?.length > 0 && (
                <div className='market-based-electricity-mix'>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div id='suppliers-fuel-mix' style={{ display: 'flex' }}>
                      Do you have your supplier’s fuel mix?
                      <Tooltip
                        content={
                          "You will find your energy providers' fuel mix via their website or in your utility bills. Enter the percentage of the energy source without % into each fuel type, even if it is 0. For example, if 100% renewables, it would be entered as Biomass 0 Gas 0 Other 0 Coal 0 Nuclear 0 Renewable 100. The total sum of the percentage numbers must be equal to 100." ||
                          ''
                        }
                        color='white'
                        zIndex={99999}
                        className='tooltip-fuelMix-wrapper'
                        tipContentClassName='tooltip-fuelMix-content'>
                        <i className='fa fa-question-circle' style={{ cursor: 'pointer' }} />
                      </Tooltip>
                    </div>
                    <div id='suppliers-fuel-mix-btn'>
                      <button
                        className='btn btn-success'
                        disabled={fuelMixInputs}
                        onClick={() => {
                          let grid = {};
                          gridSources.map((gs) => (grid[gs] = '0'));
                          setLocalGridMix(grid);
                          setFuelMixInputs(true);
                          setHasFuelMix(true);
                        }}
                        style={{ marginRight: 10 }}>
                        Yes
                      </button>
                      <button
                        className='btn btn-warning'
                        disabled={!fuelMixInputs}
                        onClick={() => {
                          setLocalGridMix({ 599324: '100' });
                          setFuelMixInputs(false);
                        }}>
                        No
                      </button>
                    </div>
                  </div>

                  {/* for grid mix inputs */}
                  <div id='grid-mix' style={{ marginTop: 15, overflow: 'auto', background: 'white' }}>
                    {localGridMix?.['599324'] !== '100' &&
                      fuelMixInputs &&
                      hasFuelMix &&
                      currentSubentity &&
                      gridSources?.map((gs) => (
                        <div
                          className='form-group col-xs-6'
                          style={{
                            display: gs !== 'Residual' ? 'block' : 'none',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          key={gs}>
                          <label className='col-xs-6 row' style={{ lineHeight: '25px' }}>
                            {_.find(electricitySources, { id: gs })?.source}
                          </label>
                          <div className='input-group date datepicker' id={`gridMix-${gs.toLowerCase()}`}>
                            <NumberFormat
                              className='form-control'
                              inputMode='numeric'
                              name={gs}
                              value={localGridMix?.[gs]}
                              onChange={(e) => {
                                if (!e.target.value) {
                                  e.target.value = '0';
                                }
                                localGridChange(e.target.value, gs);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    {fuelMixInputs && localGridMix?.['599324'] !== '100' && fuelMixSum !== 100 && (
                      <label id='grid-mix-error' className='error' style={{ background: 'white' }}>
                        <i className='fa-solid fa-triangle-exclamation' style={{ marginRight: 10 }} />
                        Grid mix does not make up 100%
                      </label>
                    )}
                  </div>
                </div>
              )}

              {editSolarObj && (
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'red',
                    alignItems: 'center',
                    marginBottom: 2,
                  }}>
                  <div className='alert alert-info' role='alert' style={{ color: '#130384' }}>
                    <i className='fa fa-exclamation-triangle' aria-hidden='true'></i> If you change your Solar&nbsp;
                    {editSolarObj?.currentSolarLabel} kWh, please ensure you edit your corresponding{' '}
                    {editSolarObj?.correspondingLabels} so that for the same time periods:
                    <p style={{ marginTop: '5px' }}>Solar Generated kWh = Solar Consumed kWh + Solar Exported kWh</p>
                  </div>
                </span>
              )}

              <span
                style={{
                  display:
                    hasConversionFactors === false || (errorDisplay === true && validFactors.length === 0) ? 'flex' : 'none',
                  color: 'red',
                  fontWeight: 'bold',
                  alignItems: 'center',
                }}>
                <i className='fa-solid fa-triangle-exclamation' style={{ color: 'red', fontSize: 20 }} />
                <span style={{ marginLeft: 10 }}>
                  Sorry, we don't have a carbon factor for that year, please contact {email} for help.
                </span>
              </span>

              <div style={{ display: 'flex', justifyContent: 'center', background: 'white' }}>
                <AddButton
                  className={`btn btn-${canSubmitUsage ? 'success' : 'warning'}`}
                  id='submit-usage'
                  style={{ cursor: !canSubmitUsage && 'not-allowed', marginTop: !canSubmitUsage && 10, marginBottom: 5 }}
                  onClick={() => {
                    if (canSubmitUsage) {
                      saveUsageData(
                        [
                          {
                            ...item,
                            usage: localTextState?.usage,
                            waterTreatment: localTextState?.waterTreatment,
                            solarConsumed: localTextState?.solarConsumed,
                            userLabel: localTextState?.userLabel,
                            fuel_mix: (hasFuelMix && localGridMix) || null,
                          },
                        ],
                        adminPortal
                      );
                      setHasFuelMix(false);
                      setFuelMixInputs(false);
                      setLocalGridMix(false);
                      setLocalTextState(false);
                      setShowConfirmModal(true);
                    } else console.log('Dont Save');
                  }}>
                  {canSubmitUsage ? `${item?.id ? 'Edit' : 'Add'} Data` : 'Data Incomplete'}
                </AddButton>
              </div>
              {cyfDataAdmin && item?.id && (
                <>
                  <hr />
                  <button
                    className='see_more_anchor'
                    style={{ '--color': `${primaryColor}`, fontSize: 15, border: 0, background: 'inherit' }}
                    onClick={() => {
                      getUsageById(item?.id);
                      setShowMore(!showMore);
                    }}>
                    {showMore ? 'Hide...' : 'See more...'}
                  </button>
                  {usageDetails && (
                    <div style={{ display: usageDetails && showMore ? 'block' : 'none', marginTop: 10 }}>
                      <p style={{ fontWeight: 'bold' }}>Modified Date: {usageDetails?.modifiedDate}</p>
                      {['location', 'market'].map((key) => {
                        return (
                          <div>
                            <p style={{ fontWeight: 'bold' }}>{key.charAt(0).toUpperCase() + key.slice(1)}:</p>
                            {['calendarYear', 'reportingYear'].map((elem) => (
                              <>
                                <p style={{ fontWeight: 'bold' }}>{elem.charAt(0).toUpperCase() + elem.slice(1)}</p>
                                {Object.keys(usageDetails?.[key]?.[elem]).map((x) => (
                                  <ol>
                                    {x} : {JSON.stringify(usageDetails?.[key]?.[elem]?.[x])}
                                  </ol>
                                ))}
                              </>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>
        <ConfirmationModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { entities, subentities, usageOptions, conversionFactors, filteredCF_UsageOpt } = state || {};
  const { item, adminPortal } = state?.usageDataModal || {};
  const profile = state?.profile.details;
  //if admin get facilities related to the usage organisation.
  const facilities = adminPortal
    ? _.filter(state?.admin?.adminPortal?.facilities, { organisationId: item.organisation })
    : state?.facility?.list;
  const usageTypeDetails = state?.admin?.adminPortal?.usageTypeDetails;
  const currencies = state?.admin?.adminPortal?.currencies;
  const metrics = state?.metric?.list;
  const hasConversionFactors = conversionFactors ? conversionFactors?.length > 0 : undefined;
  const currentSubentity = item?.subentity;
  const options = {};
  const currency = currencies?.find((c) => c.id === item?.currency);
  const isSpend = metrics?.find((m) => m.id === item?.metric)?.isSpend === true;

  options.facility = {
    values: _.sortBy(facilities?.map(itemToOptions), ['label']),
    value: item?.facility && _.find(facilities?.map(itemToOptions), { value: item.facility }),
  };

  options.entity = {
    values: _.sortBy(entities?.map(itemToOptions), ['label']),
    value: item?.entity && _.find(entities?.map(itemToOptions), { value: item?.entity }),
  };

  options.subentity = {
    values: item?.entity
      ? _.sortBy(subentities?.filter((s) => s.entity.includes(item?.entity) && s?.hideInUI === false)?.map(itemToOptions), [
          'label',
        ])
      : [],
    title: 'Usage Sub Type',
    value: currentSubentity && _.find(subentities?.map(itemToOptions), { value: currentSubentity }),
  };

  const subentityMetrics = options?.subentity?.value?.metric;
  options.metric = {
    values: currentSubentity
      ? _.sortBy(metrics?.filter((m) => subentityMetrics?.includes(m.id))?.map(itemToOptions), ['label'])
      : [],
    title: 'Usage / Factor Units',
    value: item?.metric && _.find(metrics?.map(itemToOptions), { value: item?.metric }),
  };

  options.currency = {
    values: isSpend //Only show the currency field if metric is Spend
      ? _.sortBy(
          currencies?.filter((x) => x.hideInUI !== true).map((x) => ({ label: `${x.symbol} (${x.isoCode})`, value: x.id })),
          ['label']
        )
      : [],
    title: 'Usage Data Input Currency',
    value:
      item?.currency &&
      _.find(
        currencies?.filter((x) => x.hideInUI !== true).map((x) => ({ label: `${x.symbol} (${x.isoCode})`, value: x.id })),
        { value: item?.currency }
      ),
  };

  usageOptions &&
    item?.metric &&
    Object.keys(usageOptions)?.forEach((usageOpt, index, arr) => {
      // Display Usage Options in Cascade
      const { displayName } = usageTypeDetails?.find((ut) => ut.type === usageOpt) || {};
      const label = displayName ? displayName : usageOpt.charAt(0).toUpperCase() + usageOpt.slice(1) + ' type';

      if (index === 0) {
        // Show immediately the first one
        options[usageOpt] = {
          values: _.sortBy(usageOptions[usageOpt]?.map(itemToOptions), ['label']),
          title: label,
          value: item?.[usageOpt] && _.find(usageOptions[usageOpt]?.map(itemToOptions), { value: item?.[usageOpt] }),
        };
      } else if (item[arr[0]]) {
        // If the first one is already selected, show the following ones
        for (index; index <= arr.length - 1; index++) {
          // As soon as we select a usage option, we filter the CF that match that usage option and the above usage combo
          // Filter the usage options to just show the ones that match the filtered CF
          const filteredUsageOpt_CF = getUsageOptionsByFilteredCF(usageOptions, filteredCF_UsageOpt);
          if (item[arr[index - 1]] && filteredUsageOpt_CF.hasOwnProperty(usageOpt)) {
            // As soon as the previous one is selected, show the following one
            options[usageOpt] = {
              values: _.sortBy(filteredUsageOpt_CF[usageOpt]?.map(itemToOptions), ['label']),
              title: label,
              value: item?.[usageOpt] && _.find(usageOptions[usageOpt]?.map(itemToOptions), { value: item?.[usageOpt] }),
            };
          } else {
            continue;
          }
        }
      }
    });

  let usageOptionsOK = true;
  if (usageOptions && Object.keys(usageOptions).length > 0) {
    const filteredUsageOpt_CF = getUsageOptionsByFilteredCF(usageOptions, filteredCF_UsageOpt);
    Object.keys(Object.keys(filteredUsageOpt_CF).length > 0 ? filteredUsageOpt_CF : usageOptions).forEach((ut) => {
      if (!item?.[ut]) usageOptionsOK = false;
    });
  }

  if (currentSubentity && item?.metric && hasConversionFactors && usageOptionsOK) {
    options.usage = {
      title: `Usage in ${isSpend ? currency?.symbol + ' (' + currency?.isoCode + ')' : options.metric?.value?.label}`,
    };
  }

  const waterTreatment = item?.entity === '151938' && currentSubentity !== '185362' && item?.metric === '4389' && !item?.id;

  const solarConsumed = currentSubentity === '229591' && item?.metric === '4385' && !item?.id;

  let editSolarObj = false;
  if ((currentSubentity === '229591' || currentSubentity === '233875' || currentSubentity === '233408') && item?.id) {
    editSolarObj =
      currentSubentity === '229591'
        ? { currentSolarLabel: 'Generated', correspondingLabels: 'Solar Consumed kWh and/or Solar Exported kWh' }
        : currentSubentity === '233875'
        ? { currentSolarLabel: 'Exported', correspondingLabels: 'Solar Generated kWh and Solar Consumed kWh' }
        : { currentSolarLabel: 'Consumed', correspondingLabels: 'Solar Generated kWh and/or Solar Exported kWh' };
  }

  const usageTypeKeys = state?.metric?.types && Object.keys(state?.metric?.types);
  const fieldsToClearInputs = usageTypeKeys && [...usageTypeKeys, 'metric', 'subentity'];
  const electricitySources = state?.admin?.adminPortal?.electricitySources;
  const organisationCurrency = currencies?.find((c) => c.id === state?.organisation?.details?.currency);
  const orgCurrency = organisationCurrency && {
    label: `${organisationCurrency.symbol} (${organisationCurrency.isoCode})`,
    value: organisationCurrency.id,
  };

  return {
    usageTypeKeys,
    usageDataModal: state?.usageDataModal,
    usageDetails: state?.usageDataModal?.usageDetails,
    item,
    usageOptions,
    options,
    waterTreatment,
    solarConsumed,
    prevDates: state?.usageDataModal?.prevDates,
    gridSources: state?.gridSources,
    usageOptionsOK,
    hasConversionFactors,
    fieldsToClearInputs,
    cyfDataAdmin: profile?.cyfAdmin && profile.canEditAdminPortal,
    branding: state?.branding,
    editSolarObj,
    currentSubentity,
    adminPortal,
    conversionFactors,
    electricitySources,
    currencies,
    orgCurrency,
    currency,
    isSpend,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  showUsageDataModal: (status, item) => dispatch(USAGEACTIONS.showUsageDataModal(status, item)),
  editUsageModal: (selectedInput, event) => dispatch(USAGEACTIONS.editUsageModal(selectedInput, event)),
  getUsageById: (id) => dispatch(USAGEACTIONS.getUsageById(id)),
  getFilteredFactors: (year, subentity, gm, metric, organisationId) =>
    dispatch(USAGEACTIONS.getFilteredFactors(year, subentity, gm, metric, organisationId)),
  saveUsageData: (usageData, adminPortal) => dispatch(USAGEACTIONS.saveUsageData(usageData, adminPortal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUsage);
