import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AuthBtn } from '../../styledComponents/GlobalStyle';

const DropdownContent = styled.div`
  background-color: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

const AuthDropdown = ({ headers }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className='' style={{ position: 'relative', display: 'block', width: '45%' }}>
      <AuthBtn
        className='btn btn-lg btn-login'
        onClick={toggleDropdown}
        style={{ fontFamily: headers, color: 'white', width: '100%' }}>
        Sign In with Partner
      </AuthBtn>
      {showDropdown && (
        <DropdownContent>
          <button
            className='btn btn-lg btn-login btn-block xero-login partner-btn'
            style={{ fontFamily: headers, width: '100%' }}
            href='#'
            onClick={() => Auth.federatedSignIn({ customProvider: 'Xero' })}>
            Sign in with{' '}
            <img
              src={process.env.PUBLIC_URL + '/images/xero_circle.png'}
              alt='Xero logo'
              style={{ width: '40px', height: '40px' }}
            />
          </button>
        </DropdownContent>
      )}
    </div>
  );
};

export default AuthDropdown;
