import React from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { questionStyles, customStyles, buttonStyles } from '../codeStyles';
import { Actions as FACILITY_ACTIONS } from '../../store/actions/facilityActions';
import _ from 'lodash';

function subtractYears(numOfYears, date) {
  date.setFullYear(date.getFullYear() - numOfYears);

  return date;
}

const ImportFromSage = ({
  selectedYear,
  selectYear,
  processSageImport,
  processingImport,
  getImportStatus,
  selectedAccounts,
  facilities,
  getFacilities,
  currentOrg,
}) => {
  const { reportingYearEndDay, reportingYearEndMonth } = currentOrg || {};

  const years = [...new Array(new Date().getFullYear() - 2017)].map((item, index) => 2018 + index);
  if (reportingYearEndMonth !== '12' || reportingYearEndDay !== '31') {
    years.push(years[years.length - 1] + 1);
  }
  let dates = [];

  const convertDate = (dateString) => {
    var date = new Date(dateString);
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  };

  currentOrg &&
    facilities?.length > 0 &&
    years.forEach((year) => {
      var dateObj = { range: '', endYear: '' };
      var dateTo = new Date(year, reportingYearEndMonth - 1, reportingYearEndDay);
      var dateFrom = new Date(year, reportingYearEndMonth - 1, reportingYearEndDay);
      subtractYears(1, dateFrom);
      dateFrom.setDate(dateFrom.getDate() + 1);

      dateObj.range = convertDate(dateFrom) + ' - ' + convertDate(dateTo);
      dateObj.endYear = year;

      dates.push(dateObj);
    });

  if (!selectedAccounts) return null;

  const yearOptions = dates?.map((date) => ({
    label: date.range,
    value: date.endYear,
  }));

  const buttonStyle = { ...buttonStyles, display: 'flex', marginLeft: '20px' };

  const disabledStyle = {
    ...buttonStyle,
    backgroundColor: '#a7a9ab',
    borderColor: '#a7a9ab',
    cursor: 'not-allowed',
  };

  if (!selectedYear) {
    buttonStyle.backgroundColor = '#a7a9ab';
    buttonStyle.borderColor = '#a7a9ab';
    buttonStyle.cursor = 'not-allowed';
  }

  const isDisabled = !selectedYear || (processingImport && processingImport.status !== 'SUCCEEDED');

  return (
    <div>
      {currentOrg && facilities?.length > 0 ? (
        <div style={questionStyles}>
          <Select
            id='selectPeriod'
            value={(yearOptions && _.find(yearOptions, { value: selectedYear?.value })) || null}
            onChange={selectYear}
            options={yearOptions}
            styles={customStyles}
            placeholder='Select Reporting Period to Import'
            menuPlacement='top'
          />
          <div
            id='importSage'
            className='btn btn-success'
            style={isDisabled ? disabledStyle : { marginLeft: '20px' }}
            disabled={isDisabled}
            onClick={() => processSageImport(selectedYear?.value)}>
            Import spend data from Sage
          </div>
        </div>
      ) : (
        <div style={questionStyles}>
          <Link to={`/facilities`} className='btn btn-success' id='addFacilityBtn'>
            Add a facility before importing spend data
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const currentOrg = state?.organisation?.details;

  return {
    years: state.years,
    selectedYear: state.selectedYear,
    processingImport: state.processingImport,
    selectedAccounts: state.selectedAccounts,
    facilities: state?.facility?.list,
    currentOrg,
  };
};

const mapDispatchToProps = (dispatch) => ({
  processSageImport: (year) => dispatch(ACTIONS.processSageImport(year)),
  getImportStatus: (importArn, type) => dispatch(ACTIONS.getImportStatus(importArn, type)),
  getFacilities: () => dispatch(FACILITY_ACTIONS.getFacilities()),
  selectYear: (year) => dispatch(ACTIONS.selectYear(year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportFromSage);
