import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { format } from 'date-fns';

const WasteRecyclable = ({ years, months, wasteByType, wasteTypes, getYears, yearTitles }) => {
  if (!wasteTypes && !wasteByType) return null;

  if (months.length > 0 || getYears.length > 0) {
    return (
      <div style={{ overflow: 'auto' }}>
        <h3>Waste by Type</h3>
        <table className={`table table-hover general-table`} style={{ textAlign: 'right' }}>
          <thead>
            <tr>
              <th width='20%'> Waste Type </th>

              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return (
                  <>
                    {getYears.map((year) => {
                      return (
                        <th
                          className={`toggle-absolute toggle-year-${year} toggle-month-${month}`}
                          width='15%'
                          style={{ textAlign: 'right' }}>
                          {format(new Date(year, month - 1), 'MMM yy')}
                        </th>
                      );
                    })}
                  </>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {wasteByType &&
              Object.keys(wasteByType).map((wasteType) => {
                return (
                  <tr>
                    <td style={{ textAlign: 'left' }}>{wasteType}</td>
                    {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                      return getYears.map((year) => {
                        return (
                          <td
                            className={`toggle-year-${year} toggle-month-${month}`}
                            width='15%'
                            style={{ textAlign: 'right' }}>
                            {(wasteByType[wasteType][`${year}-${month}`] &&
                              wasteByType[wasteType][`${year}-${month}`].toFixed(2)) ||
                              0}
                          </td>
                        );
                      });
                    })}
                  </tr>
                );
              })}

            <tr>
              <td style={{ textAlign: 'left', width: '10%' }}>% Recycled</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                const recycled =
                  wasteByType &&
                  _.sumBy(Object.keys(wasteByType), (wasteType) => {
                    const isRecycled =
                      wasteTypes &&
                      wasteTypes.find((item) => item.recyclable) &&
                      wasteTypes.find((item) => wasteType === item.recyclingType).recyclable === 1;
                    return isRecycled
                      ? parseFloat((wasteByType[wasteType][month] && wasteByType[wasteType][month].toFixed(2)) || 0)
                      : 0;
                  });
                const all =
                  wasteByType &&
                  _.sumBy(Object.keys(wasteByType), (wasteType) => {
                    return parseFloat((wasteByType[wasteType][month] && wasteByType[wasteType][month].toFixed(2)) || 0);
                  });
                const percentage = all ? ((recycled / all) * 100).toFixed(2) : 'N/A';

                return getYears.map((year) => {
                  return (
                    <td className={`toggle-year-${year} toggle-month-${month}`} width='15%' style={{ textAlign: 'right' }}>
                      {percentage}
                      {!isNaN(percentage) && `%`}
                    </td>
                  );
                });
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <h3>Waste by Type</h3>
      <table className={`table table-hover general-table`} style={{ textAlign: 'right' }}>
        <thead>
          <tr>
            <th width='20%'>Waste Type</th>

            {years.map((year) => (
              <th
                key={crypto.randomUUID()}
                className={`toggle-absolute toggle-year-${year}`}
                width='15%'
                style={{ textAlign: 'right' }}>
                {yearTitles[year]}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {wasteByType &&
            Object.keys(wasteByType).map((wasteType) => {
              return (
                <tr className={`${wasteType}-row`} key={crypto.randomUUID()}>
                  <td style={{ textAlign: 'left' }}>{wasteType}</td>
                  {years.map((year) => {
                    return (
                      <td
                        key={crypto.randomUUID()}
                        className={`toggle-year-${year}`}
                        width='15%'
                        style={{ textAlign: 'right' }}>
                        {wasteByType?.[wasteType]?.[year]?.toFixed(2) || 0}
                      </td>
                    );
                  })}
                </tr>
              );
            })}

          <tr className='recycled-row'>
            <td style={{ textAlign: 'left' }}>% Recycled</td>
            {years.map((year) => {
              const recycled = _.sumBy(Object.keys(wasteByType), (wasteType) => {
                const isRecycled =
                  wasteTypes &&
                  wasteTypes.find((item) => item.recyclable) &&
                  wasteTypes.find((item) => wasteType === item.recyclingType).recyclable === 1;

                return isRecycled
                  ? parseFloat((wasteByType[wasteType][year] && wasteByType[wasteType][year].toFixed(2)) || 0)
                  : 0;
              });

              const all = _.sumBy(Object.keys(wasteByType), (wasteType) => {
                return parseFloat((wasteByType[wasteType][year] && wasteByType[wasteType][year].toFixed(2)) || 0);
              });

              const percentage = all ? ((recycled / all) * 100).toFixed(2) : 'N/A';

              return (
                <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                  {percentage}
                  {!isNaN(percentage) && `%`}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { getYears } = ownProps;

  const { years, months, wasteByType } = state.consumption || {};
  const report = state?.report?.details;
  const yearTitles = {};
  years?.forEach((y) => {
    yearTitles[y] = report?.startDate?.split('-')[0] !== report?.endDate?.split('-')[0] ? y - 1 + ' - ' + y : y;
  });
  return {
    wasteTypes: state?.metric?.types?.waste,
    wasteByType,
    years: years || [],
    months: months || [],
    getYears: getYears || [],
    yearTitles,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WasteRecyclable);
