import cleanDeep from 'clean-deep';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as yup from 'yup';
import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';
import { Actions as ORGANISATIONACTIONS } from '../../store/actions/organisationActions';
import { Actions as PAYMENTACTIONS } from '../../store/actions/paymentActions';
import { AuthBtn } from '../../styledComponents/GlobalStyle';
import ChoosePaymentMethods from '../Payments/RedirectToCheckout';
import { itemToOptions } from '../utils/GlobalFunctions';
import ValidationMessage from '../utils/ValidationMessage';
import LogoSide from './LogoSide';

const validationSchema = yup.object().shape({
  address: yup.string().required('Address is required'),
  country: yup.string().required('Country is required'),
  industry: yup.string().required('Industry is required'),
  currency: yup.string().required('Reporting Currency is required'),
  vatNumber: yup
    .string()
    .notRequired()
    .matches(/^(GB)([0-9]{9}([0-9]{9})?|[A-Z]{2}[0-9]{9})/, {
      message: 'VAT Number must follow the following structure: GB123456789',
      excludeEmptyString: true,
    }),
});

const BillingDetails = ({
  branding,
  countryOptions,
  industryOptions,
  currentOrganisation,
  getWLAdminOrgs,
  loggingIn,
  registrationBillingDetails,
  setShowPaymentModal,
  toggleModal,
  renewOrgSub,
  listCountries,
  listIndustries,
  newUserSubscription,
  currency,
  listCurrencies,
}) => {
  const { signInFormBg, primaryHeaderText, primaryHeaderBackground } = branding.colors;
  const { headers } = branding.fonts?.[0].font;

  const [editedState, setEditedState] = useState({});
  const [subscribeCard, setSubscribeCard] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const updateField = (e) => {
    const newEditedState = { ...editedState };
    newEditedState[e.target.name] = e.target.value;
    setEditedState(newEditedState);
  };

  const billingDetails = {
    address: editedState?.['address'],
    vatNumber: editedState?.['vatNumber'],
    country: editedState && editedState?.['country']?.label,
    countryCode: editedState && editedState?.['country']?.code,
    countryId: editedState && editedState?.['country']?.value,
  };

  useEffect(() => {
    loggingIn(false);
    if (currentOrganisation) {
      getWLAdminOrgs();
      listCountries();
      listIndustries();
      listCurrencies();
    }
    //eslint-disable-next-line
  }, []);

  const onSubmit = async () => {
    await validationSchema
      .validate(
        {
          address: editedState?.['address'],
          country: editedState && editedState?.['country']?.label,
          vatNumber: editedState?.['vatNumber'],
          industry: editedState?.['industry'],
          currency: editedState?.['currency'],
        },
        { abortEarly: false }
      )
      .then(async () => {
        try {
          const { industry, vatNumber, countryId, currency } = editedState || {};
          registrationBillingDetails(billingDetails);
          newUserSubscription(false, cleanDeep({ industry, vatNumber, countryId, currency }));
          setSubscribeCard(true);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((error) => setValidationError(error.inner));
  };

  if (subscribeCard && renewOrgSub) {
    return <ChoosePaymentMethods setShowPaymentModal={setShowPaymentModal} renewOrgSub={renewOrgSub} />;
  } else if (subscribeCard) return <ChoosePaymentMethods />;

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,0.8)', overflow: 'auto' }}>
      <div
        className='login-body'
        style={{ height: '100vh', width: '100%', display: 'flex', background: renewOrgSub && 'transparent' }}>
        <div
          className='close-modal-btn close'
          style={{ display: renewOrgSub ? 'block' : 'none' }}
          onClick={() => {
            setShowPaymentModal(false);
            toggleModal(false, null);
          }}>
          <i className='fa fa-times' />
        </div>
        {!renewOrgSub && <LogoSide />}
        <div
          className={renewOrgSub ? '' : 'signInSide'}
          style={{ background: renewOrgSub ? 'transparent' : signInFormBg, width: renewOrgSub && '100%' }}>
          <div className={renewOrgSub ? 'form-signin' : 'confirmationSide'} style={{ width: '35vw' }}>
            {renewOrgSub ? (
              <h2
                className='form-signin-heading'
                style={{ background: primaryHeaderBackground, fontSize: '2em', fontWeight: '700' }}>
                Billing Details
              </h2>
            ) : (
              <h1 style={{ marginBottom: '1.5vh', color: primaryHeaderText }}> Billing Details</h1>
            )}
            <div className={renewOrgSub ? 'login-wrap' : ''}>
              <input
                type='text'
                className='form-control'
                name='address'
                placeholder='Address *'
                defaultValue=''
                maxLength='50'
                data-lpignore='true'
                style={{ marginBottom: '1.5vh', height: '5vh' }}
                onChange={updateField}
              />

              <ValidationMessage
                message={_.find(validationError, { path: 'address' })?.message}
                style={{ marginTop: '-10px' }}
              />

              <Select
                options={countryOptions}
                name='country'
                styles={{
                  placeholder: (provider) => ({ ...provider, fontSize: renewOrgSub ? '12px' : '14px' }),
                  control: (provider) => ({ ...provider, height: '5vh' }),
                  container: (x) => ({ ...x, marginBottom: '1.5vh' }),
                }}
                data-lpignore='true'
                placeholder={'Country *'}
                onChange={(selected) => {
                  const newEditedState = { ...editedState };
                  newEditedState['country'] = selected;
                  setEditedState(newEditedState);
                }}
              />

              <ValidationMessage message={_.find(validationError, { path: 'country' })?.message} />

              <Select
                options={currency}
                name='currency'
                styles={{
                  placeholder: (provider) => ({ ...provider, fontSize: renewOrgSub ? '12px' : '14px' }),
                  control: (provider) => ({ ...provider, height: '5vh' }),
                  container: (x) => ({ ...x, marginBottom: '1.5vh' }),
                }}
                data-lpignore='true'
                placeholder={'Reporting Currency *'}
                onChange={(selected) => {
                  const newEditedState = { ...editedState };
                  newEditedState['currency'] = selected?.value;
                  setEditedState(newEditedState);
                }}
              />

              <ValidationMessage message={_.find(validationError, { path: 'currency' })?.message} />

              <Select
                options={industryOptions}
                name='industry'
                styles={{
                  placeholder: (provider) => ({ ...provider, fontSize: renewOrgSub ? '12px' : '14px' }),
                  control: (provider) => ({ ...provider, height: '5vh' }),
                  container: (x) => ({ ...x, marginBottom: '1.5vh' }),
                }}
                style={{ marginBottom: '1.5vh', height: '5vh' }}
                data-lpignore='true'
                placeholder={'Industry *'}
                onChange={(selected) => {
                  const newEditedState = { ...editedState };
                  newEditedState['industry'] = selected?.value;
                  setEditedState(newEditedState);
                }}
              />

              <ValidationMessage message={_.find(validationError, { path: 'industry' })?.message} />

              <input
                id='register-email'
                type='text'
                className='form-control'
                name='vatNumber'
                data-lpignore='true'
                placeholder='VAT/TAX number (if applicable)'
                defaultValue=''
                maxLength={11}
                style={{ marginBottom: '1.5vh', marginTop: '1.5vh', height: '5vh' }}
                onChange={updateField}
              />

              <ValidationMessage message={_.find(validationError, { path: 'vatNumber' })?.message} />

              <AuthBtn
                className='btn btn-lg btn-login btn-block'
                id='submit-billingDetails'
                style={{ fontFamily: headers, marginTop: '3vh', color: 'white' }}
                onClick={onSubmit}>
                Continue
              </AuthBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  listCountries: () => dispatch(ORGANISATIONACTIONS.listCountries()),
  listIndustries: () => dispatch(ORGANISATIONACTIONS.listIndustries()),
  listCurrencies: () => dispatch(ORGANISATIONACTIONS.listCurrencies()),
  registrationBillingDetails: (billingDetails) => dispatch(PAYMENTACTIONS.registrationBillingDetails(billingDetails)),
  loggingIn: (saving) => dispatch(AUTHENTICATION_ACTIONS.loggingIn(saving)),
  toggleModal: (status, item) => dispatch(ORGANISATIONACTIONS.toggleModal(status, item)),
  getWLAdminOrgs: () => dispatch(ORGANISATIONACTIONS.getWLAdminOrgs()),
  newUserSubscription: (user, organisation) => dispatch(AUTHENTICATION_ACTIONS.newUserSubscription(user, organisation)),
});

const mapStateToProps = (state) => {
  const { countries, industries, currencies } = state?.organisation || {};

  return {
    branding: state.branding,
    currentOrganisation: state.currentOrganisation,
    countryOptions: _.sortBy(
      countries?.map((item) => ({ label: item.name, code: item.countryAlpha2Code, value: item.id })),
      ['label']
    ),
    industryOptions: _.sortBy(industries?.map(itemToOptions), ['label']),
    currency: currencies
      ?.filter((x) => x.hideInUI !== true)
      .map((x) => ({ label: `${x.symbol} (${x.isoCode})`, value: x.id })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetails);
