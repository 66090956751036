import { Auth } from '@aws-amplify/auth';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import cleanDeep from 'clean-deep';
import { format } from 'date-fns';
import _, { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as yup from 'yup';
import '../UsageData/reactdatepicker.css';

import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';
import { Actions as ORGANISATIONACTIONS } from '../../store/actions/organisationActions';
import { Actions as PAYMENTACTIONS } from '../../store/actions/paymentActions';
import { AuthBtn } from '../../styledComponents/GlobalStyle';

import ValidationMessage from '../utils/ValidationMessage';
import BillingDetails from './BillingDetails';
import ConfirmEmail from './ConfirmEmail';
import LogoSide from './LogoSide';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const code = urlParams.get('referralCode');

const updateSubscriptionValidationSchema = yup.object().shape({
  orgName: yup.string().required('Company name is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email().required('Email is required'),
  softwareAgreementAccepted: yup
    .boolean()
    .oneOf([true], 'Accepting the Software License Agreement & Privacy Policy is required')
    .required(),
  price: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
    revenue: yup.number().required('Please select a revenue range'),
  }),
});

const RegisterPage = ({
  areOrgAgreementsAccepted,
  branding,
  confirmUserAgreement,
  createACContact,
  currentOrganisation,
  getStripeCoupon,
  gettingCoupon,
  isExistingUser,
  isWhiteLabelApp,
  loggingIn,
  newUserSubscription,
  orgDetails,
  processing,
  saveOrganisation,
  setShowPaymentModal,
  stripeCoupon,
  renewOrgSub,
  toggleModal,
  wlSettings,
  history,
  sendEmailToSalesTeam,
  howDidHearAboutUsOptions,
  getStripePricingTiers,
}) => {
  const { primaryColor, signInFormBg, primaryHeaderText, primaryHeaderBackground } = branding.colors || {};

  const [editedState, setEditedState] = useState({});
  const [price, setPrice] = useState({});
  const [stripePrices, setStripePrices] = useState({});
  const [annualPriceOptions, setAnnualPriceOptions] = useState([]);
  const [unlimitedPriceOptions, setUnlimitedPriceOptions] = useState([]);
  const [error, setError] = useState(null);
  const [showCupon, setShowCupon] = useState(false);
  const [confirmationEmail, setConfirmationEmail] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [annual, setAnnual] = useState(true); //Set to true since unlimited has been hidden
  const [unlimited, setUnlimited] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [softwareAgreementAccepted, setSoftwareAgreementAccepted] = useState(false);
  const [showSLA, setShowSLA] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showBillingDetails, setShowBillingDetails] = useState(false);
  const [_50MUser, set_50MUser] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const alertMessage =
    'Are you sure this is the correct financial year end for your organisation? ' +
    'Changing reporting year end date after entering activity data requires a full recalculation and can incur a support fee. ' +
    'N.B. The app automatically generates a calendar year report in addition to your FY report.';

  // Set Default Values
  useEffect(() => {
    // Load Initial Data
    (async () => {
      // Show loader while fetching pricing tiers
      setLoader(true);
      const { response: { pricingTiers, stripePrices } = {} } = (await getStripePricingTiers()) || {};
      setStripePrices(stripePrices);
      if (Object.keys(pricingTiers).length > 0) {
        setUnlimitedPriceOptions(pricingTiers?.['Unlimited Plan'] || false);
        setAnnualPriceOptions(pricingTiers?.['Annual Plan']);
        setLoader(false);
      }
    })();
    if (isWhiteLabelApp) {
      setUnlimited(false);
      setAnnual(true);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (areOrgAgreementsAccepted) setSoftwareAgreementAccepted(true);
    else setSoftwareAgreementAccepted(false);
  }, [areOrgAgreementsAccepted]);

  useEffect(() => {
    // Getting orgAgreements under profile details, and prepoluate inputs with orgDetails
    // For update subscriptions - existing users
    async function getOrgAgreements() {
      if (currentOrganisation && isExistingUser) {
        editedState['firstName'] = isExistingUser.firstName;
        editedState['lastName'] = isExistingUser.lastName;
        editedState['email'] = isExistingUser.email;
        editedState['orgName'] = isExistingUser.orgName;
      }
    }
    getOrgAgreements();
    //eslint-disable-next-line
  }, [currentOrganisation]);

  const updateField = (e) => {
    const newEditedState = { ...editedState };
    if (e.name === 'phoneNumber') newEditedState[e.name] = e.value || null;
    else if (e.name === 'HOW_DID_YOU_HEAR_ABOUT_US') newEditedState[e.name] = e.value || null;
    else newEditedState[e.target.name] = e.target.value;
    setEditedState(newEditedState);
  };

  const user = {
    firstName: editedState['firstName'],
    lastName: editedState['lastName'],
    email: editedState['email'],
  };

  const organisation = {
    name: editedState['orgName'],
    revenue: price && price.revenue,
    subscriptionPlan: unlimited ? 'Unlimited' : 'Annual',
    revenueRange: price && price.label,
    subscriptionPrice: price && price.value,
    stripeCoupon: stripeCoupon?.id?.trim() || null,
    stripePriceId: annual ? stripePrices?.['Annual Plan'] : stripePrices?.['Unlimited Plan'],
    referralCode: code || orgDetails?.referralCode,
    reportingDate: editedState['reportingDate'],
    reportingYearEndDay: editedState['reportingYearEndDay'],
    reportingYearEndMonth: editedState['reportingYearEndMonth'],
  };

  const signupValidationSchema = yup.object().shape({
    orgName: yup.string().required('Company name is required'),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    phoneNumber: yup.string().required('Phone number is required'),
    jobTitle: yup.string().required('Job Title is required'),
    price: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
      revenue: yup.number().required('Please select a revenue range'),
    }),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])\S{8,}/,
        'Password must contain 8 characters, one uppercase, one lowercase,one number and one special case character'
      ),
    reportingYearEndDay: yup.string().required('Reporting year end is required'),
    howDidYouHearAboutUs: yup.string().when('editedState', {
      is: () => !isWhiteLabelApp && !editedState?.['HOW_DID_YOU_HEAR_ABOUT_US'],
      then: yup.string().required('Please Select...'),
      otherwise: yup.string().notRequired(),
    }),
    howDidYouHearAboutUsOthers: yup.string().when('howDidYouHearAboutUs', {
      is: (val) => !isWhiteLabelApp && (val === '115' || val === '10'),
      then: yup.string().test('isEmptyString', 'Please Type...', (value) => value.trim() !== ''),
      otherwise: yup.string().notRequired(),
    }),
  });

  const totalPriceWithDiscount =
    stripeCoupon?.percent_off && price.value
      ? (price.value - price.value * (stripeCoupon.percent_off / 100)).toFixed(2)
      : '0';

  const handleCoupon = debounce(async (coupon) => await getStripeCoupon(coupon?.trim()), 1000);

  // This is to change the price state value, when we change unlimited to annual (viceversa)
  const annualActualPrice =
    annualPriceOptions && price && annualPriceOptions?.find((priceOption) => priceOption.label === price.label);
  const unlimitedActualPrice =
    unlimitedPriceOptions && price && unlimitedPriceOptions?.find((priceOption) => priceOption.label === price.label);

  const errorsToDisplay = {};

  validationError?.forEach((error) => {
    if (error.path === 'password' && !(error.type === 'min' || error.type === 'matches')) {
      errorsToDisplay['password'] = error.message;
    } else if (error.type === 'min') {
      errorsToDisplay['min'] = error.message;
    } else if (error.type === 'matches') {
      errorsToDisplay['matches'] = error.message;
    } else if (error.path === 'softwareAgreementAccepted') {
      errorsToDisplay['softwareAgreementAccepted'] = error.message;
    }
  });

  const signUp = async () => {
    await signupValidationSchema
      .validate(
        {
          orgName: editedState['orgName'],
          firstName: editedState['firstName'],
          lastName: editedState['lastName'],
          email: editedState['email'],
          phoneNumber: editedState['phoneNumber'],
          password: editedState['password'],
          jobTitle: editedState['jobTitle'],
          reportingYearEndDay: editedState['reportingYearEndDay'],
          price: price,
          howDidYouHearAboutUs: editedState['HOW_DID_YOU_HEAR_ABOUT_US'],
          howDidYouHearAboutUsOthers: editedState['HOW_DID_YOU_HEAR_ABOUT_US_OTHER'],
        },
        { abortEarly: false }
      )
      .then(async () => {
        try {
          let attributes;
          attributes = {
            given_name: editedState['firstName'],
            family_name: editedState['lastName'],
            phone_number: `+${editedState['phoneNumber']}`,
            'custom:companyName': editedState['orgName'],
            'custom:priceId': annual ? stripePrices?.['Annual Plan'] : stripePrices?.['Unlimited Plan'],
            'custom:subscriptionPlan': unlimited ? 'Unlimited' : 'Annual',
            'custom:revenueRange': price.label,
            'custom:subscriptionPrice': String(price.value),
            'custom:organisationRevenue': String(price.revenue),
            'custom:coupon': stripeCoupon?.id?.trim(),
          };
          if (isWhiteLabelApp) {
            // Set WL attribute that triggers different confirmation code message
            attributes = { ...attributes, 'custom:isWhitelabel': wlSettings.whiteLabelId };
          }
          loggingIn(true);
          await Auth.signUp({ username: editedState['email'], password: editedState['password'], attributes });
          newUserSubscription(cleanDeep(user), { ...cleanDeep(organisation) });
          if (!isWhiteLabelApp)
            // Don't create AC contact for WL users
            await createACContact(
              cleanDeep({
                ...user,
                phoneNumber: editedState['phoneNumber'],
                howDidYouHearAboutUs: editedState['HOW_DID_YOU_HEAR_ABOUT_US'],
                howDidYouHearAboutUsOthers: editedState['HOW_DID_YOU_HEAR_ABOUT_US_OTHER'],
                jobTitle: editedState['jobTitle'],
              })
            );
          setConfirmationEmail(true);
          loggingIn(false);
        } catch (error) {
          loggingIn(false);
          let err = null;
          !error.message ? (err = { message: error }) : (err = error);
          setError(err);
        }
      })
      .catch((error) => {
        if (price?.stopUserRegistration) {
          set_50MUser(true);
          sendEmailToSalesTeam(organisation, user);
          return;
        }
        setValidationError(error.inner);
      });
  };

  const updateSubscription = async () => {
    await updateSubscriptionValidationSchema
      .validate(
        {
          orgName: editedState['orgName'],
          firstName: editedState['firstName'],
          lastName: editedState['lastName'],
          email: editedState['email'],
          softwareAgreementAccepted: softwareAgreementAccepted,
          price: price,
        },
        { abortEarly: false }
      )
      .then(async () => {
        try {
          newUserSubscription(cleanDeep(user), { ...cleanDeep(organisation) });
          if (!areOrgAgreementsAccepted) await confirmUserAgreement(true); //confirming T&Cs
          await saveOrganisation({ ...orgDetails, ...organisation, hasSubscription: false });
          setShowBillingDetails(true);
        } catch (error) {
          setError(error);
        }
      })
      .catch((error) => {
        if (price?.stopUserRegistration) {
          set_50MUser(true);
          sendEmailToSalesTeam(organisation, user);
          return;
        }
        setValidationError(error.inner);
      });
  };

  if (confirmationEmail) {
    return (
      <ConfirmEmail
        username={editedState['email']}
        password={editedState['password']}
        user={user}
        // First time user is creating an org, this org should be parentOrg
        organisation={{ ...organisation, parentOrganisation: true }}
      />
    );
  }

  if (showBillingDetails && renewOrgSub) {
    return (
      <BillingDetails
        user={user}
        organisation={organisation}
        setShowPaymentModal={setShowPaymentModal}
        renewOrgSub={renewOrgSub}
      />
    );
  }

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,0.8)' }}>
      <div
        className='login-body'
        style={{ height: '100vh', width: '100%', display: 'flex', background: renewOrgSub && 'transparent' }}>
        <div
          className='close-modal-btn close'
          style={{ display: renewOrgSub ? 'block' : 'none' }}
          onClick={() => {
            setShowPaymentModal(false);
            toggleModal(false, null);
            history?.push('/organisations');
          }}>
          <i className='fa fa-times' />
        </div>
        {!renewOrgSub && <LogoSide />}

        <div
          className={'signInSide'}
          style={{ background: renewOrgSub ? 'transparent' : signInFormBg, width: renewOrgSub && '100vw' }}>
          <div
            className={renewOrgSub ? 'form-signin' : 'signInFields'}
            style={{ width: '35vw', height: renewOrgSub && _50MUser ? '50vh' : '' }}>
            {_50MUser && !renewOrgSub ? (
              <div>
                <p style={{ color: 'white', fontSize: '25px', fontWeight: '600' }}>
                  The Compare Your Footprint team will be in touch shortly. You can also book a time to finalise your order
                  at the following
                  <a href='https://calendly.com/jessica-owrid/30min'> link</a>.
                </p>
              </div>
            ) : (
              <div>
                {renewOrgSub ? (
                  <h2
                    className='form-signin-heading'
                    style={{ background: primaryHeaderBackground, fontSize: '2em', fontWeight: '700' }}>
                    Billing Details
                  </h2>
                ) : (
                  <h1 style={{ marginBottom: '1.5vh', color: primaryHeaderText }}> Register </h1>
                )}

                <div style={{ display: 'block', minHeight: 'auto', margin: renewOrgSub ? '1.5vh 10px' : '1.5vh auto' }}>
                  <div className='registerForm'>
                    {_50MUser && renewOrgSub && (
                      <div style={{ position: 'relative', top: 100 }}>
                        <p style={{ color: 'black', fontSize: '14px', fontWeight: '600' }}>
                          The Compare Your Footprint team will be in touch shortly. You can also book a time to finalise your
                          order at the following
                          <a href='https://calendly.com/waltercyf/30min'> link</a>.
                        </p>
                      </div>
                    )}
                    <div className='registerForm-inputs' style={{ display: _50MUser ? 'none' : 'block' }}>
                      <div className='col-xs-12 alert-danger alert' style={{ display: error ? 'block' : 'none' }}>
                        <h5 style={{ textAlign: 'left' }}>Error: {error?.log || error?.message}</h5>
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        name='orgName'
                        placeholder='Company name *'
                        defaultValue={editedState && editedState['orgName']}
                        maxLength='50'
                        style={{ marginBottom: '1.5vh', height: '5vh' }}
                        onChange={(e) => updateField(e)}
                        required
                      />

                      <ValidationMessage message={_.find(validationError, { path: 'orgName' })?.message} />
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                          <input
                            id='register-first-name'
                            type='text'
                            className='form-control'
                            name='firstName'
                            placeholder='First name *'
                            defaultValue={editedState && editedState['firstName']}
                            style={{ marginBottom: '1.5vh', height: '5vh' }}
                            maxLength='50'
                            onChange={(e) => updateField(e)}
                            required
                          />
                          <ValidationMessage message={_.find(validationError, { path: 'firstName' })?.message} />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                          <input
                            id='register-last-name'
                            type='text'
                            className='form-control'
                            name='lastName'
                            placeholder='Last name *'
                            defaultValue={editedState && editedState['lastName']}
                            style={{ marginBottom: '1.5vh', height: '5vh' }}
                            maxLength='50'
                            onChange={(e) => updateField(e)}
                            required
                          />

                          <ValidationMessage message={_.find(validationError, { path: 'lastName' })?.message} />
                        </div>
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div
                          style={{ width: renewOrgSub ? '100%' : '70%', flexDirection: 'column' }}
                          className='input-with-icon-block'>
                          <input
                            id='register-email'
                            type='text'
                            className='form-control'
                            name='email'
                            placeholder='Email address *'
                            style={{ marginBottom: '1.5vh', height: '5vh' }}
                            defaultValue={editedState && editedState['email']}
                            onChange={(e) => updateField(e)}
                            required
                          />
                          {!renewOrgSub && (
                            <Tooltip
                              title={
                                <p style={{ fontSize: 12 }}>
                                  Email addresses are case sensitive throughout the application. Please enter your email
                                  exactly as you wish to use it going forward. You will receive a confirmation of
                                  registration when this process is completed, please retain this email for reference to your
                                  registered email ID.
                                </p>
                              }
                              arrow
                              placement='left'
                              componentsProps={{
                                tooltip: { sx: { backgroundColor: '#60b12f' } },
                                arrow: { sx: { color: '#60b12f' } },
                              }}>
                              <i className='fa fa-info-circle input-with-icon-block__icon' />
                            </Tooltip>
                          )}

                          <ValidationMessage message={_.find(validationError, { path: 'email' })?.message} />
                        </div>

                        <div
                          style={{
                            display: renewOrgSub ? 'none' : 'flex',
                            flexDirection: 'column',
                            width: '43%',
                            marginLeft: 10,
                          }}>
                          <DatePicker
                            id='reporting-year-end-datepicker-signup'
                            showMonthDropdown
                            dateFormatCalendar='LLL'
                            openToDate={new Date(new Date().getFullYear(), 11, 31)}
                            dateFormat='dd/MM'
                            placeholderText='Reporting Year End *'
                            selected={editedState && editedState.reportingDate}
                            onChange={(date) => {
                              if (!date) return;
                              setAlertStatus(true);
                              const newEditedState = { ...editedState };
                              newEditedState['reportingDate'] = date;
                              newEditedState['reportingYearEndDay'] = format(date, 'dd');
                              newEditedState['reportingYearEndMonth'] = format(date, 'MM');
                              setEditedState(newEditedState);
                            }}
                            className='form-control'
                          />
                          <ValidationMessage message={_.find(validationError, { path: 'reportingYearEndDay' })?.message} />
                        </div>
                      </div>

                      <div style={{ display: renewOrgSub ? 'none' : 'flex', marginBottom: '10px' }}>
                        {alertStatus && (
                          <Alert severity='warning' onClose={() => setAlertStatus(false)} style={{ marginBottom: '3px' }}>
                            <span style={{ color: 'red' }}>
                              <b>{alertMessage}</b>
                            </span>
                          </Alert>
                        )}
                      </div>

                      <div>
                        <input
                          type='text'
                          className='form-control'
                          name='jobTitle'
                          placeholder='Job Title*'
                          style={{ marginBottom: '1.5vh', height: '5vh', display: renewOrgSub ? 'none' : 'flex' }}
                          defaultValue={editedState && editedState['jobTitle']}
                          onChange={(e) => updateField(e)}
                          required
                        />
                      </div>
                      <ValidationMessage message={_.find(validationError, { path: 'jobTitle' })?.message} />
                      <div
                        className='phoneContainer input-with-icon-block'
                        style={{
                          display: renewOrgSub ? 'none' : 'flex',
                          alignItems: 'center',
                          height: '5vh',
                          marginBottom: '1.5vh',
                        }}>
                        <PhoneInput
                          id='register-phone'
                          country={'gb'}
                          inputProps={{ name: 'phoneNumber' }}
                          value={editedState && editedState['phoneNumber']}
                          onChange={(phone) => updateField({ name: 'phoneNumber', value: phone })}
                          placeholder='Phone Number *'
                          inputClass='inputClass'
                        />

                        <Tooltip
                          title={<p style={{ fontSize: 12 }}> We will only contact you about your subscription. </p>}
                          placement='left'
                          arrow
                          componentsProps={{
                            tooltip: { sx: { backgroundColor: '#60b12f' } },
                            arrow: { sx: { color: '#60b12f' } },
                          }}>
                          <i style={{ cursor: 'pointer' }} className='fa fa-info-circle input-with-icon-block__icon' />
                        </Tooltip>
                      </div>
                      <ValidationMessage message={_.find(validationError, { path: 'phoneNumber' })?.message} />

                      <div className='row'>
                        <div
                          className='col-xs-10'
                          style={{
                            display: renewOrgSub || isWhiteLabelApp ? 'none' : showInput ? 'flex' : 'block',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}>
                          <div className='form-group' style={{ width: showInput ? '30%' : '100%' }}>
                            <Select
                              options={howDidHearAboutUsOptions}
                              placeholder={'How did you hear about us?'}
                              styles={{
                                placeholder: (provider) => ({ ...provider, fontSize: renewOrgSub ? '12px' : '14px' }),
                                control: (control) => ({ ...control, height: '5vh' }),
                              }}
                              onChange={(e) => {
                                if (e.showInput) {
                                  setShowInput(true);
                                  editedState['HOW_DID_YOU_HEAR_ABOUT_US_OTHER'] = '';
                                } else if (showInput) {
                                  setShowInput(false);
                                  delete editedState?.['HOW_DID_YOU_HEAR_ABOUT_US_OTHER'];
                                }
                                updateField({ name: 'HOW_DID_YOU_HEAR_ABOUT_US', value: e.value || null });
                              }}
                            />
                          </div>
                          {showInput && (
                            <div className='form-group' style={{ width: '60%' }}>
                              <input
                                type='text'
                                className='form-control'
                                name='HOW_DID_YOU_HEAR_ABOUT_US_OTHER'
                                placeholder='Please specify *'
                                defaultValue={editedState && editedState['HOW_DID_YOU_HEAR_ABOUT_US_OTHER']}
                                maxLength='50'
                                onChange={(e) => updateField(e)}
                                style={{ height: '5vh' }}
                                required
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <ValidationMessage
                        message={
                          (validationError && _.find(validationError, { path: 'howDidYouHearAboutUs' })?.message) ||
                          _.find(validationError, { path: 'howDidYouHearAboutUsOthers' })?.message
                        }
                      />
                      <div style={{ display: renewOrgSub ? 'none' : 'block' }}>
                        <i className='fa fa-eye show-password' onClick={() => setShowPassword(!showPassword)} />

                        <input
                          id='register-password'
                          style={{ marginBottom: '1.5vh', height: '5vh' }}
                          type={showPassword ? 'text' : 'password'}
                          className='form-control'
                          name='password'
                          placeholder='Enter Password *'
                          onChange={(e) => updateField(e)}
                          required
                        />
                      </div>
                      <ValidationMessage
                        message={errorsToDisplay.password || errorsToDisplay.matches || errorsToDisplay.min}
                        style={{ marginTop: '-10px' }}
                      />
                      <div style={{ display: showCupon ? 'block' : 'none', position: 'relative' }}>
                        <i
                          className='fa fa-spin fa-spinner'
                          style={{
                            position: 'absolute',
                            zIndex: 10,
                            top: 12,
                            right: 5,
                            display: gettingCoupon ? 'block' : 'none',
                          }}
                        />

                        <input
                          id='coupon_input'
                          type='text'
                          className='form-control'
                          name='coupon'
                          placeholder='Discount coupon'
                          defaultValue=''
                          onChange={(e) => handleCoupon(e.target.value)}
                          style={{ marginBottom: '1.5vh', height: '5vh' }}
                        />

                        <ValidationMessage
                          message={stripeCoupon?.id ? 'Code Verified' : stripeCoupon?.message}
                          style={{ marginTop: '-10px', marginLeft: 3 }}
                        />
                      </div>
                      {(annualPriceOptions?.length > 0 || unlimitedPriceOptions?.length > 0) && (
                        <div className='row'>
                          <div className='col-xs-10' style={{ width: '100%' }}>
                            <div className='form-group'>
                              <Select
                                maxMenuHeight={200}
                                options={unlimited ? unlimitedPriceOptions : annualPriceOptions}
                                placeholder={'Select Your Revenue Range'}
                                styles={{
                                  placeholder: (provider) => ({ ...provider, fontSize: renewOrgSub ? '12px' : '14px' }),
                                  control: (control) => ({ ...control, height: '5vh' }),
                                  menu: (control) => ({ ...control, zIndex: '10' }),
                                }}
                                onChange={(price) => {
                                  //Annual Plan
                                  if (annual) setPrice(price);
                                  //Monthly Plan
                                  else setPrice(price);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <ValidationMessage message={_.find(validationError, { path: 'price.revenue' })?.message} />

                      <div style={{ display: isWhiteLabelApp ? 'none' : 'none', justifyContent: 'flex-end' }}>
                        <button
                          style={{
                            textDecoration: 'none',
                            fontSize: '12px',
                            cursor: 'pointer',
                            border: 0,
                            background: 'inherit',
                            color: 'black',
                          }}
                          onClick={() => {
                            if (annual) {
                              setAnnual(!annual);
                              setUnlimited(!unlimited);
                              setPrice(unlimitedActualPrice);
                            } else {
                              setUnlimited(!unlimited);
                              setAnnual(!annual);
                              setPrice(annualActualPrice);
                            }
                          }}>
                          {<span>{`Switch to ${annual ? 'Unlimited' : 'Annual'}`}</span>}
                        </button>
                      </div>

                      <div className='price-box' style={{ marginTop: '10px' }}>
                        {price?.stopUserRegistration ? (
                          <span style={{ color: primaryColor, display: 'inline-block', marginBottom: 10 }}>
                            Price on request...
                          </span>
                        ) : (
                          <>
                            <span className='currency'>£</span>
                            <span className='price'>
                              {stripeCoupon?.percent_off ? price && totalPriceWithDiscount : price && price.value}
                            </span>
                            <span className='price-note'> + VAT</span>
                            <span className='price-note'> {unlimited ? '/ mo' : '/ yr'}</span>
                          </>
                        )}
                      </div>

                      <AuthBtn
                        style={{ color: 'white' }}
                        disabled={loader}
                        className='btn btn-lg btn-login btn-block onceonly'
                        id='register-pay-btn'
                        onClick={renewOrgSub ? updateSubscription : signUp}>
                        {(processing || loader) && (
                          <i className='fa fa-spin fa-spinner icon-spinner' id='register-spinner' />
                        )}
                        {loader ? 'Fetching Pricing Tiers' : renewOrgSub ? 'Submit' : 'Register and pay'}
                      </AuthBtn>
                      <div
                        style={{
                          display: renewOrgSub ? (!areOrgAgreementsAccepted ? 'block' : 'none') : 'none',
                          fontSize: '10px',
                        }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            type='checkbox'
                            style={{ marginBottom: 10 }}
                            onChange={() => setSoftwareAgreementAccepted(!softwareAgreementAccepted)}
                          />
                          <label style={{ fontWeight: 400, marginLeft: 5 }}>
                            By clicking you are accepting to our{' '}
                            <span style={{ cursor: 'pointer' }} onClick={() => setShowSLA(!showSLA)}>
                              Software License Agreement{' '}
                            </span>
                            and our{' '}
                            <span style={{ cursor: 'pointer' }} onClick={() => setShowPrivacyPolicy(!showPrivacyPolicy)}>
                              Privacy Policy
                            </span>
                            .
                          </label>
                        </div>
                      </div>

                      <ValidationMessage message={_.find(validationError, { path: 'softwareAgreementAccepted' })?.message} />

                      <div style={{ display: 'flex', marginTop: '1.5vh', justifyContent: 'space-between' }}>
                        <button
                          style={{
                            color: primaryColor,
                            textDecoration: 'none',
                            cursor: 'pointer',
                            border: 0,
                            background: 'inherit',
                          }}
                          id='got-a-coupon'
                          onClick={() => setShowCupon(!showCupon)}>
                          {showCupon ? 'Hide coupon' : 'Got coupon?'}
                        </button>
                        {!renewOrgSub && (
                          <div>
                            <span style={{ color: 'white' }}>Already Registered?</span>
                            <a href='/' style={{ color: primaryColor }}>
                              &nbsp;Login
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  newUserSubscription: (user, organisation) => dispatch(AUTHENTICATION_ACTIONS.newUserSubscription(user, organisation)),
  loggingIn: (saving) => dispatch(AUTHENTICATION_ACTIONS.loggingIn(saving)),
  createACContact: (user, organisationName) => dispatch(AUTHENTICATION_ACTIONS.createACContact(user, organisationName)),
  confirmUserAgreement: (incOrg) => dispatch(AUTHENTICATION_ACTIONS.confirmUserAgreement(incOrg)),
  sendEmailToSalesTeam: (org, user) => dispatch(AUTHENTICATION_ACTIONS.sendEmailToSalesTeam(org, user)),
  getStripeCoupon: (coupon) => dispatch(PAYMENTACTIONS.getStripeCoupon(coupon)),
  getStripePricingTiers: () => dispatch(PAYMENTACTIONS.getStripePricingTiers()),
  toggleModal: (status, item) => dispatch(ORGANISATIONACTIONS.toggleModal(status, item)),
  saveOrganisation: (organisation) => dispatch(ORGANISATIONACTIONS.saveOrganisation(organisation)),
});

const mapStateToProps = (state) => {
  const { firstName, lastName, email, legalAgreements } = state.profile?.details || {};
  const { revenueRange, subscriptionPlan, name } = state.organisation?.details || {};
  const { whiteLabelId } = state.whiteLabelAdmin;
  const howDidHearAboutUsOptions = _.sortBy(
    [
      { name: 'Website search', id: '5' },
      { name: 'I am an existing client', id: '114' },
      { name: 'Word of mouth / Referral / Other (Specify in the Other box)', id: '10' },
      { name: 'B Corp', id: '110' },
      { name: 'Event / Networking', id: '6' },
      { name: 'Podcast', id: '108' },
      { name: 'Press', id: '109' },
      { name: 'Social media', id: '9' },
      { name: 'Email', id: '116' },
      { name: 'Webinar', id: '8' },
      { name: 'Website search', id: '5' },
    ].map((x) => ({
      label: x.name,
      value: x.id,
      showInput: x.name.includes('Other box') ? true : false,
    })),
    ['label']
  );

  const existingUser = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    revenueRange: revenueRange,
    subscriptionPlan: subscriptionPlan,
    orgName: name,
  };

  return {
    areOrgAgreementsAccepted: legalAgreements?.softwareLicenseAgreementConfirmed,
    branding: state.branding,
    currentOrganisation: state.currentOrganisation,
    gettingCoupon: state.payment.gettingCoupon,
    isExistingUser: state.organisation?.details ? existingUser : null,
    isWhiteLabelApp: whiteLabelId !== '0',
    orgDetails: state.organisation?.details,
    processing: state.authentication.processing,
    stripeCoupon: state.payment.stripeCoupon,
    renewOrgSub: !window.location.pathname.includes('register'),
    wlSettings: state.whiteLabelAdmin,
    howDidHearAboutUsOptions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
