import React from 'react';
import { connect } from 'react-redux';

import { format } from 'date-fns';
import { monthsShort } from 'moment';

const ElectricityRenewable = ({ years, electricityRenewable, months, getYears, yearTitles }) => {
  if (!electricityRenewable) return null;

  if (months.length > 0) {
    return (
      <div style={{ overflow: 'auto' }}>
        <h3>Electricity by Source</h3>
        <table
          id='electricty-by-renewables-table'
          className={`table table-hover general-table`}
          style={{ textAlign: 'right' }}>
          <thead>
            <tr>
              <th width='20%'> Electricity Consumption </th>

              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  return (
                    <th className={`toggle-year-${year} toggle-month-${month}`} width='15%' style={{ textAlign: 'right' }}>
                      {format(new Date(year, month - 1), 'MMM yy')}
                    </th>
                  );
                });
              })}
            </tr>
          </thead>

          <tbody>
            <tr>
              <td style={{ textAlign: 'left' }}>Purchased Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  return (
                    <td className={`toggle-year-${year} toggle-month-${month}`} width='15%' style={{ textAlign: 'right' }}>
                      {(electricityRenewable[`${year}-${month}`] &&
                        electricityRenewable[`${year}-${month}`].renewable &&
                        `${electricityRenewable[`${year}-${month}`].renewable.toFixed(2)} kWh`) ||
                        `0 `}
                    </td>
                  );
                });
              })}
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>Self-Generated Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  return (
                    <td className={`toggle-year-${year} toggle-month-${month}`} width='15%' style={{ textAlign: 'right' }}>
                      {(electricityRenewable[`${year}-${month}`] &&
                        electricityRenewable[`${year}-${month}`].generated &&
                        `${electricityRenewable[`${year}-${month}`].generated.toFixed(2)} kWh`) ||
                        `0 `}
                    </td>
                  );
                });
              })}
            </tr>

            <tr>
              <td style={{ textAlign: 'left' }}>Total Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  const generated =
                    (electricityRenewable[`${year}-${month}`] && electricityRenewable[`${year}-${month}`].generated) || 0;
                  const renewable =
                    (electricityRenewable[`${year}-${month}`] && electricityRenewable[`${year}-${month}`].renewable) || 0;
                  const total = generated + renewable || 0;
                  return (
                    <td className={`toggle-year-${year} toggle-month-${month}`} width='15%' style={{ textAlign: 'right' }}>
                      {`${total.toFixed(2)} kWh` || `0 kWh`}
                    </td>
                  );
                });
              })}
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>Total Electricity</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  return (
                    <td className={`toggle-year-${year} toggle-month-${month}`} width='15%' style={{ textAlign: 'right' }}>
                      {(electricityRenewable[`${year}-${month}`] &&
                        electricityRenewable[`${year}-${month}`].total &&
                        `${electricityRenewable[`${year}-${month}`].total.toFixed(2)} kWh`) ||
                        `0 kWh`}
                    </td>
                  );
                });
              })}
            </tr>

            <tr>
              <td style={{ textAlign: 'left' }}>% Purchased Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  const renewable =
                    (electricityRenewable[`${year}-${month}`] && electricityRenewable[`${year}-${month}`].renewable) || 0;
                  const total =
                    (electricityRenewable[`${year}-${month}`] && electricityRenewable[`${year}-${month}`].total) || 0;

                  const percentage = total ? ((renewable / total) * 100).toFixed(2) : 'N/A';
                  return (
                    <td
                      className={`toggle-year-${year} toggle-month-${monthsShort}`}
                      width='15%'
                      style={{ textAlign: 'right' }}>
                      {percentage}
                      {!isNaN(percentage) && `%`}
                    </td>
                  );
                });
              })}
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>% Self-Generated Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  const renewable =
                    (electricityRenewable[`${year}-${month}`] && electricityRenewable[`${year}-${month}`].generated) || 0;
                  const total =
                    (electricityRenewable[`${year}-${month}`] && electricityRenewable[`${year}-${month}`].total) || 0;

                  const percentage = total ? ((renewable / total) * 100).toFixed(2) : 'N/A';
                  return (
                    <td
                      className={`toggle-year-${year} toggle-month-${monthsShort}`}
                      width='15%'
                      style={{ textAlign: 'right' }}>
                      {percentage}
                      {!isNaN(percentage) && `%`}
                    </td>
                  );
                });
              })}
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>% Total Renewable</td>
              {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  const renewable =
                    (electricityRenewable[`${year}-${month}`] && electricityRenewable[`${year}-${month}`].renewable) || 0;
                  const generated =
                    (electricityRenewable[`${year}-${month}`] && electricityRenewable[`${year}-${month}`].generated) || 0;
                  const total =
                    (electricityRenewable[`${year}-${month}`] && electricityRenewable[`${year}-${month}`].total) || 0;

                  const percentage = total ? (((generated + renewable) / total) * 100).toFixed(2) : 'N/A';
                  return (
                    <td
                      className={`toggle-year-${year} toggle-month-${monthsShort}`}
                      width='15%'
                      style={{ textAlign: 'right' }}>
                      {percentage}
                      {!isNaN(percentage) && `%`}
                    </td>
                  );
                });
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <h3>Electricity by Source</h3>
      <table className={`table table-hover general-table`} style={{ textAlign: 'right' }}>
        <thead>
          <tr>
            <th width='20%'>Electricity Consumption</th>

            {years.map((year) => (
              <th key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                {yearTitles[year]}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr className='electricity-renewable-row'>
            <td style={{ textAlign: 'left' }}>Purchased Renewable</td>
            {years.map((year) => (
              <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                {`${electricityRenewable?.[year]?.renewable?.toFixed(2) || '0.00'} kWh` || `0.00 kWh`}
              </td>
            ))}
          </tr>
          <tr className='electricity--generated-renewable-row'>
            <td style={{ textAlign: 'left' }}>Self-Generated Renewable</td>
            {years.map((year) => (
              <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                {`${electricityRenewable?.[year]?.generated?.toFixed(2) || '0.00'} kWh` || `0.00 kWh`}
              </td>
            ))}
          </tr>
          <tr className='electricity-total-renewable-row'>
            <td style={{ textAlign: 'left' }}>Total Renewable</td>
            {years.map((year) => {
              const renewable = electricityRenewable?.[year]?.renewable || 0;
              const generated = electricityRenewable?.[year]?.generated || 0;
              const total = renewable + generated || 0;
              return (
                <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                  {`${total.toFixed(2) || '0.00'} kWh` || `0.00 kWh`}
                </td>
              );
            })}
          </tr>
          <tr className='electricity-total-row'>
            <td style={{ textAlign: 'left' }}>Total Electricity</td>
            {years.map((year) => (
              <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                {`${electricityRenewable?.[year]?.total?.toFixed(2) || '0.00'} kWh` || `0.00 kWh`}
              </td>
            ))}
          </tr>

          <tr className='percRenewable-row'>
            <td style={{ textAlign: 'left' }}>% Purchased Renewable</td>
            {years.map((year) => {
              const renewable = electricityRenewable?.[year]?.renewable || 0;
              const total = electricityRenewable?.[year]?.total || 0;

              const percentage = total ? ((renewable / total) * 100).toFixed(2) : 'N/A';

              return (
                <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                  {percentage}
                  {!isNaN(percentage) && `%`}
                </td>
              );
            })}
          </tr>

          <tr className='percGeneratedRenewable-row'>
            <td style={{ textAlign: 'left' }}>% Self-Generated Renewable</td>
            {years.map((year) => {
              const generated = electricityRenewable?.[year]?.generated || 0;
              const total = electricityRenewable?.[year]?.total || 0;

              const percentage = total ? ((generated / total) * 100).toFixed(2) : 'N/A';

              return (
                <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                  {percentage}
                  {!isNaN(percentage) && `%`}
                </td>
              );
            })}
          </tr>

          <tr className='percTotalRenewable-row'>
            <td style={{ textAlign: 'left' }}>% Total Renewable</td>
            {years.map((year) => {
              const renewable = electricityRenewable?.[year]?.renewable || 0;
              const generated = electricityRenewable?.[year]?.generated || 0;
              const total = electricityRenewable?.[year]?.total || 0;

              const percentage = total ? (((renewable + generated) / total) * 100).toFixed(2) : 'N/A';

              return (
                <td key={crypto.randomUUID()} className={`toggle-year-${year}`} width='15%' style={{ textAlign: 'right' }}>
                  {percentage}
                  {!isNaN(percentage) && `%`}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { getYears } = ownProps;

  const { years, electricityRenewable, months } = state.consumption || {};
  const report = state?.report?.details;
  const yearTitles = {};
  years?.forEach((y) => {
    yearTitles[y] = report?.startDate?.split('-')[0] !== report?.endDate?.split('-')[0] ? y - 1 + ' - ' + y : y;
  });
  return {
    electricityRenewable,
    years: years || [],
    months: months || [],
    getYears: getYears || [],
    yearTitles,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ElectricityRenewable);
