import LinearProgress from '@mui/material/LinearProgress';
import { addYears, compareAsc, differenceInCalendarDays, fromUnixTime, getTime } from 'date-fns';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions';
import { Actions as PAYMENTACTIONS } from '../../store/actions/paymentActions';
import { Actions } from '../../store/actions/reportActions';
import { AddButton } from '../../styledComponents/GlobalStyle';
import { checkReportingYearEndAndIndustry } from '../utils/GlobalFunctions';
import ReportRow from './ReportRow';

const ReportTable = ({
  list,
  showModal,
  listReports,
  currentOrganisation,
  branding,
  showLoader,
  addReportIssue,
  reportGeneration,
  toggleModal,
  enableReportGeneration,
  adminUser,
  ambassadorAdmin,
  stripeSubscriptionsFetched,
}) => {
  const { headers } = branding.fonts?.[0].font;

  const history = useHistory();

  useEffect(() => {
    listReports();
  }, [currentOrganisation, listReports]);

  if (list?.length > 0) {
    list?.sort((a, b) => {
      // Extract the year from the report names using slice
      const yearA = parseInt(a.name.includes('/') ? a.name.slice(5, 9) : a.name.slice(0, 4));
      const yearB = parseInt(b.name.slice(0, 4));

      // Compare the years in descending order (latest year first)
      if (yearA < yearB) return 1;
      else if (yearA > yearB) return -1;
      else return 0;
    });
  }

  if (showLoader) {
    return <LinearProgress color='inherit' />; //Loader
  }

  return (
    <div className='row' style={{ fontFamily: headers }}>
      <div className='col-lg-12'>
        <section className='panel'>
          <header className='panel-heading'>Your Reports</header>
          <div className='panel-body'>
            <table id='reports-table' className='table table-hover general-table'>
              <thead>
                <tr>
                  <th>Report</th>
                  <th>Period</th>
                  <th>Status</th>
                  <th>Last Updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  Array.isArray(list) &&
                  list.map((reportDetails, index) => <ReportRow key={index} reportDetails={reportDetails} />)}

                {!ambassadorAdmin && (
                  <tr>
                    <td colSpan='5'>
                      <AddButton
                        id='add-report-button'
                        className='btn btn-success'
                        disabled={enableReportGeneration === undefined || !stripeSubscriptionsFetched ? true : false}
                        onClick={() => {
                          // In case reports generation are disabled show warning modal
                          if (!reportGeneration && !adminUser) toggleModal(true, 'reports-disabled', null);
                          else if (addReportIssue) history.push(`organisations/${currentOrganisation}`);
                          else if (!enableReportGeneration) showModal(true);
                          else showModal();
                        }}>
                        {addReportIssue ? `${addReportIssue} to add a report` : 'Add a Report'}
                      </AddButton>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { consultantAdmin, whiteLabelAdmin, cyfAdmin, ambassadorAdmin } = state?.profile?.details || {};
  const { hasPaymentSystem } = state?.whiteLabelAdmin || {};
  const orgDetails = state?.organisation?.details;
  const addReportIssue = checkReportingYearEndAndIndustry(orgDetails);
  const { subscriptionPlan } = orgDetails || {};
  const reportList = state?.report?.list;
  const { stripeCustomerId, stripeSubscriptions } = state?.payment?.stripe || {};
  const directUser = !whiteLabelAdmin && !consultantAdmin && !cyfAdmin && hasPaymentSystem;
  const adminUser = whiteLabelAdmin || consultantAdmin || cyfAdmin;
  const hasAnYearPassed = (dateStart) => {
    let result = differenceInCalendarDays(new Date(), fromUnixTime(dateStart));
    if (result > 365) return true;
    else return false;
  };
  const isFirstDateAfterSecondDate = (firstDate, secondDate) => {
    const check = compareAsc(new Date(firstDate), fromUnixTime(secondDate));
    if (check === 1) return true;
    else return false;
  };

  let getLastSubscription;
  let lastReportCreated;
  let enableReportGeneration;

  if (Array.isArray(stripeSubscriptions) && stripeSubscriptions.length >= 1) {
    // Get last subscription
    getLastSubscription = _.orderBy(stripeSubscriptions, (sub) => fromUnixTime(sub.created), 'desc')?.[0];
  }

  if (!getLastSubscription) {
    let startDate = addYears(new Date(orgDetails?.subscriptionEndDate), -1);
    getLastSubscription = {
      endDate: orgDetails?.subscriptionEndDate,
      current_period_start: getTime(startDate) / 1000, //getTime returns time in milliseconds however we want it in second thus the / 1000
    };
  }

  if (Array.isArray(reportList) && reportList.length >= 1) {
    // Get last report created
    lastReportCreated = _.orderBy(reportList, (report) => report.created, 'desc')?.[0];
  }

  if (directUser && lastReportCreated && getLastSubscription && subscriptionPlan !== 'Unlimited') {
    // User is a direct user and has a subscription which is not unlimited
    if (!hasAnYearPassed(getLastSubscription?.current_period_start)) {
      // An year has not passed since the subscription started
      // Get lastSubscription period start date and convert it to date, remove time element
      const lastSubscriptionStartDate = new Date(getLastSubscription?.current_period_start * 1000);
      lastSubscriptionStartDate.setHours(0, 0, 0, 0);
      // Get last report created date, convert it to date, and remove time element
      const lastReportCreatedDate = new Date(lastReportCreated?.created);
      lastReportCreatedDate.setHours(0, 0, 0, 0);
      // Check if last report created date is equal to last subscription start date
      if (
        isFirstDateAfterSecondDate(lastReportCreated?.created, getLastSubscription?.current_period_start) ||
        lastReportCreatedDate.getTime() === lastSubscriptionStartDate.getTime()
      ) {
        // last report created is AFTER subscription start date --> User cannot generate report
        enableReportGeneration = false;
      } else {
        // last report created is BEFORE subscription start date --> User can generate report
        enableReportGeneration = true;
      }
    } else {
      // An year has already passed since the subscription started --> user should pay for report
      enableReportGeneration = false;
    }
  } else enableReportGeneration = true;

  return {
    list: reportList,
    currentOrganisation: state.currentOrganisation,
    branding: state.branding,
    showLoader: state?.showLoader,
    addReportIssue,
    reportGeneration: orgDetails?.reportGeneration,
    directUser,
    stripeCustomerId,
    lastSubscription: getLastSubscription,
    enableReportGeneration,
    ambassadorAdmin,
    stripeSubscriptionsFetched: stripeSubscriptions !== undefined,
    adminUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (updateSubscription) => dispatch(Actions.setAddReportModal(true, updateSubscription)),
  listReports: () => dispatch(Actions.listReports()),
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
  getSubscription: () => dispatch(PAYMENTACTIONS.getSubscription()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportTable);
