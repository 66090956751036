import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Actions as CONSUMPTION_ACTIONS } from '../../store/actions/consumptionActions';
import { Actions as FACILITY_ACTIONS } from '../../store/actions/facilityActions';
import { Actions as REPORT_ACTIONS } from '../../store/actions/reportActions';
import { ConsumptionReportBtn } from '../../styledComponents/GlobalStyle';
import { PeriodMaker } from '../utils/GlobalFunctions';
import ReportOverview from './../HTMLReport/ReportOverview';
import ConsumptionEntity from './ConsumptionEntity';
import ElectricityRenewable from './ElectricityRenewable';
import WasteRecyclable from './WasteRecyclable';
import { Tooltip } from '@mui/material';

const ConsumptionReport = ({
  report,
  getConsumption,
  entities,
  years,
  consumptionFilter,
  metrics,
  organisationDetails,
  getFacilityMetrics,
  getReport,
  toggleConsumptionFilter,
  branding,
  industries,
}) => {
  const { headers } = branding.fonts?.[0].font || {};

  const options = ['m1', 'm2', 'm3', 'revenue', 'absolute'];

  const filterClasses = consumptionFilter.map((item) => `hide-${item}`);

  useEffect(() => {
    const reportId = window.location.pathname.split('/')[3];
    getReport(reportId);
  }, [getReport]);

  useEffect(() => {
    if (report && report.id) {
      getFacilityMetrics();
      getConsumption();
    }
    //eslint-disable-next-line
  }, [report && report.id]);

  if (!report || !report.id) {
    <div className='row' style={{ fontFamily: headers }}>
      Loading...
    </div>;
  }

  let singleYear = false;
  if (years.length === 1) {
    singleYear = years[0];
  }

  return (
    <div className='row' style={{ fontFamily: headers }}>
      <style>
        {`.facility-values td:first-child { padding-left:40px; }`}
        {years?.map((year) => `.hide-year-${year} .toggle-year-${year} { display: none }`)}
        {options?.map((key) => `.hide-${key} .toggle-${key} { display: none }`)}
      </style>

      <div className='col-lg-12'>
        <section className='panel'>
          <ReportOverview mode='consumption' />
        </section>

        <section className='panel'>
          <div className='panel-body' style={{ overflow: 'scroll' }}>
            <div className='clearfix'>
              <div style={{ float: 'right' }}>
                <span>Show: </span>

                <ConsumptionReportBtn
                  className={`btn ${consumptionFilter.includes('absolute') ? 'btn-default' : 'btn-success'}`}
                  onClick={() => toggleConsumptionFilter('absolute')}>
                  Absolute
                </ConsumptionReportBtn>

                <ConsumptionReportBtn
                  className={`btn ${consumptionFilter.includes('revenue') ? 'btn-default' : 'btn-success'}`}
                  onClick={() => toggleConsumptionFilter('revenue')}>
                  per million Revenue
                </ConsumptionReportBtn>

                {organisationDetails &&
                  ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
                    const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
                    const metricID = orgIndustry?.[ir];
                    const metric = metrics && metrics.find((metric) => metric.id === metricID);
                    const metricName =
                      metric && (metric.name === 'Unit' ? orgIndustry.descriptionOfNonStandardRatio : metric.name);

                    return (
                      metric &&
                      metricID &&
                      metricID !== '230393' && (
                        <ConsumptionReportBtn
                          key={crypto.randomUUID()}
                          className={`btn ${consumptionFilter.includes(`m${index + 1}`) ? 'btn-default' : 'btn-success'}`}
                          onClick={() => toggleConsumptionFilter(`m${index + 1}`)}>
                          per {metricName}
                        </ConsumptionReportBtn>
                      )
                    );
                  })}
              </div>
            </div>
            <div className='clearfix'>
              <div style={{ float: 'right', marginTop: '10px' }}>
                <span>Show Year: </span>
                {years.map((year) => {
                  return (
                    <ConsumptionReportBtn
                      key={crypto.randomUUID()}
                      className={`btn ${consumptionFilter.includes(`year-${year}`) ? 'btn-default' : 'btn-success'}`}
                      onClick={() => toggleConsumptionFilter(`year-${year}`)}>
                      {singleYear
                        ? PeriodMaker(report.startDate, report.endDate, singleYear)
                        : PeriodMaker(report.startDate, report.endDate, +year)}
                    </ConsumptionReportBtn>
                  );
                })}
              </div>
            </div>
            <br />

            <h3>Consumption Disaggregated by Source</h3>
            <table
              className={`table table-hover general-table pie ${filterClasses.join(' ')}`}
              style={{ textAlign: 'right' }}>
              <thead>
                <tr>
                  <th width='20%'>
                    Category
                    <Tooltip
                      arrow
                      placement='top'
                      componentsProps={{ tooltip: { sx: { bgcolor: branding.colors?.secondaryColor } } }}
                      title={
                        <p style={{ fontSize: 12 }}>
                          Expenditure data has been converted into the currencies GBP (£) or USD ($) for the calculation of
                          tCO2e emissions.
                        </p>
                      }>
                      <i className='fas fa-info-circle' style={{ marginLeft: '0.5rem' }} />
                    </Tooltip>
                  </th>

                  {years.map((year) => {
                    return (
                      <th
                        key={crypto.randomUUID()}
                        className={`toggle-absolute toggle-year-${year}`}
                        width='15%'
                        style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                        Absolute Consumption <br />
                        {singleYear
                          ? PeriodMaker(report.startDate, report.endDate, singleYear)
                          : PeriodMaker(report.startDate, report.endDate, +year)}
                      </th>
                    );
                  })}
                  {years.map((year) => {
                    return (
                      <th
                        key={crypto.randomUUID()}
                        className={`toggle-revenue toggle-year-${year}`}
                        width='15%'
                        style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                        Normalised consumption per million revenue <br />
                        {singleYear
                          ? PeriodMaker(report.startDate, report.endDate, singleYear)
                          : PeriodMaker(report.startDate, report.endDate, +year)}
                      </th>
                    );
                  })}
                  {organisationDetails &&
                    ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
                      const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
                      const metricID = orgIndustry?.[ir];
                      const metric = metrics && metrics.find((metric) => metric.id === metricID);
                      const metricName =
                        metric && (metric.name === 'Unit' ? orgIndustry.descriptionOfNonStandardRatio : metric.name);

                      return (
                        metric &&
                        metricID &&
                        metricID !== '230393' &&
                        years.map((year) => {
                          return (
                            <th
                              key={crypto.randomUUID()}
                              className={`toggle-m${index + 1} toggle-year-${year}`}
                              width='15%'
                              style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                              Normalised consumption per {metricName} <br />
                              {singleYear
                                ? PeriodMaker(report.startDate, report.endDate, singleYear)
                                : PeriodMaker(report.startDate, report.endDate, +year)}
                            </th>
                          );
                        })
                      );
                    })}
                </tr>
              </thead>

              <tbody>
                {entities?.map((entity) => (
                  <ConsumptionEntity entityID={entity.id} />
                ))}
              </tbody>
            </table>

            <WasteRecyclable />
            <ElectricityRenewable />
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    data,
    entities: consumptionEntities,
    subentities: consumptionSubentities,
    years,
    filter,
    wasteByType,
  } = state.consumption || {};

  const { subentities, entities, facility, organisation, report } = state;

  const neededEntities =
    entities &&
    consumptionEntities &&
    _.orderBy(
      entities.filter(({ id }) => consumptionEntities.includes(id)),
      ['name']
    );
  const neededSubentities =
    subentities &&
    consumptionSubentities &&
    _.orderBy(
      subentities.filter(({ id }) => consumptionSubentities.includes(id)),
      ['name']
    );

  return {
    report: report?.details,
    wasteByType,
    consumptionData: data,
    consumptionFilter: filter || [],
    subentities: neededSubentities,
    entities: neededEntities,
    years: years?.sort((a, b) => a - b) || [],
    facilities: facility && facility.list,
    facilityMetrics: facility?.metrics,
    metrics: state.metric?.list,
    types: state.metric?.types,
    organisationDetails: organisation?.details,
    organisationMetrics: organisation?.metrics,
    branding: state.branding,
    industries: organisation?.industries,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReport: (id) => dispatch(REPORT_ACTIONS.getReport(id)),
  getFacilityMetrics: () => dispatch(FACILITY_ACTIONS.getFacilityMetrics()),
  getConsumption: () => dispatch(CONSUMPTION_ACTIONS.getConsumption()),
  toggleConsumptionFilter: (filter) => dispatch(CONSUMPTION_ACTIONS.toggleConsumptionFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsumptionReport);
