import React from 'react';
import { connect } from 'react-redux';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import Breadcrumb from '../../Common/Breadcrumb';
import ClientsListTable from './OrganisationsListTable';
import Box from '@mui/material/Box';

const items = [{ label: 'Organisations', link: '/admin/organisations' }];

const OrgsList = ({ toggleModal, cyfAdmin }) => {
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Breadcrumb items={items} />
        {cyfAdmin && (
          <div className='btn btn-danger' onClick={() => toggleModal(true, 'recalcAllUsage', null)}>
            Recalculate all Organisations
          </div>
        )}
      </Box>
      <ClientsListTable />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cyfAdmin: state.profile.details.cyfAdmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRecalcUsageStepFunctionStatus: (importArn) => dispatch(ADMINACTIONS.getRecalcUsageStepFunctionStatus(importArn)),
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgsList);
