import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import softwareLicenseAgreement from './SoftwareLicenseAgreement';
import privacyPolicy from './PrivacyPolicy';

function RegistrationAgreements({ setShowSoftwareLicenseAgreement, showSoftwareLicenseAgreement, setShowPrivacyPolicy }) {
  if (showSoftwareLicenseAgreement) {
    return (
      <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
        <div className='modal-dialog' style={{ top: 40 }}>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                onClick={() => setShowSoftwareLicenseAgreement(false)}
                className='close'
                style={{ position: 'absolute', top: '10px', right: '20px' }}>
                x
              </button>
              <h4 className='modal-title'>Software License Agreement</h4>
            </div>
            <div
              className='modal-body'
              style={{
                height: '80vh',
                overflow: 'auto',
                fontSize: '12px',
                background: 'white',
                borderRadius: '6px',
              }}>
              <div style={{ textAlign: 'justify', color: 'black' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReactMarkdown>
                    ![ScreenShot](https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/branding/compareyourfootprint/Picture+1.jpg)
                  </ReactMarkdown>
                </div>
                <ReactMarkdown
                  remarkPlugins={[gfm]}
                  className={'markdown-paragraph'}
                  children={softwareLicenseAgreement()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)', top: -20 }}>
      <div className='modal-dialog' style={{ top: 40 }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              onClick={() => setShowPrivacyPolicy(false)}
              className='close'
              style={{ position: 'absolute', top: '10px', right: '20px' }}>
              x
            </button>
            <h4 className='modal-title'>Privacy Policy</h4>
          </div>
          <div
            className='modal-body'
            style={{
              height: '80vh',
              overflow: 'auto',
              fontSize: '12px',
              background: 'white',
              borderRadius: '6px',
            }}>
            <div style={{ textAlign: 'justify', color: 'black' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ReactMarkdown>
                  ![ScreenShot](https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/branding/compareyourfootprint/Picture+1.jpg)
                </ReactMarkdown>
              </div>
              <ReactMarkdown remarkPlugins={[gfm]} className={'privacyPolicy-text'} children={privacyPolicy()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationAgreements;
